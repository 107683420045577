import { useSelector } from "react-redux";

export const useIPCountryCode = () =>
  useSelector(({ iplocation }: any) => iplocation.country_code);

export const useIPCountryName = () =>
  useSelector(({ iplocation }: any) => iplocation.country_name);

export const useIPRegionName = () =>
  useSelector(({ iplocation }: any) => iplocation.region_name);
