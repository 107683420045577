import React from "react";
import Loader from "app/components/Loader";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import VideoPlayer from "app/components/VideoPlayer";
import styled from "styled-components";

const StyledVideoPlayer = styled(VideoPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  #video-player-wrapper {
    opacity: ${({ opacity }) => opacity};
  }
`;

interface Props {
  objectFit?: string;
  containerBg?: string;
  showLoader?: boolean;
  url: string;
  ChildControls?: React.ReactElement;
  position?: string;
  playing?: boolean;
  playsinline?: boolean;
  controls?: boolean;
  volume?: number;
  muted?: boolean;
  loop?: boolean;
  height?: string;
  width?: string;
  onReady?(_player: any): void;
  onProgress?(_progress: any): void;
  onError?(_error: any): void;
  onDuration?(_duration: any): void;
}

const ResponsiveVideoPlayer = ({
  onReady = null,
  containerBg = "black",
  showLoader = true,
  url,
  objectFit = null,
  ChildControls = null,
  ...rest
}: Props) => {
  const [loading, setLoading] = React.useState(true);
  const objectFitAttrs = {} as { height: string };

  /*  This object sets the height of the Player to 100%
    only if the objectFit property that's passed in is "cover".
    Kinda scrappy but prevents passing in the height prop to the player if not needed. */
  if (objectFit === "cover") {
    objectFitAttrs.height = "100%";
  }

  return (
    <Flex
      minWidth="100%"
      width="100%"
      height="100%"
      justifyContent="center"
      bg="white"
      {...objectFitAttrs}
    >
      <Div width="100%" height="100%" position="relative" overflow="hidden">
        <Div
          pt="56.25%"
          position="relative"
          bg={containerBg}
          {...objectFitAttrs}
        >
          {showLoader && loading && (
            <Flex
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
            >
              <Loader />
            </Flex>
          )}
          <StyledVideoPlayer
            id="video-player-wrapper"
            onReady={(player: any) => {
              setLoading(false);
              if (onReady) {
                onReady(player);
              }
            }}
            width="100%"
            height="100%"
            opacity={loading ? 0 : 1}
            url={url}
            objectFit={objectFit}
            {...rest}
          />
        </Div>
        {ChildControls}
      </Div>
    </Flex>
  );
};

export default ResponsiveVideoPlayer;
