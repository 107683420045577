import React from "react";
import styled from "styled-components";
import Icon, { GoogleLogoWithColor } from "../../Icon";
import Button from "../index";

const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding: 12px;

  span {
    flex: 1;
    text-transform: uppercase;
  }
`;

const GoogleLoginButton = ({
  message = "Log in with Google",
  ...props
}: {
  message: string;
}) => (
  <StyledButton {...props} variant="google">
    <Icon
      mr={2}
      width="18px"
      height="18px"
      position="absolute"
      as={GoogleLogoWithColor}
    />
    <span>{message}</span>
  </StyledButton>
);

export default GoogleLoginButton;
