import React, { useEffect, useLayoutEffect } from "react";
import isServer from "../../../helpers/isServer";

const useEnhancedEffect = isServer ? useEffect : useLayoutEffect;

const NoSsr = props => {
  const { children, defer = false, fallback = null } = props;
  const [mountedState, setMountedState] = React.useState(false);

  useEnhancedEffect(() => {
    if (!defer) {
      setMountedState(true);
    }
  }, [defer]);

  React.useEffect(() => {
    if (defer) {
      setMountedState(true);
    }
  }, [defer]);

  return <React.Fragment>{mountedState ? children : fallback}</React.Fragment>;
};

export default NoSsr;
