import React, { useRef } from "react";
import PropTypes from "prop-types";
import { border, layout } from "styled-system";
import styled from "styled-components";
import env from "helpers/env";

const Img = styled.img`
  border-radius: 50%;
  ${border}
  ${layout}
`;

const Avatar = ({ alt, src, ...rest }) => {
  const imgRef = useRef();
  return (
    <Img
      alt={alt}
      ref={imgRef}
      src={src || `${env("PUBLIC_ASSET_PATH")}/default-avatar.png?w=240`}
      onError={e => {
        // eslint-disable-next-line no-param-reassign
        e.target.src = `${env("PUBLIC_ASSET_PATH")}/default-avatar.png?w=240`;
      }}
      {...rest}
    />
  );
};

Avatar.defaultProps = {
  alt: "Avatar",
  src: `${env("PUBLIC_ASSET_PATH")}/default-avatar.png?w=240`,
};

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};

export default Avatar;
