export const areInputValuesValid = (values: string[]) =>
  values.every(
    // values can be empty strings
    value => value.length && !Number.isNaN(value)
  );

export const isPartiallyValid = (values: string[]) =>
  values.some(
    // values can be empty strings
    value => value.length && !Number.isNaN(value)
  );
