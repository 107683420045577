import styled from "styled-components";
import { layout, space, typography } from "styled-system";

export const StyledInput = styled.input<{ hasError: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  border: 3px solid ${({ theme, hasError }) =>
    hasError ? theme.colors.red : "transparent"};
  background: transparent;
  border-radius: 16px;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: bold;
  width: 107px;
  height: 107px;
  caret-color: transparent;

  &:focus {
    outline: none;
    border: 3px solid ${({ theme, hasError }) =>
    hasError ? theme.colors.red : theme.colors.blue};
  }

  ${layout}
  ${space}
  ${typography}
`;
