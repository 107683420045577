import alerts from "./alerts";
import badges from "./badges";
import breakpoints from "./breakpoints";
import buttons from "./buttons";
import colors from "./colors";
import components from "./components";
import mediaQueries from "./media-queries";
import space from "./space";

export default {
  alerts,
  badges,
  breakpoints,
  buttons,
  colors,
  components,
  fontSizes: [
    "10px",
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "32px",
    "48px",
    "64px",
  ],
  mediaQueries,
  secondaryFont: "Poppins",
  space,
};
