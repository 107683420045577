import styled from "styled-components";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";

const ProgressBarWrapper = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
`;

const SliderWrapper = styled.div`
  margin: 0 10px;
  width: 100%;

  .rc-slider {
    height: 20px;
  }
`;

const StyledProgressBarSlider = styled(Slider)`
  padding: 5px 0;
  border-radius: 0;

  .rc-slider-vertical {
    padding: 0;
    width: 4px;
  }
  .rc-slider-rail {
    height: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    opacity: 0.4;
  }
  .rc-slider-step {
    height: ${({ height }) => height};
  }
  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 4px;
    height: ${({ height }) => height};
  }
  .rc-slider-handle {
    background-color: ${({ theme }) => theme.colors.blue};
    border: none;
    height: ${({ handleSize }) => handleSize};
    width: ${({ handleSize }) => handleSize};
  }
  .rc-slider-handle:focus {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  .rc-slider-handle:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
  .rc-slider-handle:active {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 3px ${({ theme }) => theme.colors.white};
  }
`;

export { ProgressBarWrapper, SliderWrapper, StyledProgressBarSlider };
