import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { RouteComponentProps } from "react-router-dom";
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { ApolloProvider } from "@apollo/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import env from "helpers/env";
import theme from "../../../theme";
import PersistWrapper from "../PersistWrapper";
import RouterProvider from "./RouterProvider";
import { FamilyContextProvider } from "./FamilyContextProvider";
import { AlgoliaWidgetsCollectorProvider } from "../Algolia/WidgetsCollectorProvider";
import { DeviceSizeProvider } from "./DeviceSizeProvider";

const StyledBaseModalBackground = styled(BaseModalBackground)`
  z-index: ${({ zIndex }) => zIndex || "99999 !important"};
  opacity: ${({ opacity }) => opacity};
  transition: opacity ease 200ms;
  background: rgba(0, 0, 0, 0.75);
  display: block;
  backdrop-filter: blur(8px);
  top: 0;
`;

const PAYPAL_OPTIONS = {
  "client-id": env("PUBLIC_PAYPAL_CLIENT_ID"),
  currency: "USD",
  vault: true,
};

const RRF_CONFIG = {
  preserveOnLogout: [
    "dance_styles",
    "instructors",
    "programs",
    "free_classes",
    "classes_details",
    "classes_videos",
    "welcome_guide",
  ],
};

interface Props {
  children: React.ReactNode;
  store: Record<string, unknown>;
  firebaseInstance(): void;
  persistor: Record<string, unknown>;
  history: RouteComponentProps["history"];
  location: string;
  apolloClient: Record<string, unknown>;
  helmetContext: Record<string, unknown>;
  routerContext: Record<string, unknown>;
  widgetsCollector(): void;
}

const AppProviders = ({
  children,
  location,
  store,
  history,
  persistor,
  firebaseInstance,
  apolloClient,
  helmetContext,
  routerContext,
  widgetsCollector,
}: Props) => {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <Helmet title="STEEZY Studio | Online Dance Classes and Tutorials">
          <style>
            {
              "@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');"
            }
          </style>
        </Helmet>
        <ThemeProvider theme={theme}>
          <PayPalScriptProvider options={PAYPAL_OPTIONS}>
            <PersistWrapper persistor={persistor}>
              <ReactReduxFirebaseProvider
                config={RRF_CONFIG}
                firebase={firebaseInstance}
                dispatch={store.dispatch}
              >
                <RouterProvider
                  history={history}
                  location={location}
                  context={routerContext}
                >
                  <ApolloProvider client={apolloClient}>
                    <ModalProvider
                      backgroundComponent={StyledBaseModalBackground}
                    >
                      <DeviceSizeProvider>
                        <AlgoliaWidgetsCollectorProvider
                          widgetsCollector={widgetsCollector}
                        >
                          <FamilyContextProvider>
                            {children}
                          </FamilyContextProvider>
                        </AlgoliaWidgetsCollectorProvider>
                      </DeviceSizeProvider>
                    </ModalProvider>
                  </ApolloProvider>
                </RouterProvider>
              </ReactReduxFirebaseProvider>
            </PersistWrapper>
          </PayPalScriptProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default AppProviders;
