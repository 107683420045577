import styled from "styled-components";
import BaseIcon from "app/components/Icon";

const unseenColor = "#E7F2FF";

export const Wrapper = styled.div<{ isSeen: boolean }>`
  display: grid;
  grid-template-columns: 50px 1fr 26px;
  align-items: center;
  gap: 16px;
  padding: 16px;
  max-height: 100px;
  background: ${({ isSeen, theme }) =>
    isSeen ? theme.colors.white : unseenColor};
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    background: ${({ theme }) => theme.colors.monochrome[1]};
  }
`;

export const Header = styled.header`
  font-size: 14px;
  font-weight: 600;
  line-height: 110%;
`;

export const Body = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.12%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Span = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 128%;
  letter-spacing: 0.44px;
`;

export const Icon = styled(BaseIcon)<{ isSeen: boolean }>`
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-color: ${({ isSeen, theme }) =>
    isSeen ? theme.colors.white : unseenColor};
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: 0;
`;
