import React, { useState, createRef, useEffect } from "react";
import Icon, { Dot, DotActive } from "app/components/Icon";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { StyledInput } from "./styles";

const NUMERIC_REGEX = /^[0-9]+$/;

interface Props {
  values: string[];
  autoFocus: boolean;
  hasError: boolean;
  onChange: (a: OnChangeInput) => void;
  placeholder?: string;
  required?: boolean;
}

interface OnChangeInput {
  values: string[];
  event: React.ChangeEvent<HTMLInputElement>;
}

export const PinInput = ({
  autoFocus = false,
  hasError = false,
  onChange = () => undefined,
  placeholder = "",
  required = false,
  values = ["", "", "", ""],
}: Props) => {
  const [inputRefs, setInputRefs] = useState<any[]>([]);
  const [hasFocus, setHasFocus] = useState(false);

  const focusOnFirstInput = () => {
    const inputRef = inputRefs[0];
    if (inputRef?.current?.focus && !inputRef.current.value) {
      inputRef.current.focus();
    }
  };

  useEffect(
    function initializeInputRefs() {
      setInputRefs(refs =>
        Array(values.length)
          .fill("")
          .map((_, i) => refs[i] || createRef())
      );
    },
    [values]
  );

  useEffect(
    function autoFocusOnMount() {
      if (autoFocus) {
        focusOnFirstInput();
      }
    },
    [autoFocus, inputRefs]
  );

  useEffect(
    function autoFocusOnError() {
      if (hasError) {
        focusOnFirstInput();
      }
    },
    [hasError]
  );

  return (
    <Flex alignItems="center" flexWrap="wrap">
      {values.map((v, i) => {
        const isActive = inputRefs[i]?.current === document.activeElement;
        return (
          <Div
            position="relative"
            mr={3}
            mb={3}
            bg="monochrome.1"
            borderRadius="16px"
          >
            {!values[i] && (
              <Flex
                width="100%"
                height="100%"
                position="absolute"
                justifyContent="center"
                alignItems="center"
              >
                <Icon
                  width="20px"
                  height="auto"
                  mt={isActive ? "10px" : 0}
                  as={isActive ? DotActive : Dot}
                />
              </Flex>
            )}
            <StyledInput
              hasError={hasError}
              inputMode="numeric"
              ref={inputRefs[i]}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              name="Pin Input"
              onBlur={() => setHasFocus(false)}
              onFocus={() => setHasFocus(true)}
              onChange={event => {
                const eventValue = event.target.value;
                // in the case of an autocomplete or copy and paste
                if (eventValue.length > 2) {
                  // see if we can use the string to fill out our values
                  if (
                    eventValue.length === values.length &&
                    eventValue.match(NUMERIC_REGEX)
                  ) {
                    onChange({ values: eventValue.split(""), event });
                  }
                  return;
                }
                // value was deleted
                if (eventValue === "") {
                  const newValues = values.slice();
                  newValues[i] = "";
                  onChange({ values: newValues, event });
                  return;
                }
                // we want to override the input value with the last digit typed
                const currentValue = values[i];
                let newValue = eventValue;
                if (currentValue[0] === eventValue[0]) {
                  // eslint-disable-next-line prefer-destructuring
                  newValue = eventValue[1];
                } else if (currentValue[0] === eventValue[1]) {
                  [newValue] = eventValue;
                }
                // only fire a change event if the new value is lowercased alphanumeric
                if (newValue.match(NUMERIC_REGEX)) {
                  const newValues = values.slice();
                  newValues[i] = newValue;
                  onChange({ values: newValues, event });

                  // tab to next pin code input if we aren't at end already
                  if (i < values.length - 1) {
                    const inputRef = inputRefs[i + 1];
                    if (inputRef?.current?.focus) {
                      inputRef.current.focus();
                    }
                  }
                }
              }}
              onKeyDown={event => {
                // if we see a backspace/delete and the input is empty, transfer focus backward
                if (event.key === "Backspace" && values[i] === "" && i > 0) {
                  const inputRef = inputRefs[i - 1];
                  if (inputRef?.current?.focus) {
                    inputRef.current.focus();
                  }
                }
              }}
              placeholder={hasFocus ? "" : placeholder}
              required={required}
              type="text"
              value={values[i]}
            />
          </Div>
        );
      })}
    </Flex>
  );
};

export default PinInput;
