import { NotFound } from "app/components/NotFound";
import React from "react";
import { Helmet } from "react-helmet-async";

export default () => (
  <>
    <Helmet title="Not Found | Online Dance Classes and Tutorials" />
    <NotFound />;
  </>
);
