import React from "react";
import env from "helpers/env";
import { PrivacyBar, PrivacyText } from "./styles";

export const PrivacyFooter = () => (
  <PrivacyBar pl={{ _: "40px", lg: "100px" }}>
    <PrivacyText color="black" mr={4}>
      © 2021 STEEZY STUDIO
    </PrivacyText>
    <a href={`${env("PUBLIC_URL")}/privacy`} target="blank">
      <PrivacyText cursor="pointer" color="monochrome.4">
        PRIVACY POLICY &amp; FAQ
      </PrivacyText>
    </a>
  </PrivacyBar>
);
