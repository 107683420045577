export const SET_WIDGET_PLAYING = "community/SET_WIDGET_PLAYING";

const initialState = {
  challengeIdPlaying: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WIDGET_PLAYING:
      return {
        ...state,
        challengeIdPlaying: action.challengeIdPlaying,
      };

    default:
      return state;
  }
};

export const setWidgetPlaying = challengeIdPlaying => dispatch => {
  dispatch({
    type: SET_WIDGET_PLAYING,
    challengeIdPlaying,
  });
};
