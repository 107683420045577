import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import { ControlButton } from "app/components/ClassPlayer/components";
import Text from "app/components/Text";

const BaseButton = ({
  onClick,
  Icon,
  text = "",
  iconWidth = 24,
  show = true,
  ...rest
}) => {
  if (!show) {
    return null;
  }

  return (
    <ControlButton mb={3} onClick={onClick} {...rest}>
      <Flex flexDirection="column" alignItems="center">
        <Icon width={iconWidth} color="#FFFFFF" />
        <Text fontSize="11px" mt={2}>
          {String(text)}
        </Text>
      </Flex>
    </ControlButton>
  );
};

BaseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.element.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconWidth: PropTypes.number,
  show: PropTypes.bool,
};

export default BaseButton;
