import styled from "styled-components";
import {
  background,
  border,
  color,
  layout,
  position,
  space,
  shadow,
  system,
} from "styled-system";

type Props = {
  backdropFilter?: string;
  [x: string]: any;
};

export default styled.div<Props>`
  ${background}
  ${border}
  ${color}
  ${layout}
  ${position}
  ${space}
  ${shadow}
  ${system({
    backgroundClip: true,
    columns: true,
    cursor: true,
    textAlign: true,
    transition: true,
    transform: true,
  })}
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
`;
