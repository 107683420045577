import { gql } from "@apollo/client";
import { CORE_VIDEO_UPLOAD_FIELDS } from "./fragments";

const VIDEO_UPLOAD_DELETE = gql`
  ${CORE_VIDEO_UPLOAD_FIELDS}
  mutation VideoUploadDelete($videoId: String!) {
    videoUploadDelete(videoId: $videoId) {
      ...CoreVideoUploadFields
    }
  }
`;

const USER_ACKNOWLEDGE_CONTENT = gql`
  mutation UserAcknowledgeContent($input: UserAcknowledgeContentInput!) {
    userAcknowledgeContent(input: $input) {
      id
      uid
      progress {
        acknowledgedContent
      }
    }
  }
`;

export { VIDEO_UPLOAD_DELETE, USER_ACKNOWLEDGE_CONTENT };
