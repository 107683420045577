import localforage from "localforage";

const instance =
  typeof window !== "undefined"
    ? localforage.createInstance({
        driver: localforage.INDEXEDDB,
        version: 1.0,
        name: "redux-store",
        storeName: "redux-store",
      })
    : null;

export default instance;
