import styled from "styled-components";
import Modal from "styled-react-modal";
import { borderRadius, layout, space } from "styled-system";

export const BackgroundClick = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  background: ${({ background, theme }) => background ?? theme.colors.white};
  position: relative;
  ${borderRadius};
  ${layout};
  ${space};
`;

export const StyledModal = Modal.styled`
  overflow: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  outline: none;
`;
