import React from "react";
import Flex from "app/components/Flex";
import { P1 } from "app/components/Typography";
import Icon from "app/components/Icon";
import { MenuItem } from "@szhsin/react-menu";

interface Props {
  Svg?: any;
  text: string;
  onClick?(): void;
}

export const DropdownOption = ({
  Svg = null,
  text,
  onClick = () => {},
}: Props) => (
  <MenuItem onClick={onClick}>
    <Flex color="monochrome.7" alignItems="center" py={2} px={2}>
      {!!Svg && (
        <Icon color="monochrome.7" width="16px" height="16px" mr={3} as={Svg} />
      )}
      <P1>{text}</P1>
    </Flex>
  </MenuItem>
);
