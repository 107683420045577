import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTrailerOpen: false,
  topmostBlockId: "",
};

const programViewsSlice = createSlice({
  name: "program-view",
  initialState,
  reducers: {
    openTrailer(state) {
      state.isTrailerOpen = true;
    },
    closeTrailer(state) {
      state.isTrailerOpen = false;
    },
    setTopmostBlockId(state, action) {
      state.topmostBlockId = action.payload;
    },
  },
});

export const {
  openTrailer,
  closeTrailer,
  setTopmostBlockId,
} = programViewsSlice.actions;

export default programViewsSlice.reducer;
