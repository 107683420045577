import moment from "moment-timezone";

export default ({ endTime } = {}) => {
  const now = moment();
  const endTimeDate = moment(endTime);

  if (!endTime || !endTimeDate.isValid()) {
    return 0;
  }

  const datesDiff = endTimeDate.diff(now);
  const daysLeft =
    datesDiff > 0 ? Math.floor(moment.duration(datesDiff).asDays()) + 1 : 0;

  return daysLeft;
};
