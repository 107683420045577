import { ApolloClient, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import ApolloLinkTimeout from "apollo-link-timeout";
import { createUploadLink } from "apollo-upload-client";
import cache, { createCache } from "config/gql-cache";
import { GraphQLError } from "graphql";
import env from "helpers/env";
import getTransactionId from "helpers/getTransactionId";
import isServer from "helpers/isServer";
import { getAuthHeaders } from "helpers/session/getAuthHeaders";
import ErrorReporter from "services/error-reporter";
import AwaitAuthLink from "./links/await-auth";
import { requestTimerLink } from "./links/request-timer";

const apiDomain = isServer
  ? env("SERVER_API_DOMAIN")
  : env("PUBLIC_API_DOMAIN");

const createSentryHeader = () => {
  const header = {};
  header["x-transaction-id"] = getTransactionId();
  return header;
};

const uploadLink = createUploadLink({
  uri: `${apiDomain}/graphql`,
});

const contextLink = injectHeaders =>
  setContext(async (_, { headers }) => {
    return {
      credentials: "include",
      headers: {
        ...headers,
        ...injectHeaders,
        ...(await getAuthHeaders()),
        ...createSentryHeader(),
        "apollographql-client-name": "web",
        "apollographql-client-version": env("PUBLIC_VERSION") ?? "unknown",
      },
    };
  });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    if (env("PUBLIC_ENV") === "testing") {
      // eslint-disable-next-line no-console
      console.log(operation, graphQLErrors);
    }
    graphQLErrors.forEach(err => {
      switch (err.extensions.code) {
        case "INTERNAL_SERVER_ERROR":
          ErrorReporter.report(new GraphQLError(err.message));
          break;
        default:
          break;
      }
    });
  }
  if (networkError) {
    ErrorReporter.report(networkError);
  }
});

if (!isServer) {
  const initialState = window.__APOLLO_STATE__ ?? {};
  delete window.__APOLLO_STATE__;
  cache.restore(initialState);
}

export const createSsrClient = injectHeaders => {
  const requestTimeout = env("NODE_ENV") === "development" ? 2000 : 500;
  return new ApolloClient({
    uri: `${apiDomain}/graphql`,
    cache: createCache(),
    link: from([
      requestTimerLink,
      new ApolloLinkTimeout(requestTimeout),
      contextLink(injectHeaders),
      errorLink,
      uploadLink,
    ]),
    ssrMode: true,
  });
};

const awaitAuthLink = new AwaitAuthLink();
const client = new ApolloClient({
  uri: `${apiDomain}/graphql`,
  cache,
  link: from([awaitAuthLink, contextLink(), errorLink, uploadLink]),
});

export default client;
