import isServer from "./isServer";

export default function(...args) {
  if (isServer) {
    return () => {};
  }
  if (window.zE && typeof window.zE === "function") {
    return window.zE(...args);
  }
  return () => {};
}
