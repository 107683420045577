import { useGetUserProfile } from "hooks/User";
import useGetChallengeById from "./useGetChallengeById";

const SIBLING_TYPE = {
  PREVIOUS: -1,
  NEXT: 1,
};

export default ({ isModal, challengeId, isChallenge, submissionId }) => {
  if (!isModal) {
    return {};
  }

  let videos;
  let fetchMore;
  if (challengeId) {
    [{ videos } = {}, , , fetchMore] = useGetChallengeById({
      variables: { id: challengeId },
    });
  } else {
    [{ uploadedVideos: videos } = {}, , , fetchMore] = useGetUserProfile();
  }

  const { edges = [], totalCount } = videos || {};
  const hasMore = edges.length < totalCount;

  const submissions = edges.map(({ node, cursor } = {}) => ({
    cursor,
    ...node,
  }));

  if (submissions.length === 0) {
    return {};
  }

  const [{ cursor: videoIndex } = {}] = submissions.filter(
    ({ id } = {}) => submissionId === id
  );

  const getSiblingVideo = siblingType =>
    submissions.filter(
      ({ cursor } = {}) => cursor === `${Number(videoIndex) + siblingType}`
    );

  const [{ id: previousVideo } = {}] = getSiblingVideo(SIBLING_TYPE.PREVIOUS);

  const previousIsChallenge =
    challengeId && !previousVideo && challengeId !== submissionId;
  const firstVideo = previousIsChallenge ? challengeId : previousVideo;

  const { id: firstChallengeSubmission } = submissions.shift();
  const [{ id: nextVideo } = {}] = getSiblingVideo(SIBLING_TYPE.NEXT);

  if (!nextVideo && hasMore) {
    fetchMore();
  }

  return {
    previousIsChallenge,
    previousVideo: firstVideo,
    nextVideo: isChallenge ? firstChallengeSubmission : nextVideo,
  };
};
