import { AccessType } from "services/graphql";
import { usePrimarySubscription } from "./usePrimarySubscription";

export function useUserAccessType(): AccessType {
  const {
    primarySubscription,
    loadingPrimarySubscription,
  } = usePrimarySubscription();

  if (loadingPrimarySubscription) {
    return AccessType.Free;
  }

  if (!primarySubscription) {
    return AccessType.Free;
  }

  if (!primarySubscription.isActive) {
    return AccessType.Free;
  }

  return primarySubscription?.plan?.accessType || AccessType.Free;
}
