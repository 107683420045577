import React, { useState } from "react";
import Text from "app/components/Text";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Icon, {
  STEEZYStudio,
  STEEZYFamilyVertical,
  Lock,
} from "app/components/Icon";
import { H2 } from "app/components/Typography";
import { ValidatePinModal } from "app/components/ValidatePinModal";
import { FullscreenModal } from "../FullscreenModal";
import { Avatar } from "./styles";

interface Props {
  isOpen: boolean;
  onCancel?(): void;
  onSelection(isFamily?: boolean): void;
  toggleFamilyMode(isToggled: boolean): void;
}

const STEEZYAvatar = ({ onClick }: { onClick?(): void }) => (
  <>
    <Avatar onClick={onClick} cursor={onClick ? "pointer" : "default"}>
      <Icon width="100%" height="100%" as={STEEZYStudio} />
    </Avatar>
    <H2 mt="20px">STEEZY Studio</H2>
    <Div
      bg="black"
      width="36px"
      height="36px"
      borderRadius="5px"
      padding="8px"
      mt="18px"
    >
      <Icon width="100%" height="100%" as={Lock} color="white" />
    </Div>
  </>
);

export const ModeSelectionModal = ({
  isOpen,
  onSelection,
  toggleFamilyMode,
}: Props) => {
  const [isValidating, setIsValidating] = useState(false);

  if (isValidating) {
    return (
      <ValidatePinModal
        isOpen
        onValidated={() => {
          setIsValidating(false);
          toggleFamilyMode(false);
          onSelection();
        }}
        onCancel={() => setIsValidating(false)}
      >
        <STEEZYAvatar />
        <Text fontWeight="700" fontSize="63px" my="64px">
          Enter your PIN
        </Text>
      </ValidatePinModal>
    );
  }

  const selectStudioMode = () => setIsValidating(true);

  const selectFamilyMode = () => {
    onSelection(true);
    toggleFamilyMode(true);
  };

  return (
    <FullscreenModal isOpen={isOpen}>
      <Flex
        p={4}
        width="100%"
        height="100%"
        alignItems="center"
        flexDirection="column"
      >
        <Text fontWeight="700" fontSize="63px" mt="120px">
          Who&apos;s ready to dance?
        </Text>
        <Flex
          width="100%"
          maxWidth="450px"
          justifyContent="space-between"
          flexWrap="wrap"
          mt="135px"
        >
          <Flex alignItems="center" flexDirection="column">
            {/* @POTENTIAL TODO: Add hover state */}
            <STEEZYAvatar onClick={selectStudioMode} />
          </Flex>
          <Flex alignItems="center" flexDirection="column">
            {/* @POTENTIAL TODO: Add hover state */}
            <Avatar onClick={selectFamilyMode} cursor="pointer">
              <Icon
                width="100%"
                height="100%"
                as={STEEZYFamilyVertical}
                p="20px"
              />
            </Avatar>
            <H2 mt="20px">STEEZY Family</H2>
          </Flex>
        </Flex>
      </Flex>
    </FullscreenModal>
  );
};
