export const HIDE_FLASH_ACTIVITY = "classPlayer/HIDE_FLASH_ACTIVITY";
export const PAUSE_CLASS = "classPlayer/PAUSE_CLASS";
export const PLAY_CLASS = "classPlayer/PLAY_CLASS";
export const RESET_CLASS_PLAYER = "classPlayer/RESET_CLASS_PLAYER";
export const START_LOOPING_CLASS = "classPlayer/START_LOOPING_CLASS";
export const SET_LOOPING_RANGE_CLASS = "classPlayer/SET_LOOPING_RANGE_CLASS";
export const SET_SPEED_CLASS = "classPlayer/SET_SPEED_CLASS";
export const SHOW_FLASH_ACTIVITY = "classPlayer/SHOW_FLASH_ACTIVITY";
export const STOP_LOOPING_CLASS = "classPlayer/STOP_LOOPING_CLASS";
export const STOP_CLASS = "classPlayer/STOP_CLASS";
