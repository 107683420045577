import Loader from "app/components/Loader";
import React from "react";
import { Notification, PageInfo } from "services/graphql";
import Text from "app/components/Text";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Icon, { Bell } from "app/components/Icon";
import { Waypoint } from "app/components/Waypoint";
import { MenuItem } from "@szhsin/react-menu";
import { NotificationItem } from "./NotificationItem";

interface Props {
  notifications: Partial<Notification>[];
  isLoading: boolean;
  notificationsPageInfo: Partial<PageInfo>;
  fetchMoreNotifications: () => void;
}

export function NotificationList({
  notifications,
  notificationsPageInfo,
  isLoading = true,
  fetchMoreNotifications,
}: Props) {
  if (!notifications) {
    return (
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Loader width="50" height="50" />
      </Flex>
    );
  }

  if (notifications.length === 0) {
    return (
      <Flex
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Icon as={Bell} width="25px" height="25px" />
        <Text
          mt="16px"
          fontWeight="600"
          fontSize="14px"
          lineHeight="140%"
          letterSpacing="1.4px"
          color="black"
        >
          NO NOTIFICATIONS
        </Text>
      </Flex>
    );
  }

  return (
    <Div overflow="auto" height="100%" pb="20px">
      {notifications &&
        notifications.map(notification => (
          // Wrapping in MenuItem to allow proper propagation of click events to Menu parent component
          <MenuItem>
            <NotificationItem notification={notification} />
          </MenuItem>
        ))}
      {isLoading ? (
        <Flex width="100%" justifyContent="center" alignItems="center">
          <Loader width="20" height="20" />
        </Flex>
      ) : (
        <Waypoint
          fetchData={fetchMoreNotifications}
          hasMore={notificationsPageInfo?.hasNextPage}
        />
      )}
    </Div>
  );
}
