import styled from "styled-components";
import Flex from "app/components/Flex";

export default styled(Flex).attrs(({ color }) => ({
  as: "a",
  px: "18px",
  width: "100%",
  height: "32px",
  cursor: "pointer",
  alignItems: "center",
  borderRadius: "2px",
  mb: 2,
  color: color || "black",
}))`
  ${({ hoverBg, theme }) =>
    `
      @media (hover: hover) {
        :hover {
            color: ${theme.colors.black};
            background: ${
              hoverBg ? theme.colors[hoverBg] : theme.colors.monochrome[1]
            };
          }
        }
      }
  `}
`;
