import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import convertTimeToString from "helpers/convertTimeToString";
import {
  ProgressBarWrapper,
  SliderWrapper,
  StyledProgressBarSlider,
} from "./styles";

const ProgressBar = ({
  currentTimeInSeconds = 0,
  onAfterChange,
  hideDuration,
  ...rest
}) => {
  const [dragging, setDragging] = useState(false);
  const [value, setValue] = useState(currentTimeInSeconds);

  useEffect(() => {
    if (!dragging) {
      setValue(currentTimeInSeconds);
    }
  }, [currentTimeInSeconds, dragging]);

  return (
    <ProgressBarWrapper
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <SliderWrapper>
        <StyledProgressBarSlider
          height="8px"
          handleSize="20px"
          tipFormatter={newValue => convertTimeToString(newValue)}
          value={value}
          onBeforeChange={() => {
            setDragging(true);
          }}
          onChange={setValue}
          onAfterChange={newValue => {
            if (onAfterChange) {
              onAfterChange(newValue);
            }
            setDragging(false);
          }}
          {...rest}
        />
      </SliderWrapper>
    </ProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  currentTimeInSeconds: PropTypes.number,
  onAfterChange: PropTypes.func,
  hideDuration: PropTypes.bool,
};

export default ProgressBar;
