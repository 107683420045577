import colors from "./colors";

export default {
  primary: {
    background: colors.primary[0],
  },
  secondary: {
    color: colors.black,
    background: colors.lightGrey,
  },
  success: {
    background: colors.success,
  },
  danger: {
    background: colors.danger,
  },
  warning: {
    background: colors.warning,
  },
  advanced: {
    background: colors.advanced,
  },
  intermediate: {
    background: colors.intermediate,
  },
  beginner: {
    background: colors.beginner,
  },
};
