import { useGetCategoriesQuery } from "services/graphql";
import {
  onboardingSelectCategories,
  onboardingSelectReason,
  onboardingSelectLevel,
} from "services/typewriter/segment";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const trackSelectCategoriesEventAction = ({
  selectedCategories,
  module,
}) => async (dispatch, getState) => {
  try {
    const {
      router: { location },
    } = getState();
    let categorySlugs = selectedCategories;

    if (!selectedCategories) {
      const { data } = useGetCategoriesQuery({
        variables: {
          slugs: [],
        },
      });
      const { categories } = data;
      categorySlugs = categories.reduce(
        (arr, category) =>
          category.isFollowing ? [...arr, category.slug] : arr,
        []
      );
    }

    onboardingSelectCategories({
      categories: categorySlugs,
      path: location.pathname,
      module,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const trackSelectDanceLevelEventAction = ({
  danceLevel,
  module,
}) => async (dispatch, getState) => {
  try {
    const {
      router: { location },
    } = getState();

    onboardingSelectLevel({
      dance_level: danceLevel,
      path: location.pathname,
      module,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const trackSelectDanceReasonEventAction = ({
  danceReason,
  module,
}) => async (dispatch, getState) => {
  try {
    const {
      router: { location },
    } = getState();

    onboardingSelectReason({
      reason: danceReason,
      path: location.pathname,
      module,
    });
  } catch (err) {
    console.log({ err });
  }
};
