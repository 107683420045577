import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  entrypoint: null,
  mockDevice: { width: undefined, height: undefined },
  initialAlgoliaResults: undefined,
};

const serverSlice = createSlice({
  name: "server",
  initialState,
  reducers: {
    setEntrypoint(state, action) {
      state.entrypoint = action.payload;
    },
    setInitialAlgoliaResults(state, action) {
      state.initialAlgoliaResults = action.payload;
    },
    setMockDeviceDimensions(state, action) {
      const { width, height } = action.payload;
      state.mockDevice.width = width;
      state.mockDevice.height = height;
    },
    clearMockDeviceDimensions(state) {
      state.mockDevice.width = undefined;
      state.mockDevice.height = undefined;
    },
  },
});

export const {
  setEntrypoint,
  setInitialAlgoliaResults,
  setMockDeviceDimensions,
  clearMockDeviceDimensions,
} = serverSlice.actions;

export default serverSlice.reducer;
