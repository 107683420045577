import React from "react";
import { Redirect, RouteProps } from "react-router-dom";
import { useUserSubscription } from "modules/selectors/users";
import AuthenticatedRoute from "../AuthenticatedRoute";

const SubscribedRoute = ({ children, ...rest }: RouteProps) => {
  const { isSubscriptionActive } = useUserSubscription();

  if (!isSubscriptionActive) {
    return <Redirect to="/checkout" />;
  }

  return <AuthenticatedRoute {...rest} children={children} />;
};

export default SubscribedRoute;
