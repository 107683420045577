import { useSelector } from "react-redux";

interface RootState {
  components: {
    Settings: {
      volume: number;
    };
    Navbar: {
      height: number;
    };
    SideNavbar: {
      width: number;
      expanded: boolean;
    };
  };
}

export const useComponentsSettingsVolume = () =>
  useSelector(({ components }: RootState) => components.Settings.volume);

export const useNavbarHeight = () =>
  useSelector(({ components }: RootState) => components.Navbar.height);

export const useSideNavbarSettings = () =>
  useSelector(({ components }: RootState) => ({
    isExpanded: components.SideNavbar.expanded,
    width: components.SideNavbar.width,
  }));
