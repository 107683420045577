import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as STEEZYLogo } from "app/assets/svg/steezy-logo.svg";
import styled from "styled-components";

const StyledLink = styled(Link)`
  display: flex;
  :hover {
    opacity: 0.8;
  }
`;

const Logo = styled.div`
  width: 100px;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Focus = () => (
  <Wrapper>
    <StyledLink to="/dashboard">
      <Logo as={STEEZYLogo} />
    </StyledLink>
  </Wrapper>
);

export default Focus;
