import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import env from "helpers/env";
import isClient from "../helpers/isClient";
import refreshServerSessionForUser from "../helpers/session/refreshServerSessionForUser";

const firebaseConfig = {
  apiKey: env("PUBLIC_FIREBASE_API_KEY"),
  authDomain: env("PUBLIC_FIREBASE_AUTH_DOMAIN"),
  databaseURL: env("PUBLIC_FIREBASE_DATABASE_URL"),
  storageBucket: env("PUBLIC_FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: env("PUBLIC_FIREBASE_MESSAGING_SENDER_ID"),
  projectId: env("PUBLIC_FIREBASE_PROJECT_ID"),
};

firebase.initializeApp(firebaseConfig);

if (isClient) {
  firebase.auth().onIdTokenChanged(user => {
    if (!user) {
      return;
    }
    return refreshServerSessionForUser(user);
  });
}

export default firebase;
