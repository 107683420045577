import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFamilyFriendlyFlag, useFeatureFlags } from "hooks/useFeatureFlags";
import { ModeSelectionModal } from "app/components/ModeSelectionModal";
import {
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
  FAMILY_MODE_STORAGE_KEY,
} from "constants/index";
import { useGetParentalControlsQuery } from "services/graphql";
import {
  familyEnterFamilyMode,
  familyEnterStudioMode,
} from "services/typewriter/segment";
import { getCachedFamilyModeSetting } from "./getCachedFamilyModeSetting";

interface ContextState {
  isFamilyModeOn: boolean | null;
  loadingIsFamilyModeOn: boolean;
  refetchParentalControls(): void;
  setAnalyticsContext(context: string): void;
  toggleFamilyMode(isToggled: boolean): void;
}

interface Props {
  children: React.ReactNode;
}

export const FamilyContext = createContext({
  setAnalyticsContext: () => {},
  loadingIsFamilyModeOn: true,
  isFamilyModeOn: null,
  refetchParentalControls: () => {},
  toggleFamilyMode: () => {},
} as ContextState);

export function FamilyContextProvider({ children }: Props) {
  const history = useHistory();

  const [analyticsContext, setAnalyticsContext] = useState("");
  const [isSelectingMode, setIsSelectingMode] = useState(false);

  const {
    loading: loadingParentalControls,
    data: userData,
    refetch: refetchParentalControls,
  } = useGetParentalControlsQuery();
  const isParentalControlsEnabled = userData?.me?.parentalControls.isEnabled;

  const {
    isFamilyModeOn,
    loadingIsFamilyModeOn,
    toggleFamilyMode,
  } = useFamilyMode();

  useEffect(() => {
    if (loadingIsFamilyModeOn || loadingParentalControls) {
      return;
    }

    if (!isParentalControlsEnabled) {
      return;
    }

    if (isFamilyModeOn === null) {
      // No cached setting found.
      setIsSelectingMode(true);
    }
  }, [
    isParentalControlsEnabled,
    isFamilyModeOn,
    loadingIsFamilyModeOn,
    loadingParentalControls,
  ]);

  const contextValue = {
    isFamilyModeOn,
    loadingIsFamilyModeOn,
    refetchParentalControls,
    setAnalyticsContext,
    toggleFamilyMode,
  };

  return (
    <FamilyContext.Provider value={contextValue}>
      {children}
      <ModeSelectionModal
        isOpen={isSelectingMode}
        toggleFamilyMode={toggleFamilyMode}
        onSelection={(isFamily: boolean) => {
          if (isFamily) {
            familyEnterFamilyMode({
              module: ANALYTICS_MODULE.mode_selection,
              location: analyticsContext || ANALYTICS_LOCATION.launch,
            });
          } else {
            familyEnterStudioMode({
              module: ANALYTICS_MODULE.mode_selection,
              location: analyticsContext || ANALYTICS_LOCATION.launch,
            });
          }

          if (analyticsContext === ANALYTICS_LOCATION.onboarding) {
            history.push("/dashboard");
          }

          setAnalyticsContext("");
          setIsSelectingMode(false);
        }}
      />
    </FamilyContext.Provider>
  );
}

function useFamilyMode() {
  const { loadingFeatureFlags } = useFeatureFlags();
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();

  const {
    loading: loadingParentalControls,
    data: userData,
  } = useGetParentalControlsQuery();
  const isParentalControlsEnabled = userData?.me?.parentalControls.isEnabled;

  const [isFamilyModeOn, setIsFamilyModeOn] = useState<boolean | null>(false);
  const [loadingIsFamilyModeOn, setLoadingFamilyModeOn] = useState(true);

  useEffect(() => {
    if (loadingFeatureFlags || loadingParentalControls) {
      return;
    }

    if (isParentalControlsEnabled) {
      const isFamilyModeToggled = getCachedFamilyModeSetting();
      setIsFamilyModeOn(isFamilyModeToggled);
    }

    // Loading done after data is pulled & cache checked if applicable.
    setLoadingFamilyModeOn(false);
  }, [isParentalControlsEnabled, loadingFeatureFlags, loadingParentalControls]);

  useEffect(
    function whenDisablingParentalControls() {
      if (loadingParentalControls || isParentalControlsEnabled) {
        return;
      }

      localStorage.removeItem(FAMILY_MODE_STORAGE_KEY);
      setIsFamilyModeOn(false);
    },
    [isParentalControlsEnabled, loadingParentalControls]
  );

  const toggleFamilyMode = (isToggled: boolean) => {
    const nextWeekDate = new Date().setDate(new Date().getDate() + 7);
    localStorage.setItem(
      FAMILY_MODE_STORAGE_KEY,
      JSON.stringify({ isToggled, expiresAt: nextWeekDate })
    );

    setIsFamilyModeOn(isToggled);
  };

  return {
    // `isFamilyModeOn` can be `null`, meaning no selected mode (need to select)
    isFamilyModeOn: isFamilyFeatureEnabled && isFamilyModeOn,
    loadingIsFamilyModeOn,
    toggleFamilyMode,
  };
}
