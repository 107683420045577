import React from "react";
import PropTypes from "prop-types";
import { Lock } from "../Icon";
import InputAddon from "./InputAddon";

const defaultProps = {
  errors: {},
  handleChange: null,
  required: false,
  touched: {},
  name: "password",
  id: "password",
  placeholder: "Password",
  isSignUp: false,
};

const propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.shape({}),
  required: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  touched: PropTypes.shape({}),
  isSignUp: PropTypes.bool,
  placeholder: PropTypes.string,
};

const InputPassword = ({ isSignUp = false, ...props }) => (
  <InputAddon
    {...props}
    isSignUp={isSignUp}
    icon={Lock}
    type="password"
    placeholder="Password (6+ characters)"
    label={isSignUp || "Password"}
    validate={values => {
      const { required } = props;
      if (required && (!values || values === "")) {
        return "Password is required";
      }
      if (values.length < 6) {
        return "Must be 6 characters or more";
      }

      return null;
    }}
  />
);

InputPassword.defaultProps = defaultProps;
InputPassword.propTypes = propTypes;

export default InputPassword;
