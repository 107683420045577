import React from "react";
import styled from "styled-components";
import Icon, { Facebook } from "../../Icon";
import Button from "../index";

const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding: 12px;

  span {
    flex: 1;
    text-transform: uppercase;
  }
`;

const FacebookLoginButton = ({
  message = "Log in with Facebook",
  ...props
}: {
  message: string;
}) => (
  <StyledButton {...props} variant="facebook">
    <Icon mr={2} width="16px" height="16px" position="absolute" as={Facebook} />
    <span>{message}</span>
  </StyledButton>
);

export default FacebookLoginButton;
