const getStartLevel = (cachedQuality, levels) => {
  let levelIndex = levels.length - 1;
  for (; levelIndex >= 0; levelIndex--) {
    const levelBitrate = levels[levelIndex].bitrate;
    if (cachedQuality.bitrate >= levelBitrate) {
      break;
    }
  }
  return Math.max(0, levelIndex);
};

export default getStartLevel;
