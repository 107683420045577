import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Avatar from "app/components/Avatar";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { useFamilyFriendlyFlag, useFeatureFlags } from "hooks/useFeatureFlags";
import { useGetParentalControlsQuery } from "services/graphql";
import { DropdownMenu } from "app/components/Dropdown";
import { useGetUserProfilePhotoUrl } from "modules/selectors";
import { FamilyToggle } from "./FamilyToggle";
import { Links } from "./Links";

interface RootState {
  user: {
    public: {
      profile_pic: string;
    };
  };
  components: {
    Navbar: {
      height: number;
    };
  };
  auth: {
    isAnonymous: boolean;
    providerData: UserInfo[];
  };
}

// Could potentially pull firebase types: https://firebase.google.com/docs/reference/js/v8/firebase.UserInfo
interface UserInfo {
  photoURL: string;
}

const StyledButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
`;

export const DropdownAvatar = () => {
  const isAnonymous = useSelector(({ auth }: RootState) => auth.isAnonymous);
  const profilePicture = useSelector(
    ({ user }: RootState) => user.public.profile_pic
  );
  const providerData = useSelector(
    ({ auth }: RootState) => auth.providerData && auth.providerData[0]
  );
  const { isFamilyModeOn } = useFamilyModeSetting();
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const { data: userData } = useGetParentalControlsQuery();
  const isParentalControlsEnabled = userData?.me?.parentalControls?.isEnabled;

  return (
    !isAnonymous && (
      <DropdownMenu
        align="end"
        direction="bottom"
        menuButton={
          <StyledButton type="button">
            <Avatar
              height={{ _: "30px", sm: "32px" }}
              width={{ _: "30px", sm: "32px" }}
              alt="Profile Avatar"
              src={
                profilePicture ||
                (providerData?.photoURL &&
                  `${providerData.photoURL}?height=240&width=240`)
              }
            />
          </StyledButton>
        }
      >
        {isFamilyFeatureEnabled && isParentalControlsEnabled ? (
          <>
            <FamilyToggle />
            {!isFamilyModeOn && <Links />}
          </>
        ) : (
          <Links />
        )}
      </DropdownMenu>
    )
  );
};
