import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import ReportOverlay from "app/components/Challenges/ChallengesComponents/ReportOverlay";

const ThumbnailWrapper = styled(Flex)`
  cursor: pointer;
`;

const SubmissionsItem = ({ submission, openModal }) => {
  const { reportedByMe, thumbnailUrl } = submission;
  const onClick = () => openModal(submission);

  return (
    <ThumbnailWrapper position="relative" borderRadius="2px" onClick={onClick}>
      <Thumbnail
        src={thumbnailUrl}
        width={{ xs: "60px", md: "60px", lg: "63px" }}
        height={{ xs: "97px", md: "97px", lg: "102px" }}
        maxWidth="63px"
      />
      {reportedByMe && <ReportOverlay iconWidth="30px" iconHeight="30px" />}
    </ThumbnailWrapper>
  );
};

SubmissionsItem.propTypes = {
  submission: PropTypes.shape({}).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default SubmissionsItem;
