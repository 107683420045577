import React from "react";
import { useHistory } from "react-router";
import Button, { ButtonProps } from "./index";

interface Props extends ButtonProps {
  children: any;
  to: string;
}

const LinkButton = ({ children, to, ...rest }: Props) => {
  const history = useHistory();
  return (
    <Button
      {...rest}
      onClick={() => {
        history.push(to);
      }}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
