import { ApolloLink } from "@apollo/client";
import isServer from "helpers/isServer";
import { EventEmitter } from "events";
import firebase from "../../config/firebase-config";

class AwaitAuthLink extends ApolloLink {
  constructor() {
    super();
    this.hasAuthToken = false;
    this.listenToIdToken();
  }

  emitter = new EventEmitter();

  listenToIdToken() {
    firebase.auth().onIdTokenChanged(user => {
      if (!user) {
        this.hasAuthToken = false;
        return;
      }
      this.emitter.emit("ready");
      this.hasAuthToken = true;
    });
  }

  request(operation, forward) {
    if (isServer || this.hasAuthToken) {
      return forward(operation);
    }
    return new Promise(success => {
      this.emitter.once("ready", () => {
        success(forward(operation));
      });
    });
  }
}

export default AwaitAuthLink;
