import { createSlice } from "@reduxjs/toolkit";
import storage from "config/localforage-config";
import persistReducer from "config/persist-reducer";

const initialState = {
  isFrontView: true,
  mirrored: true,
  showFullscreen: false,
  showWebcam: false,
};

const playerSettingsSlice = createSlice({
  name: "playerSettings",
  initialState,
  reducers: {
    setField(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

export const { setField } = playerSettingsSlice.actions;

export default persistReducer(
  {
    key: "playerSettings",
    version: 1,
    storage,
    blacklist: ["showFullscreen"],
  },
  playerSettingsSlice.reducer
);
