import { SUBSCRIPTION_STATUSES } from "../../constants";

import {
  GET_PRIVATE_DATA,
  GET_PROGRESS_DATA,
  GET_PUBLIC_DATA,
  GET_SUBSCRIPTION_DATA,
  GET_CLASS_RATING_DATA,
  RESET_USER,
  SAVE_CLASS,
  SAVE_CONTENT_PROGRESS,
  SAVE_PRIVATE_DATA,
  SAVE_PROGRAM,
  SAVE_PUBLIC_DATA,
  SAVE_STYLE,
  SAVE_CLASS_RATING,
  START_PROGRAM,
} from "./actions-types";

import store from "./store";

export default (state = store, action) => {
  switch (action.type) {
    case RESET_USER:
      return store;

    case GET_PUBLIC_DATA:
      return {
        ...state,
        public: { ...action.results, loaded: true },
      };

    case SAVE_PUBLIC_DATA:
      return state;

    case GET_PRIVATE_DATA:
      return {
        ...state,
        private: { ...action.results, loaded: true },
      };

    case GET_SUBSCRIPTION_DATA: {
      const {
        isActive,
        subscription,
        primarySubscription,
        id,
      } = action.results;

      return {
        ...state,
        id,
        subscription: {
          ...state.subscription,
          activeSubscription: isActive,
          primarySubscription,
          hasSubscription: !!primarySubscription,
          loaded: true,
          isPaused: subscription?.status === SUBSCRIPTION_STATUSES.paused,
          isPauseScheduled: subscription?.isPauseScheduled,
        },
      };
    }

    case SAVE_PRIVATE_DATA:
      return state;

    case GET_PROGRESS_DATA:
      return {
        ...state,
        progress: {
          ...action.results,
          loaded: true,
        },
      };

    case GET_CLASS_RATING_DATA:
      return {
        ...state,
        classRatings: { ...action.results, loaded: true },
      };

    case SAVE_CLASS:
      return state;

    case SAVE_PROGRAM:
      return state;

    case SAVE_CONTENT_PROGRESS:
      return state;

    case START_PROGRAM:
      return state;

    case SAVE_STYLE:
      return state;

    case SAVE_CLASS_RATING:
      return state;

    default:
      return state;
  }
};
