import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactPlayer from "react-player/lazy";
import { isSafari } from "react-device-detect";
import useHlsQualityTracker from "hooks/useHlsQualityTracker";

const StyledReactPlayer = styled(ReactPlayer)`
  /* iFrame styling for YouTube links to hide title */
  iframe {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: calc(100% + 120px);
  }

  video {
    object-fit: ${({ objectFit }) => objectFit};
  }
`;

const VideoPlayer = ({ onReady, url, ...rest }) => {
  const trackHlsQuality = useHlsQualityTracker();

  const safariUrl = useMemo(
    () => [
      {
        src: url,
        type: "application/x-mpegURL",
      },
    ],
    [url]
  );

  return (
    <StyledReactPlayer
      config={{
        youtube: {
          playerVars: {
            fs: 0,
            rel: 0,
          },
        },
        hlsOptions: {
          autoStartLoad: false,
        },
      }}
      onReady={player => {
        trackHlsQuality(player);
        if (onReady) {
          onReady(player);
        }
      }}
      width="100%"
      height="100%"
      // Band-aid fix below due to not knowing the real cause of the following issues:
      // Issues occuring with Safari not playing videos immediately if type is undefined
      // Issues occuring with Chrome not playing videos immediately if type is defined as application/x-mpegURL
      url={isSafari ? safariUrl : url}
      {...rest}
    />
  );
};

VideoPlayer.defaultProps = {
  onReady: null,
};

VideoPlayer.propTypes = {
  onReady: PropTypes.func,
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
