/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";
import { border, space, layout, position, color } from "styled-system";
import Div from "app/components/Div";
import React from "react";
import PropTypes from "prop-types";
import Icon from "app/components/Icon";

export const Button = styled.button.attrs({
  color: "white",
})`
  display: flex;
  align-items: center;
  background: rgba(34, 34, 34, 0.4);
  border: 1px solid;
  border-radius: 2px;
  text-transform: uppercase;

  @media (hover: hover) {
    :hover {
      color: ${({ theme }) => theme.colors.gold};
    }
  }
  ${border}
  ${space}
  ${color}
`;

Button.defaultProps = {
  px: 4,
  py: 2,
};

export const HoverWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ControlIcon = styled(Icon)`
  width: ${({ width }) => width || "24px"};
  height: ${({ height }) => height || "24px"};
  color: ${({ theme }) => theme.colors.white};

  ${color}
`;

const buttonStyle = css`
  color: ${({ theme }) => theme.colors.white};
  background: transparent;
  border: none;
  display: flex;
  width: 40px;
  padding: 8px;
  justify-content: center;
  font-size: 16px;

  ${space}
  ${layout}
  ${position}
`;

const DisabledButton = styled.div`
  cursor: not-allowed;
  ${ControlIcon} {
    opacity: 0.2;
  }

  ${buttonStyle}
`;

const NormalButton = styled.button`
  ${buttonStyle}
`;

// Using a DisabledButton to mock styling of disabled button since Tooltip is breaking on disabled inputs
// https://github.com/react-component/tooltip/issues/18

export const ControlButton = ({ disabled = false, ...props }) => {
  return disabled ? (
    <DisabledButton {...props} onClick={() => {}} />
  ) : (
    <NormalButton {...props} />
  );
};

ControlButton.defaultProps = {
  disabled: false,
};

ControlButton.propTypes = {
  disabled: PropTypes.bool,
};

export const ResizeableWrapper = styled.div`
  height: 100%;
  max-width: 100%;
  background: #000000;

  .react-resizable {
    position: relative;
  }

  .react-resizable-handle {
    position: absolute;
    width: 5px;
    opacity: 0.75;
    height: 100%;
    right: 0;
    background-position: bottom right;
    background: ${({ theme }) => theme.colors.primary[0]};
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: ew-resize;
    transition: width 0.1s ease-in-out;

    @media (hover: hover) {
      :hover {
        width: 10px;
        opacity: 1;
      }
    }
  }
  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 0;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
  }
  .react-resizable-handle-e {
    right: 0;
    bottom: 0;
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
  }
  .react-resizable-handle-s {
    bottom: 0;
  }
`;

const StyledResizeableHandle = styled.div`
  position: absolute;
  opacity: 0.75;
  width: 10px;
  height: 100%;
  right: 0;
  background-position: bottom right;
  background: ${({ theme }) => theme.colors.black};
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: ew-resize;
  transition: width 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  opacity: 1;

  ${({ resizeHandles }) => {
    switch (resizeHandles) {
      case "sw":
        return `
          bottom: 0;
          left: 0;
          cursor: sw-resize;
        `;
      case "se":
        return `
        bottom: 0;
        right: 0;
        cursor: se-resize;
      `;
      case "nw":
        return `
          top: 0;
          left: 0;
          cursor: nw-resize;
        `;
      case "ne":
        return `
          top: 0;
          right: 0;
          cursor: ne-resize;
        `;
      case "w":
        return `
          left: 0;
          top: 0;
          cursor: ew-resize;
        `;
      case "e":
        return `
          right: 0;
          top: 0;
          bottom: 0;
          cursor: ew-resize;
        `;
      case "n":
        return `
          top: 0;
          left: 50%;
          margin-left: -10px;
          cursor: ns-resize;
        `;
      case "s":
        return `
          bottom: 0;
          left: 50%;
          margin-left: -10px;
          cursor: ns-resize;
        `;

      default:
        return "";
    }
  }}
`;

export const ResizeableHandle = resizeHandles => {
  return (
    <StyledResizeableHandle resizeHandles={resizeHandles}>
      <Div width="3px" height="40px" bg="monochrome.5" borderRadius="30px" />
    </StyledResizeableHandle>
  );
};
