import { lighten } from "polished";

// Design system for reference: https://www.figma.com/file/LUevMJPw8oTOUwloAedXQb/103019?node-id=0%3A1
// Color naming for reference: https://brand.steezy.co/#color

const monochrome = [
  "#FFFFFF",
  "#EBEFEF",
  "#C8CBCB",
  "#A5A8A8",
  "#848787",
  "#656666",
  "#474848",
  "#2B2C2C",
  "#111111",
  "#222222",
  "#000000",
  "#EAEEEE",
];

const champYellow = [
  "#FFE08F",
  "#FFD66B",
  "#FFCC47",
  "#FFC224",
  "#FFB800",
  "#DB9E00",
  "#B88500",
  "#946B00",
  "#705100",
];

const redMercedes = [
  "#EB9E9E",
  "#E57F7F",
  "#DF6060",
  "#D84141",
  "#D22222",
  "#B51D1D",
  "#971818",
  "#7A1414",
  "#5C0F0F",
];

const cleanMint = [
  "#C8F9DB",
  "#B6F7D0",
  "#A4F5C5",
  "#93F3B9",
  "#81F1AE",
  "#6FCF96",
  "#5DAE7D",
  "#4B8C65",
  "#396A4D",
];

export default {
  black: monochrome[8], // for quicker access
  blue: "#0B79FB",
  blueberry: "#513AE3",
  bulbaGreen: cleanMint[4],
  cleanMint,
  champYellow,
  facebook: "#3D5A98",
  google: "#DE5246",
  googleHover: "#F5F5F5",
  gold: champYellow[4],
  greys: ["#EBEFEF", "#B4B7B7", "#444444"],
  twitter: "#2DAAE1",
  // monochrome are similar to greys but were introduced in a later version
  // of the design system that added more colors/shades
  // adding TODO for slow migration
  // TODO: slowly move all greys to monochrome array
  monochrome,
  magenta: "#B515B8",
  orange: "#EC6125",
  redMercedes,
  sky: "#35B6ED",
  yellow: champYellow[4],
  white: monochrome[0], // for quicker access

  // FAMILY colors
  familyBlue: "#5A86DF",
  familyOrange: "#EE911D",
  familyPurple: "#9F36C8",
  familyYellow: "#F7BC00",
  familyTeal: "#0C4134",

  // Legacy colors
  // These colors existed before Design system
  // They will remain until replaced
  advanced: "#F82B60",
  beginner: "#5CB85C",
  blueTransparent: "rgba(74, 144, 226, 0.4)",
  brown: "#8A6D3B",
  danger: "#EB5A46",
  darkGrey: "#434343", // TODO: Deprecate
  green: "#00A779",
  grey: "#BCBABA",
  intermediate: "#FCB400",
  lightGrey: "#EBEFEF", // TODO: Deprecate
  limeGreen: "#7ED321",
  medGrey: "#9B9B9B", // TODO: Deprecate
  neutralGray: "#F6F8F9", // TODO: Deprecate
  paypal: ["#009CDE", lighten(0.1, "#009CDE")],
  primary: ["#0B79FB", lighten(0.1, "#0B79FB")],
  red: "#c10000",
  spotify: "#1CB954",
  success: "#58A107",
  warning: "#FFBE27",
  youtube: "#FF0000",
};
