import React from "react";
import styled from "styled-components";
import { variant as styledSystemVariant } from "styled-system";
import Icon, { Close } from "../Icon";

const StyledButton = styled.button`
  padding: 8px;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const buttonVariants = {
  light: {
    background: "rgba(0, 0, 0, 0.1)",
    color: "monochrome.7",
    svg: {
      color: "monochrome.7",
    },
  },
  dark: {
    background: "rgba(0, 0, 0, 0.4)",
    color: "monochrome.2",
    svg: {
      color: "monochrome.2",
    },
  },
};

export const CloseButton = styled(StyledButton)<{ variant: string }>(
  styledSystemVariant({
    variants: buttonVariants,
  })
);

interface Props {
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  variant?: "light" | "dark";
}

export default ({ onClick, variant = "dark", ...rest }: Props) => (
  <CloseButton
    variant={variant}
    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // Stop propagation since we handle clicks on Wrapper
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      onClick(e);
    }}
    {...rest}
  >
    <Icon as={Close} width="20px" height="20px" />
  </CloseButton>
);
