import { contentSelectClass } from "services/typewriter/segment";
import { useAccountMode } from "hooks/useAccountMode";
import { useCanUserTakeClass } from "hooks/Classes";

export function useContentSelectClassEvent({ classData }: { classData: any }) {
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classData?.id,
  });
  const { accountMode } = useAccountMode();

  return {
    contentSelectClassEvent: ({
      element,
      location,
      path,
      playlist_id,
      selected_from,
      otherEventAttributes = {},
    }: {
      element?: string;
      location: string;
      path?: string;
      playlist_id?: string;
      selected_from: string;
      otherEventAttributes?: any;
    }) =>
      contentSelectClass({
        account_mode: accountMode,
        class_id: classData?.id,
        class_title: classData?.title,
        content_locked: !canUserTakeClass,
        element,
        event_time: new Date().toISOString(),
        location,
        path,
        playlist_id,
        selected_from,
        title: classData?.title,
        ...otherEventAttributes,
      }),
  };
}
