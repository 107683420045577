import axios from "services/api";
import isServer from "helpers/isServer";

export const GET_LOCATION = "classes/GET_LOCATION";
export const ERROR_GET_LOCATION = "classes/ERROR_GET_LOCATION";

const initialState = {
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION:
      return {
        ...state,
        ...action.results,
        loaded: true,
      };

    case ERROR_GET_LOCATION:
      return {
        ...state,
        loaded: true,
      };

    default:
      return state;
  }
};

export const getLocation = () => async dispatch => {
  if (isServer) {
    return;
  }
  const isLocationStored = JSON.parse(localStorage.getItem("iplocation"));
  if (!isLocationStored) {
    try {
      const { data } = await axios.get(
        "https://api.ipapi.com/api/check?access_key=82856ec0cfc796bade867f2ab7497ca5"
      );
      localStorage.setItem("iplocation", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: ERROR_GET_LOCATION,
      });
      return;
    }
  }

  const storedLocation = JSON.parse(localStorage.getItem("iplocation"));

  dispatch({
    type: GET_LOCATION,
    results: storedLocation,
  });
};
