/* eslint-disable camelcase */
import persistReducer from "config/persist-reducer";
import storage from "config/localforage-config";
import { addEventLogAction } from "../party";
import {
  HIDE_FLASH_ACTIVITY,
  PAUSE_CLASS,
  PLAY_CLASS,
  RESET_CLASS_PLAYER,
  SET_LOOPING_RANGE_CLASS,
  SET_SPEED_CLASS,
  SHOW_FLASH_ACTIVITY,
  START_LOOPING_CLASS,
  STOP_CLASS,
  STOP_LOOPING_CLASS,
} from "./actionTypes";

const initialState = {
  flashedActivity: {},
  showFlashActivity: false,
  playing: false,
  isStopped: false,
  looping: false,
  loopingRange: [0, 60],
  speed: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FLASH_ACTIVITY:
      return {
        ...state,
        showFlashActivity: true,
        flashedActivity: action.flashedActivity,
      };

    case HIDE_FLASH_ACTIVITY:
      return {
        ...state,
        showFlashActivity: false,
      };

    case PLAY_CLASS:
      return {
        ...state,
        playing: true,
        isStopped: false,
      };

    case PAUSE_CLASS:
      return {
        ...state,
        playing: false,
        isStopped: false,
      };

    case RESET_CLASS_PLAYER:
      return initialState;

    case SET_SPEED_CLASS:
      return {
        ...state,
        speed: action.speed,
      };

    case SET_LOOPING_RANGE_CLASS:
      return {
        ...state,
        loopingRange: action.loopingRange,
      };

    case START_LOOPING_CLASS:
      return {
        ...state,
        looping: true,
      };

    case STOP_LOOPING_CLASS:
      return {
        ...state,
        looping: false,
      };

    case STOP_CLASS:
      return {
        ...state,
        playing: false,
        isStopped: true,
      };

    default:
      return state;
  }
};

export const playClassAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls`)
      .update({ lastActorUid: uid, playing: true, membersStopped: null });

    dispatch(addEventLogAction("resumed the class"));
  }

  dispatch({
    type: PLAY_CLASS,
  });
};

export const pauseClassAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls`)
      .update({ lastActorUid: uid, playing: false, membersStopped: null });

    dispatch(addEventLogAction("paused the class"));
  }

  dispatch({
    type: PAUSE_CLASS,
  });
};

export const resetClassPlayerAction = () => dispatch => {
  dispatch({
    type: RESET_CLASS_PLAYER,
  });
};

export const startLoopingClassAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls`)
      .update({ lastActorUid: uid, looping: true });

    dispatch(addEventLogAction("started a loop"));
  }

  dispatch({
    type: START_LOOPING_CLASS,
  });
};

export const setLoopingRangeClassAction = loopingRange => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls`)
      .update({ lastActorUid: uid, loopingRange });
  }

  dispatch({
    type: SET_LOOPING_RANGE_CLASS,
    loopingRange,
  });
};

export const setSpeedClassAction = speed => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls`)
      .update({ lastActorUid: uid, speed });

    dispatch(addEventLogAction(`changed playback speed to "${speed}x"`));
  }

  dispatch({
    type: SET_SPEED_CLASS,
    speed,
  });
};

export const stopLoopingClassAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls`)
      .update({ lastActorUid: uid, looping: false });

    dispatch(addEventLogAction("stopped a loop"));
  }

  dispatch({
    type: STOP_LOOPING_CLASS,
  });
};

export const stopClassAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const partyId = user?.private?.party?.id;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  if (party.started) {
    await firebase
      .database()
      .ref(`parties/${partyId}/playerControls/membersStopped`)
      .update({ [uid]: true });
  }
  dispatch({
    type: STOP_CLASS,
  });
};

export const flashActivityAction = (title, icon, position) => dispatch => {
  dispatch({
    type: SHOW_FLASH_ACTIVITY,
    flashedActivity: {
      title,
      icon,
      position,
    },
  });
  setTimeout(() => dispatch({ type: HIDE_FLASH_ACTIVITY }), 300);
};

export default persistReducer(
  {
    key: "classPlayer",
    version: 1,
    storage,
    whitelist: ["speed"],
  },
  reducer
);
