import React, { useEffect, useState, useRef } from "react";
import useOnScreen from "hooks/useOnScreen";

const Waypoint = ({ fetchData = () => {}, hasMore = false }) => {
  const [loading, setLoading] = useState(false);
  const waypointRef = useRef();
  const onScreen = useOnScreen(waypointRef);

  useEffect(() => {
    if (hasMore && onScreen && !loading) {
      fetchMoreData();
    }
  }, [hasMore, onScreen]);

  async function fetchMoreData() {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }

  return <div ref={waypointRef} />;
};

export { Waypoint };
