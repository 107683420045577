import "intersection-observer";
import React from "react";
import { hydrate, render } from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import ErrorReporter from "services/error-reporter";
import { loadableReady } from "@loadable/component";
import firebase from "config/firebase-config";
import env from "helpers/env";
import createStore from "./store";
import Application from "./app";
import client from "./graphql/client";
import "./config/moment-config";

// Initialize error reporter. It's important to do this early and outside render scope so we can catch high-level errors.
ErrorReporter.init();

smoothscroll.polyfill();

const initialState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const { history, persistor, store } = createStore({ initialState, firebase });

const root = document.getElementById("root");
const app = (
  <Application
    history={history}
    persistor={persistor}
    store={store}
    firebaseInstance={firebase}
    apolloClient={client}
  />
);

if (env("WEBPACK_SERVE")) {
  render(app, root);
} else {
  loadableReady(() => {
    hydrate(app, root);
  });
}
