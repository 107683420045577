import { lighten } from "polished";
import colors from "./colors";

export default {
  primary: {
    color: colors.white,
    background: colors.primary[0],
    "@media (hover: hover)": {
      ":hover": {
        background: colors.primary[1],
      },
    },
  },
  inversePrimary: {
    color: colors.primary[0],
    background: colors.white,
  },
  secondary: {
    color: colors.black,
    background: "none",
    "@media (hover: hover)": {
      ":hover": {
        background: colors.lightGrey,
      },
    },
  },
  basic: {
    color: colors.black,
    background: colors.white,
    "@media (hover: hover)": {
      ":hover": {
        background: colors.lightGrey,
      },
    },
  },
  success: {
    color: colors.white,
    background: colors.success,
    "@media (hover: hover)": {
      ":hover": {
        background: lighten(0.1, colors.success),
      },
    },
  },
  danger: {
    color: colors.white,
    background: colors.danger,
    "@media (hover: hover)": {
      ":hover": {
        background: lighten(0.1, colors.danger),
      },
    },
  },
  warning: {
    color: colors.white,
    background: colors.warning,
    "@media (hover: hover)": {
      ":hover": {
        background: lighten(0.1, colors.warning),
      },
    },
  },
  outline: {
    color: colors.black,
    background: colors.white,
    border: `1px solid ${colors.black}`,
    "@media (hover: hover)": {
      ":hover": {
        background: colors.lightGrey,
      },
    },
  },
  hollow: {
    color: colors.white,
    background: "none",
    border: `1px solid ${colors.white}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.blue,
        background: colors.white,
      },
    },
  },
  hollowHoverGold: {
    color: colors.white,
    background: "none",
    border: `1px solid ${colors.white}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.gold,
        border: `1px solid ${colors.gold}`,
      },
    },
  },
  hollowBlack: {
    color: colors.black,
    borderRadius: "3px",
    background: "none",
    border: `2px solid ${colors.black}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.white,
        background: colors.black,
      },
    },
  },
  hollowBlue: {
    color: colors.primary[0],
    background: "none",
    border: `2px solid ${colors.primary[0]}`,
    "@media (hover: hover)": {
      ":hover": {
        color: colors.white,
        background: colors.primary[0],
      },
    },
  },
  twitter: {
    color: colors.white,
    background: colors.twitter,
    "@media (hover: hover)": {
      ":hover": {
        background: lighten(0.1, colors.twitter),
      },
    },
  },
  facebook: {
    color: colors.white,
    background: colors.facebook,
    "@media (hover: hover)": {
      ":hover": {
        background: lighten(0.1, colors.facebook),
      },
    },
  },
  google: {
    color: colors.monochrome[8],
    background: colors.monochrome[1],
    "@media (hover: hover)": {
      ":hover": {
        background: colors.googleHover,
      },
    },
  },
  apple: {
    color: colors.white,
    background: colors.black,
    "@media (hover: hover)": {
      ":hover": {
        background: lighten(0.1, colors.black),
      },
    },
  },
};
