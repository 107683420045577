import { useQuery } from "@apollo/client";
import { VIDEO_UPLOAD_BY_ID_PUBLIC } from "graphql/challenges";

export default ({ options = {} } = {}) => {
  const { data = { videoUploadByIdPublic: {} }, loading, error } = useQuery(
    VIDEO_UPLOAD_BY_ID_PUBLIC,
    options
  );

  const videoUploadData = data.videoUploadByIdPublic;

  return [videoUploadData, loading, error];
};
