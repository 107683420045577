import axios from "services/api";

export const SET_SELECTED_PLAN = "checkout/SET_SELECTED_PLAN";
export const APPLY_COUPON = "checkout/APPLY_COUPON";
export const RESET_COUPON = "checkout/RESET_COUPON";
export const SET_BRAINTREE_COUPON_VERIFICATION =
  "checkout/SET_BRAINTREE_COUPON_VERIFICATION";

const initialState = {
  selectedPlan: null,
  coupon: null,
  discountPayload: {},
  recurringCharge: null,
  isVerifiedOnBraintree: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PLAN: {
      const { results: plan } = action;
      return {
        ...state,
        selectedPlan: plan,
        recurringCharge: plan.total_price,
      };
    }
    case APPLY_COUPON: {
      const { results } = action;
      return {
        ...state,
        recurringCharge: results.newRecurringCharge,
        coupon: results.coupon,
        discountPayload: results.discountPayload,
        priceAfterTrial: results.newPriceAfterTrial,
      };
    }
    case RESET_COUPON: {
      return {
        ...state,
        recurringCharge: state.selectedPlan.total_price,
        coupon: null,
        isVerifiedOnBraintree: null,
      };
    }
    case SET_BRAINTREE_COUPON_VERIFICATION: {
      const { results } = action;
      return {
        ...state,
        isVerifiedOnBraintree: results.verified,
      };
    }
    default:
      return state;
  }
};

export const setSelectedPlanAction = ({ plan }) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_SELECTED_PLAN,
      results: plan,
    });
    resolve();
  });

export const applyCouponAction = (newCouponId, plans) => (
  dispatch,
  getState
) => {
  try {
    const { cart } = getState();
    const { selectedPlan: plan } = cart;
    const planIds = Object.keys(plans);
    return axios
      .get(`/api/payments/coupon/${newCouponId}`, {
        params: {
          planIds,
          selectedPlanId: plan.id,
        },
      })
      .then(({ data }) => {
        const coupon = data;
        if (coupon.code === "UNVERIFIED_BRAINTREE_COUPON") {
          dispatch({
            type: "SET_BRAINTREE_COUPON_VERIFICATION",
            results: {
              verified: false,
            },
          });
        } else {
          dispatch({
            type: "SET_BRAINTREE_COUPON_VERIFICATION",
            results: {
              verified: true,
            },
          });
        }

        let todayDiscount;
        let newPriceAfterTrial = plan.total_price;
        let newRecurringCharge = plan.total_price;

        const couponPercentOff = coupon.percent_off && coupon.percent_off / 100;
        const todayTotal =
          Math.round((plan.total_price - todayDiscount) * 100) / 100;

        if (coupon.amount_off) {
          todayDiscount = coupon.amount_off / 100;
          newPriceAfterTrial = plan.total_price - todayDiscount;
        } else if (coupon.percent_off) {
          todayDiscount = Math.round(plan.total_price * couponPercentOff);
          newPriceAfterTrial =
            plan.total_price - (plan.total_price * coupon.percent_off) / 100;
        }

        newPriceAfterTrial = Math.round(newPriceAfterTrial * 100) / 100;

        if (coupon.duration === "forever") {
          newRecurringCharge = newPriceAfterTrial;
        }

        const discountPayload = {
          discount: todayDiscount,
          discount_percent: couponPercentOff,
          discount_amount: coupon.amount_off && coupon.amount_off / 100,
          plan_price: plan.total_price,
          plan_price_after_discount: todayTotal,
        };

        dispatch({
          type: APPLY_COUPON,
          results: {
            newRecurringCharge,
            coupon,
            discountPayload,
            newPriceAfterTrial,
          },
        });

        return {
          newRecurringCharge,
          coupon,
          newPriceAfterTrial,
          discountPayload,
        };
      });
  } catch (error) {
    throw error;
  }
};

export const resetCouponAction = () => dispatch =>
  dispatch({ type: RESET_COUPON });
