import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";

interface Props {
  to: string;
  from: string;
}

const RedirectWithParams = ({ to, from }: Props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const params = `?${queryString.stringify(query)}`;

  return <Redirect from={from} to={`${to}${params}`} />;
};

export default RedirectWithParams;
