import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  font-size: 10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${rotate} 0.7s infinite linear;
  animation: ${rotate} 0.7s infinite linear;

  ${({ width, theme }) => `
  width: ${`${width || 120}px`};
  height: ${`${width || 120}px`};
  border-top: ${`${width / 10 || 12}px`} solid ${theme.colors.blueTransparent};
  border-right: ${`${width / 10 || 12}px`} solid
    ${theme.colors.blueTransparent};
  border-bottom: ${`${width / 10 || 12}px`} solid
    ${theme.colors.blueTransparent};
  border-left: ${`${width / 10 || 12}px`} solid ${theme.colors.primary[0]};
`}

  ${({ light, width }) =>
    light &&
    `
    border-left: ${`${width / 10 || 12}px`} solid ${({ theme }) =>
      theme.colors.white};
`}

${({ margin }) =>
  margin &&
  `
  margin: ${margin}
`}
`;

const Loader = props => {
  return <StyledLoader role="alert" aria-busy="true" {...props} />;
};

export default Loader;
