import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import firebase from "config/firebase-config";
import { v4 as uuidv4 } from "uuid";
import env from "helpers/env";

const init = () => {
  initSentry();
  initContextListeners();
};

const report = (error, context = {}) => {
  if (env("NODE_ENV") === "development") {
    console.warn("Error reported:", error);
  }
  reportToSentry({ error, context });
};

const initSentry = () => {
  sessionStorage.setItem("txid", uuidv4());

  Sentry.init({
    dsn: env("PUBLIC_SENTRY_DSN"),
    integrations: [new Integrations.BrowserTracing()],
    environment: env("NODE_ENV"),
    sampleRate: 0.33,
    tracesSampleRate: 0.33,
    release: `stark@${env("PUBLIC_VERSION")}`,
  });
};

const initContextListeners = () => {
  firebase.auth().onAuthStateChanged(user => {
    const { uid } = user || {};

    const transactionId = sessionStorage.getItem("txid");
    Sentry.configureScope(scope => {
      scope.setTag("transaction_id", transactionId);
      if (!uid) {
        scope.setUser(null);
      } else {
        scope.setUser({ id: uid });
      }
    });
  });
};

const addContextToScope = (scope, context) => {
  Object.keys(context).forEach(key => {
    const value = context[key];
    scope.setTag(key, value);
  });
};

const reportToSentry = ({ error, context }) => {
  Sentry.withScope(function(scope) {
    addContextToScope(scope, context);
    Sentry.captureException(error);
  });
};

export default { report, init };
