import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Icon, {
  ListBulletpoint,
  ListBulletpointSelected,
} from "app/components/Icon";
import { P1 } from "app/components/Typography";

const ModalListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 16px 32px 16px 0;
  cursor: pointer;

  ${({ isLastInRow, theme }) =>
    !isLastInRow && `border-bottom: 1px solid ${theme.colors.monochrome[1]};`}

  ${({ theme }) => theme.mediaQueries.ltmd} {
    padding: 16px 0;
  }
`;

const ReportPostModalListItem = ({
  isLastInRow,
  reason,
  selected,
  setSelectedReportReason,
}) => {
  return (
    <ModalListItem
      onClick={() => setSelectedReportReason(reason.value)}
      selected={selected}
      isLastInRow={isLastInRow}
    >
      <Icon
        width="19px"
        height="19px"
        as={selected ? ListBulletpointSelected : ListBulletpoint}
        mr={3}
      />
      <P1 width={{ _: "80%", md: "100%" }}>{reason.label}</P1>
    </ModalListItem>
  );
};

ReportPostModalListItem.propTypes = {
  isLastInRow: PropTypes.bool.isRequired,
  reason: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedReportReason: PropTypes.func.isRequired,
};

export default ReportPostModalListItem;
