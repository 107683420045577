/* eslint-disable prefer-destructuring */
const breakpoints = ["374px", "480px", "768px", "1170px", "1440px"];

breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.programsLg = "1300px";

export default breakpoints;
