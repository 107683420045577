export const OPEN_FILTERS = "OPEN_FILTERS";
export const CLOSE_FILTERS = "CLOSE_FILTERS";

const initialState = {
  classes: {
    isOpen: false,
  },
  programsV2: {
    isOpen: false,
  },
};

export const openFiltersAction = index => dispatch =>
  dispatch({ type: OPEN_FILTERS, index });

export const closeFiltersAction = index => dispatch =>
  dispatch({ type: CLOSE_FILTERS, index });

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FILTERS:
      return {
        ...state,
        [action.index]: {
          isOpen: true,
        },
      };

    case CLOSE_FILTERS:
      return {
        ...state,
        [action.index]: {
          isOpen: false,
        },
      };

    default:
      return state;
  }
};
