import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import { P1 } from "app/components/Typography";
import ReportOverlay from "app/components/Challenges/ChallengesComponents/ReportOverlay";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import { useGetVideoUploadById } from "hooks/Challenges";
import ChildControls from "./ChildControls";

const VideoPlayer = ({
  challenge,
  containerBg = "white",
  objectFit = "cover",
  playsinline = true,
  startMuted = true,
  loop = true,
  showLoader = true,
  onReady = () => {},
  onProgress = () => {},
  containerBorderRadius = "16px",
  containerHeight = { _: "469px", xs: "621px", lg: "647px" },
  containerWidth = { _: "259px", xs: "343px", lg: "357px" },
  staticSizes = false,
  controls,
  url,
  isAuthoredByMe,
  reportedByMe,
  videoId,
  isPlayingFromParent,
  setPlayingFromParent = () => {},
  onLike = () => {},
  didLike,
  onClickComment = () => {},
  thumbnailUrl,
  ...rest
}) => {
  const playerWrapperRef = useRef();
  const [playerProgressInSeconds, setPlayerProgressInSeconds] = useState(0);
  const [playing, setPlaying] = useState(isPlayingFromParent);
  const [muted, setMuted] = useState(startMuted);
  const [durationOfVideo, setDurationOfVideo] = useState(0);
  const [playerInstance, setPlayerInstance] = useState(null);

  const [videoUploadData, videoUploadLoading] = useGetVideoUploadById({
    options: {
      variables: {
        videoId,
      },
    },
  });

  const loading = videoUploadLoading;

  useEffect(() => {
    setPlaying(isPlayingFromParent);
  }, [isPlayingFromParent]);

  const customSetPlaying = isPlaying => {
    setPlaying(isPlaying);
    setPlayingFromParent(isPlaying);
  };

  const seekTo = useCallback(
    seconds => {
      const safeSeconds = Math.min(Math.max(0, seconds), durationOfVideo);
      setPlayerProgressInSeconds(safeSeconds);
      playerInstance.seekTo(safeSeconds);
    },
    [playerInstance, durationOfVideo, setPlayerProgressInSeconds]
  );

  useEffect(() => setMuted(startMuted), [startMuted]);

  if (loading) {
    return <></>;
  }

  return (
    <Flex
      position="relative"
      flexDirection="column"
      alignItems="center"
      height={containerHeight}
      width={containerWidth}
      maxHeight={staticSizes ? containerHeight : "none"}
      maxWidth={staticSizes ? containerWidth : "none"}
      minHeight={staticSizes ? containerHeight : "none"}
      minWidth={staticSizes ? containerWidth : "none"}
      overflowY="hidden"
      overflowX="hidden"
      borderRadius={containerBorderRadius}
      ref={playerWrapperRef}
    >
      {reportedByMe && (
        <ReportOverlay style={{ zIndex: 1 }} iconWidth="72px" iconHeight="72px">
          <P1 color="white" mt={3}>
            Post Reported
          </P1>
        </ReportOverlay>
      )}
      <ResponsiveVideoPlayer
        containerBg={containerBg}
        objectFit={objectFit}
        playsinline={playsinline}
        playing={playing}
        muted={muted}
        loop={loop}
        showLoader={showLoader}
        controls={controls}
        url={url}
        onError={err => {
          if (err && err.name === "NotAllowedError") {
            setMuted(true);
          }
        }}
        onReady={player => {
          setPlayerInstance(player);
          customSetPlaying(isPlayingFromParent);
          onReady(player);
        }}
        onProgress={playerProgress => {
          setPlayerProgressInSeconds(playerProgress.playedSeconds);
          onProgress(playerProgress);
        }}
        onDuration={duration => {
          setDurationOfVideo(duration);
        }}
        ChildControls={
          <ChildControls
            challengeData={challenge}
            videoUploadData={videoUploadData}
            playing={playing}
            setPlaying={customSetPlaying}
            muted={muted}
            setMuted={setMuted}
            seekTo={seekTo}
            durationOfVideo={durationOfVideo}
            playerProgressInSeconds={playerProgressInSeconds}
            isAuthoredByMe={isAuthoredByMe}
            reportedByMe={reportedByMe}
            videoId={videoId}
            onLike={onLike}
            didLike={didLike}
            onClickComment={onClickComment}
            thumbnailUrl={thumbnailUrl}
            {...rest}
          />
        }
      />
    </Flex>
  );
};

VideoPlayer.propTypes = {
  isAuthoredByMe: PropTypes.bool.isRequired,
  reportedByMe: PropTypes.bool.isRequired,
  videoId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  containerBg: PropTypes.string,
  objectFit: PropTypes.string,
  playsinline: PropTypes.bool,
  startMuted: PropTypes.bool,
  loop: PropTypes.bool,
  showLoader: PropTypes.bool,
  controls: PropTypes.bool,
  onReady: PropTypes.func,
  onProgress: PropTypes.func,
  containerBorderRadius: PropTypes.string,
  containerHeight: PropTypes.shape({}),
  containerWidth: PropTypes.oneOf([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  staticSizes: PropTypes.bool,
  isPlayingFromParent: PropTypes.bool,
  setPlayingFromParent: PropTypes.func,
  onLike: PropTypes.func,
  didLike: PropTypes.bool,
  onClickComment: PropTypes.func,
  thumbnailUrl: PropTypes.string,
};

export default VideoPlayer;
