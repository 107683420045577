import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Span = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
  right: 20px;
`;

const CloseButton = ({ className, onClick, dataTestId }) => (
  <Span
    className={className}
    onClick={onClick}
    aria-label="Close"
    aria-hidden="true"
    data-test-id={dataTestId}
  >
    &times;
  </Span>
);

CloseButton.defaultProps = {
  className: null,
  dataTestId: undefined,
};

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

export default CloseButton;
