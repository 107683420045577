import React, { createContext } from "react";
import usePrevious from "hooks/usePrevious";
import { useLocation } from "react-router";

export const PreviousLocationContext = createContext({
  previousLocation: null,
});

interface Props {
  children: React.ReactNode;
}

export function PreviousLocationProvider({ children }: Props) {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  return (
    <PreviousLocationContext.Provider value={{ previousLocation }}>
      {children}
    </PreviousLocationContext.Provider>
  );
}
