import { REPORT_ACTIVITY } from "graphql/mutations";
import { gql, useMutation } from "@apollo/client";

export default options => {
  const [reportActivity, reportActivityResults] = useMutation(
    REPORT_ACTIVITY,
    options
  );

  return [
    ({ variables }) => {
      return reportActivity({
        variables,
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `VideoUpload:${data?.flaggedActivityCreate?.activityId}`,
            fragment: gql`
              fragment saveReportActivity on VideoUpload {
                reportedByMe
              }
            `,
            data: {
              reportedByMe: true,
            },
          });
        },
      });
    },
    reportActivityResults,
  ];
};
