import React, { useState } from "react";
import Modal from "app/components/Modal";
import { H2 } from "app/components/Typography";
import InputEmail from "app/components/Input/InputEmail";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import InputPassword from "app/components/Input/InputPassword";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { Close } from "app/components/Icon";
import IconButton from "app/components/Button/IconButton";
import Button from "app/components/Button";
import FacebookLoginButton from "app/components/Button/LoginButtons/FacebookLoginButton";
import GoogleLoginButton from "app/components/Button/LoginButtons/GoogleLoginButton";
import AppleLoginButton from "app/components/Button/LoginButtons/AppleLoginButton";
import firebase from "config/firebase-config";
import Alert from "app/components/Alert";
import LineThroughText from "app/components/LineThroughText";
import styled from "styled-components";

const FIREBASE_PROVIDERS = {
  apple: new firebase.auth.OAuthProvider("apple.com"),
  facebook: new firebase.auth.FacebookAuthProvider(),
  google: new firebase.auth.GoogleAuthProvider(),
};

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
`;

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onSuccess(): void;
}

export function ReauthenticateModal({ isOpen, onCancel, onSuccess }: Props) {
  const [error, setError] = useState<firebase.auth.AuthError>(null);

  const reauthenticateWithCredential = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );
      await firebase
        .auth()
        .currentUser.reauthenticateWithCredential(credential);
      onSuccess();
    } catch (err) {
      setError(err);
    }
  };

  const reauthenticateWithProvider = async ({
    providerKey,
  }: {
    providerKey: string;
  }) => {
    try {
      await firebase
        .auth()
        .currentUser.reauthenticateWithPopup(
          FIREBASE_PROVIDERS[providerKey as keyof typeof FIREBASE_PROVIDERS]
        );
      onSuccess();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Modal isOpen={isOpen} maxWidth="475px" width="100%" p="36px">
      <Flex justifyContent="flex-end" width="100%">
        <IconButton
          Icon={Close}
          width="20px"
          height="auto"
          color="grey"
          onClick={onCancel}
        />
      </Flex>
      <Flex flexDirection="column">
        <H2 my="24px" textAlign="center">
          Sign in to update your PIN
        </H2>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={values => {
            reauthenticateWithCredential(values);
          }}
          render={({ touched, errors, handleChange }) => (
            <Form>
              <Flex flexDirection="column">
                <Text fontWeight="bold" fontSize="12px">
                  Email address
                </Text>
                <InputEmail
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  required
                />
              </Flex>
              <Flex flexDirection="column" mt="18px">
                <Text fontWeight="bold" fontSize="12px">
                  Password
                </Text>
                <InputPassword
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                />
              </Flex>
              <Text textAlign="right" mt="8px">
                <StyledLink to="/password-reset">
                  <small>Forgot your password?</small>
                </StyledLink>
              </Text>
              <div>
                <Button mt={3} width="100%" type="submit">
                  Sign In
                </Button>
              </div>
            </Form>
          )}
        />
        {error && (
          <Alert variant="danger" closeAlert={() => setError(null)}>
            {error.message}
          </Alert>
        )}
        <LineThroughText>or</LineThroughText>
        <div>
          <AppleLoginButton
            mb={2}
            width="100%"
            onClick={() => reauthenticateWithProvider({ providerKey: "apple" })}
            message="Sign in with Apple"
          >
            Apple
          </AppleLoginButton>
        </div>
        <div>
          <GoogleLoginButton
            mb={2}
            width="100%"
            onClick={() =>
              reauthenticateWithProvider({ providerKey: "google" })
            }
            message="Sign in with Google"
          />
        </div>
        <div>
          <FacebookLoginButton
            width="100%"
            onClick={() =>
              reauthenticateWithProvider({ providerKey: "facebook" })
            }
            message="Sign in with Facebook"
          />
        </div>
      </Flex>
    </Modal>
  );
}
