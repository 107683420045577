export const GET_PRIVATE_DATA = "user/GET_PRIVATE_DATA";
export const GET_PROGRESS_DATA = "user/GET_PROGRESS_DATA";
export const GET_PUBLIC_DATA = "user/GET_PUBLIC_DATA";
export const GET_SUBSCRIPTION_DATA = "user/GET_SUBSCRIPTION_DATA";
export const GET_CLASS_RATING_DATA = "user/GET_CLASS_RATING_DATA";
export const RESET_USER = "user/RESET_USER";
export const SAVE_CLASS = "user/SAVE_CLASS";
export const SAVE_CONTENT_PROGRESS = "user/SAVE_CONTENT_PROGRESS";
export const SAVE_PRIVATE_DATA = "user/SAVE_PUBLIC_DATA";
export const SAVE_PROGRAM = "user/SAVE_PROGRAM";
export const SAVE_PUBLIC_DATA = "user/SAVE_PUBLIC_DATA";
export const SAVE_STYLE = "user/SAVE_STYLE";
export const SAVE_CLASS_RATING = "user/SAVE_CLASS_RATING";
export const START_PROGRAM = "user/START_PROGRAM";
