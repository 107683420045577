export default {
  public: {
    loaded: false,
  },
  private: {
    loaded: false,
    dance_preferences: {},
  },
  progress: {
    loaded: false,
    "class-progress": {},
    "program-progress": {},
  },
  subscription: {
    loaded: false,
    activeSubscription: false,
    status: "none",
    subscription_status: {},
    subscription: {},
    isPaused: false,
    isPauseScheduled: false,
  },
};
