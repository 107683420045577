import styled from "styled-components";
import React from "react";
import { H5, P2 } from "../Typography";
import Div from "../Div";

export const LimitLineH5 = styled(H5)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const LimitLineP2 = styled(P2)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const LineSeparator = () => (
  <Div px={2} mb={2}>
    <Div height="2px" bg="monochrome.1" width="100%" />
  </Div>
);
