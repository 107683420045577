import styled from "styled-components";
import media from "styled-media-query";
import Flex from "app/components/Flex";
import { P2 } from "app/components/Typography";
import { SMALL_BREAKPOINT } from "constants/index";

export const PrivacyBar = styled(Flex)`
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: white;

  ${media.lessThan(SMALL_BREAKPOINT)`
    padding: 0;
    height: 64px;
    justify-content: center;
    text-align: center;
  `}
`;

export const PrivacyText = styled(P2)`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 15px;

  &:hover {
    color: ${({ theme }) => theme.colors.monochrome[8]};
  }
`;
