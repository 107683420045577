import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { connectRouter } from "connected-react-router";
import storage from "config/localforage-config";
import persistReducer from "config/persist-reducer";

import auth from "./auth";
import checkout from "./checkout";
import classPlayer from "./classPlayer";
import community from "./community";
import components from "./components";
import content from "./content";
import continuity from "./continuity";
import dancePreferences from "./dance-preferences";
import experiment from "./experiment";
import filters from "./filters";
import iplocation from "./iplocation";
import loading from "./loading";
import party from "./party";
import playerSettings from "./player-settings";
import programView from "./program-view";
import progress from "./progress";
import subscription from "./subscription";
import user from "./user";
import cart from "./cart";
import onboarding from "./onboarding";
import error from "./error";
import server from "./server";

const rootPersistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: [],
};

const rootReducer = history =>
  combineReducers({
    auth,
    checkout,
    classPlayer,
    community,
    components,
    content,
    continuity,
    dancePreferences,
    experiment,
    error,
    filters,
    firebase: firebaseReducer,
    iplocation,
    loading,
    party,
    playerSettings,
    programView,
    progress,
    router: connectRouter(history),
    server,
    subscription,
    user,
    onboarding,
    cart,
  });

export default history =>
  persistReducer(rootPersistConfig, rootReducer(history));
