/* eslint-disable camelcase */
import React from "react";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import MemberJoinNotification from "app/components/ClassPlayer/components/MemberJoinNotification";
import moment from "moment";
import convertTimeToString from "helpers/convertTimeToString";
import { PLACEHOLDER_FIRST_NAME } from "constants/index";
import {
  PLAY_CLASS,
  PAUSE_CLASS,
  STOP_CLASS,
  START_LOOPING_CLASS,
  SET_LOOPING_RANGE_CLASS,
  SET_SPEED_CLASS,
  STOP_LOOPING_CLASS,
} from "./classPlayer/actionTypes";
import { setErrorAction } from "./error";

export const MUTE_PARTICIPANTS_AT = "party/MUTE_PARTICIPANTS_AT";
export const RESET_PARTY = "party/RESET_PARTY";
export const SEEK_CLASS_FOR_PARTY = "party/SEEK_CLASS_FOR_PARTY";
export const SET_CURRENT_TIME_IN_SECONDS = "party/SET_CURRENT_TIME_IN_SECONDS";
export const SET_IS_LAST_ACTOR = "party/SET_IS_LAST_ACTOR";
export const SET_LAST_EVENT_LOG = "party/SET_LAST_EVENT_LOG";
export const SET_QUEUE = "party/SET_QUEUE";
export const START_OR_END_PARTY = "party/START_OR_END_PARTY";
export const SET_SIZE_OF_PARTY = "party/SET_SIZE_OF_PARTY";

const initialState = {
  isLastActor: false,
  started: false,
  sizeOfParty: 1,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MUTE_PARTICIPANTS_AT:
      return {
        ...state,
        mutedParticipantsAt: action.mutedParticipantsAt,
      };

    case SET_CURRENT_TIME_IN_SECONDS:
      return {
        ...state,
        currentTimeInSeconds: action.currentTimeInSeconds,
      };

    case SET_IS_LAST_ACTOR:
      return {
        ...state,
        isLastActor: action.isLastActor,
      };

    case SET_QUEUE:
      return {
        ...state,
        queue: action.queue,
      };

    case SEEK_CLASS_FOR_PARTY:
      return {
        ...state,
        timeToSeek: action.timeToSeek,
      };

    case SET_LAST_EVENT_LOG:
      return {
        ...state,
        lastEventLog: action.lastEventLog,
        lastEventTime: moment(action.lastEventTime).format("h:mm a"),
      };

    case SET_SIZE_OF_PARTY:
      return {
        ...state,
        sizeOfParty: action.sizeOfParty,
      };

    case START_OR_END_PARTY:
      return {
        ...state,
        started: action.isStarted,
      };

    case RESET_PARTY:
      return initialState;

    default:
      return state;
  }
};

export const addEventLogAction = log => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user } = getState();
  const firebase = getFirebase();
  const partyId = user.private?.party?.id;
  const firstName = user.public?.first_name || PLACEHOLDER_FIRST_NAME;
  const eventTime = new Date();

  await firebase
    .database()
    .ref(`parties/${partyId}/eventLogs`)
    .push({
      log: `${firstName} ${log}`,
      eventTime: eventTime.toISOString(),
    });
};

export const declineOrApproveMemberAction = ({
  pid,
  uid,
  isApproved,
}) => async (dispatch, getState, { apolloClient }) => {
  try {
    await apolloClient.mutate({
      variables: {
        pid,
        uid,
        isApproved,
      },
      mutation: gql`
        mutation ApprovePartyMember(
          $pid: String!
          $uid: String!
          $isApproved: Boolean!
        ) {
          approvePartyMember(
            input: { pid: $pid, uid: $uid, isApproved: $isApproved }
          ) {
            success
          }
        }
      `,
    });
  } catch (err) {
    console.log({ err });
    // throw err;
  }
};

export const queuePartyAction = ({ pid }) => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  try {
    const response = await apolloClient.mutate({
      variables: {
        pid,
      },
      mutation: gql`
        mutation QueuePartyMember($pid: String!) {
          queuePartyMember(input: { pid: $pid }) {
            id
            status
            accessToken
            expiresAt
            classId
          }
        }
      `,
    });
    const partyData = response?.data?.queuePartyMember;
    if (partyData?.id && partyData?.accessToken) {
      await joinPartyAction();
    }
  } catch (err) {
    const [graphQLError] = err.graphQLErrors;
    if (graphQLError.extensions.code === "BAD_USER_INPUT") {
      dispatch(setErrorAction(graphQLError));
    } else {
      console.log(err);
    }
  }
};

export const leaveQueueAction = ({ pid }) => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  try {
    await apolloClient.mutate({
      variables: {
        pid,
      },
      mutation: gql`
        mutation LeavePartyQueue($pid: String!) {
          leavePartyQueue(input: { pid: $pid }) {
            success
          }
        }
      `,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const setQueueAction = ({ queue }) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { user, party } = getState();
  const firebase = getFirebase();
  const partyId = user?.private?.party?.id;
  const { queue: prevQueue } = party;
  const prevQueuedUIDs = Object.keys(prevQueue || {});
  const currentQueuedUIDs = Object.keys(queue);

  prevQueuedUIDs.forEach(uid => {
    if (!currentQueuedUIDs.includes(uid) && toast.isActive(uid)) {
      toast.dismiss(uid);
    }
  });

  currentQueuedUIDs.forEach(async uid => {
    if (!toast.isActive(uid)) {
      const usersPublicData = await firebase
        .database()
        .ref(`users_public/${uid}`)
        .once("value")
        .then(snap => snap.val() || {});
      toast(
        <MemberJoinNotification
          uid={uid}
          pid={partyId}
          usersPublicData={usersPublicData}
        />,
        {
          position: "top-left",
          closeButton: false,
          toastId: uid,
        }
      );
    }
  });

  dispatch({ type: SET_QUEUE, queue });
};

export const syncPlayerControlsAction = ({
  currentTimeInSeconds,
  playing,
  lastActorUid,
  looping,
  loopingRange,
  speed,
  timeToSeek,
  membersStopped,
}) => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  const firebaseUser = firebase.auth().currentUser;
  const isLastActor = firebaseUser.uid === lastActorUid;

  if (!membersStopped?.[firebaseUser.uid]) {
    if (playing) {
      dispatch({ type: PLAY_CLASS });
    } else {
      dispatch({ type: PAUSE_CLASS });
    }
  }

  if (looping) {
    dispatch({ type: START_LOOPING_CLASS });
  } else {
    dispatch({ type: STOP_LOOPING_CLASS });
  }

  if (!isLastActor) {
    dispatch({ type: SEEK_CLASS_FOR_PARTY, timeToSeek });
  }

  if (speed) {
    dispatch({ type: SET_SPEED_CLASS, speed });
  }
  if (loopingRange) {
    dispatch({ type: SET_LOOPING_RANGE_CLASS, loopingRange });
  }
  dispatch({ type: SET_IS_LAST_ACTOR, isLastActor });
  dispatch({ type: SET_CURRENT_TIME_IN_SECONDS, currentTimeInSeconds });
};

export const muteParticipantsAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { user } = getState();
  const pid = user?.private?.party?.id;

  await firebase
    .database()
    .ref(`/parties/${pid}/mutedParticipantsAt`)
    .set(moment().toISOString());
};

export const listenToPartyDataAction = ({ pid }) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;

  await firebase
    .database()
    .ref(`/parties/${pid}`)
    .on("value", snapshot => {
      const { party } = getState();
      const { mutedParticipantsAt: oldMutedParticipantsAt } = party;
      if (!snapshot.val() || snapshot.val().endedAt) {
        return dispatch(resetPartyAction({ pid }));
      }
      const {
        queue,
        hostId,
        playerControls,
        members,
        mutedParticipantsAt,
      } = snapshot.val();
      const isHost = user.uid === hostId;

      if (isHost) {
        dispatch(setQueueAction({ queue: queue || {} }));
      }

      if (
        mutedParticipantsAt &&
        oldMutedParticipantsAt !== mutedParticipantsAt
      ) {
        dispatch({
          type: MUTE_PARTICIPANTS_AT,
          mutedParticipantsAt,
        });
      }

      if (members) {
        dispatch({ type: SET_SIZE_OF_PARTY, sizeOfParty: members.count });
      }

      if (playerControls.membersStopped?.uid) {
        dispatch({ type: STOP_CLASS });
      }

      return dispatch(syncPlayerControlsAction(playerControls || {}));
    });

  await firebase
    .database()
    .ref(`/parties/${pid}/eventLogs`)
    .orderByChild("eventTime")
    .limitToLast(1)
    .on("child_added", snapshot => {
      const eventLog = snapshot.val();
      dispatch({
        type: SET_LAST_EVENT_LOG,
        lastEventLog: eventLog.log,
        lastEventTime: eventLog.eventTime,
      });
    });
};

export const joinPartyAction = () => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  const {
    user: { private: userPrivate },
    party,
  } = getState();
  const partyToJoin = userPrivate?.party;

  if (!party.started) {
    dispatch({ type: START_OR_END_PARTY, isStarted: true });
  }

  // TODO: Access tokens have expirations and we need to handle that error
  try {
    await apolloClient.mutate({
      variables: {
        pid: partyToJoin.id,
      },
      mutation: gql`
        mutation JoinParty($pid: String!) {
          joinParty(input: { pid: $pid }) {
            sessionId
          }
        }
      `,
    });
  } catch (error) {
    console.log(error);
  }

  dispatch(listenToPartyDataAction({ pid: partyToJoin.id }));
};

export const startPartyAction = ({ classId, isFreeClass = false }) => async (
  dispatch,
  getState,
  { getFirebase, apolloClient }
) => {
  const { classPlayer, party } = getState();
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;

  let partyId = null;
  try {
    const response = await apolloClient.mutate({
      variables: {
        classId: parseInt(classId),
        isFreeClass,
      },
      mutation: gql`
        mutation CreateParty($classId: Int!, $isFreeClass: Boolean) {
          createParty(input: { classId: $classId, isFreeClass: $isFreeClass }) {
            id
          }
        }
      `,
    });
    partyId = response.data.createParty.id;
  } catch (err) {
    // TODO: catch error properly
    console.log({ err });
    throw err;
  }

  if (!party.started) {
    dispatch({ type: SET_IS_LAST_ACTOR, isLastActor: true });
    dispatch({ type: START_OR_END_PARTY, isStarted: true });
  }

  dispatch(push({ search: `pid=${partyId}` }));

  const initPlayerState = () => {
    const { playing, looping, loopingRange, speed } = classPlayer;
    return {
      playerControls: {
        lastActorUid: user.uid,
        playing,
        looping,
        loopingRange,
        speed,
      },
    };
  };
  await firebase
    .database()
    .ref(`/parties/${partyId}`)
    .update(initPlayerState());

  dispatch(listenToPartyDataAction({ pid: partyId }));
};

export const timeoutPartyMemberAction = () => async (
  dispatch,
  getState,
  { getFirebase, apolloClient }
) => {
  const firebase = getFirebase();
  const { user } = getState();
  const pid = user?.private?.party?.id;

  try {
    dispatch({ type: START_OR_END_PARTY, isStarted: false });

    firebase
      .database()
      .ref(`parties/${pid}`)
      .off("value");
    firebase
      .database()
      .ref(`/parties/${pid}/eventLogs`)
      .off("child_added");

    if (pid) {
      await apolloClient.mutate({
        variables: {
          pid,
        },
        mutation: gql`
          mutation TimeoutPartyMember($pid: String!) {
            timeoutPartyMember(input: { pid: $pid }) {
              success
            }
          }
        `,
      });
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const resetPartyAction = ({ pid }) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: RESET_PARTY });

  firebase
    .database()
    .ref(`parties/${pid}`)
    .off();
  firebase
    .database()
    .ref(`/parties/${pid}/eventLogs`)
    .off("child_added");
};

export const leavePartyAction = () => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  const { user } = getState();
  const partyId = user?.private?.party?.id;

  try {
    dispatch(push("/party"));
    await apolloClient.mutate({
      variables: {
        pid: partyId,
      },
      mutation: gql`
        mutation LeaveParty($pid: String!) {
          leaveParty(input: { pid: $pid }) {
            id
            classId
          }
        }
      `,
    });
  } catch (err) {
    // TODO: catch error properly
    console.log({ err });
    throw err;
  }
};

export const setSeenPartyEndedModalAction = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  await firebase
    .database()
    .ref(`/users_private/${uid}/party/seenEndedModalAt`)
    .set(moment().toISOString());
};

export const endPartyAction = () => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  const { user, router } = getState();
  const { location } = router;
  const partyId = user?.private?.party?.id;

  // Remove pid from url to prevent restarting party
  dispatch(push(location.pathname));

  try {
    await apolloClient.mutate({
      variables: {
        pid: partyId,
      },
      mutation: gql`
        mutation EndParty($pid: String!) {
          endParty(input: { pid: $pid }) {
            success
          }
        }
      `,
    });
  } catch (err) {
    // TODO: catch error properly
    console.log({ err });
    throw err;
  }
};

export const seekClassForPartyAction = timeToSeek => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  dispatch({ type: SEEK_CLASS_FOR_PARTY, timeToSeek });
  const { user } = getState();
  const partyId = user?.private?.party?.id;

  await firebase
    .database()
    .ref(`parties/${partyId}/playerControls`)
    .update({ timeToSeek, lastActorUid: uid, membersStopped: null });

  dispatch(addEventLogAction(`skipped to ${convertTimeToString(timeToSeek)}`));
};

export const setCurrentTimeForPartyAction = currentTimeInSeconds => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { user } = getState();
  const partyId = user?.private?.party?.id;

  await firebase
    .database()
    .ref(`parties/${partyId}/playerControls`)
    .update({ currentTimeInSeconds });
};
