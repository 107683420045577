// eslint-disable-next-line import/no-useless-path-segments
import env from "../env";
import isServer from "../isServer";
import isClient from "../isClient";

const clientVarPrefix = "PUBLIC_";
const gcpProjectId =
  process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging"
    ? "steezy-studio"
    : "dev-steezy-studio";

export const envObject = {} as Record<string, string>;
let lastRefreshed = -1;
let _clientEnvString = "";
let envLoading = false;

export const envLoaded = () => lastRefreshed >= 0;

export const clientEnvString = () => _clientEnvString;

export const loadEnv = async () => {
  if (envLoaded() || envLoading) {
    return;
  }
  envLoading = true;

  if (isServer) {
    await loadVarsFromSecretManager();
    await loadVarsFromFileSystem();
    generateClientEnvString();
  }

  if (isClient) {
    loadVarsFromWebpack();
    loadVarsFromWindow();
  }

  lastRefreshed = new Date().getTime();
  envLoading = false;
};

const addVarsToEnvObject = (vars: Record<string, string> = {}) => {
  Object.keys(vars).forEach(k => {
    envObject[k] = vars[k];
  });
};

const generateClientEnvString = () => {
  const clientVars = {} as Record<string, string>;
  Object.keys(envObject).forEach(k => {
    if (!k.startsWith(clientVarPrefix)) {
      return;
    }
    clientVars[k] = envObject[k];
  });
  // Set the `PUBLIC_VERSION` var from server environment
  clientVars.PUBLIC_VERSION = env("PUBLIC_VERSION") ?? "UNKNOWN";
  // Set the `PUBLIC_SHA` var from server environment
  clientVars.PUBLIC_SHA = env("PUBLIC_SHA") ?? "UNKNOWN";

  _clientEnvString = JSON.stringify(clientVars);
};

const loadVarsFromFileSystem = async () => {
  const Dotenv = await import(/* webpackIgnore: true */ "dotenv");
  const envFile = Dotenv.config().parsed;
  addVarsToEnvObject(envFile);
};

const loadVarsFromSecretManager = async () => {
  try {
    console.log("Loading env vars from GCP secret manager...");
    const SecretManager = await import(
      /* webpackIgnore: true */ "@google-cloud/secret-manager"
    );
    const client = new SecretManager.SecretManagerServiceClient({
      projectId: gcpProjectId,
    });

    const secretName = env("ENV_SECRET_NAME", "stark-dev-env");
    const [accessResponse] = await client.accessSecretVersion({
      name: `projects/${gcpProjectId}/secrets/${secretName}/versions/latest`,
    });

    const envData = accessResponse.payload?.data?.toString();
    const Dotenv = await import(/* webpackIgnore: true */ "dotenv");
    const envVars = Dotenv.parse(envData ?? "");
    addVarsToEnvObject(envVars);
    console.log("Secrets loaded!");
  } catch (e) {
    console.error("Could not load and parse ENV vars from secret manager", e);
  }
};

const loadVarsFromWebpack = () => {
  if (typeof WEBPACK_VARS === "undefined") {
    return;
  }
  addVarsToEnvObject(WEBPACK_VARS);
};

const loadVarsFromWindow = () => {
  try {
    if (!window.__ENV__) {
      return;
    }
    const envVars = window.__ENV__;
    delete window.__ENV__;
    addVarsToEnvObject(envVars);
  } catch (e) {
    console.error("Could not parse ENV vars from window", e);
  }
};
