import Flex from "app/components/Flex";
import styled from "styled-components";
import media from "styled-media-query";
import { lg, md } from "styles/variables";

export const Wrapper = styled(Flex)`
  height: 100%;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.monochrome[1]};
  margin: ${lg};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.lessThan("small")`
    margin: ${md};
  `}
`;

export const OptionList = styled.ul`
  margin: 0;

  ${media.lessThan("small")`
    padding-inline-start: 24px;
  `}
`;

export const Option = styled.li`
  list-style-type: disc;
`;
