import React, { useEffect } from "react";
import { Context as ResponsiveContext } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import isServer from "../../../../helpers/isServer";
import { clearMockDeviceDimensions } from "../../../../modules/server";

interface Props {
  children?: React.ReactNode;
}

export const DeviceSizeProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { width, height } = useSelector(({ server }) => server.mockDevice);

  useEffect(() => {
    if (!isServer && (width || height)) {
      // We're on the client now, we can stop returning mock dimensions
      // This ensures a smooth hydration of components
      dispatch(clearMockDeviceDimensions());
    }
  }, [width, height, dispatch]);

  if (!width && !height) {
    return children;
  }

  return (
    <ResponsiveContext.Provider
      value={{
        deviceWidth: width,
        deviceHeight: height,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};
