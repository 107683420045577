import usePlayerSettings from "hooks/usePlayerSettings";
import getStartLevel from "./getStartLevel";

/**
 * useHlsQualityTracker
 *
 * This hook allows React Player instances to opt into caching previous HLS
 * values via local browser storage. The idea is that we leverage hls.js's
 * LEVEL_LOADED event for for hints as to the ideal bandwidth for this user,
 * and store that value for future playback. Specifically, it does two things:
 *
 * (1) Updates the cached HLS quality every time hls.js loads a new level
 * (2) Initializes the player with the cached quaility if found, otherwise
 *     falling back to the default (first level in manifest)
 *
 * returns {Function} callback expecting a player instance
 */
const useHlsQualityTracker = () => {
  const { hlsQuality, setPlayerSetting } = usePlayerSettings();
  return player => {
    const hlsPlayer = player.getInternalPlayer("hls");
    if (!hlsPlayer) {
      return;
    }
    if (hlsQuality) {
      hlsPlayer.startLevel = getStartLevel(hlsQuality, hlsPlayer.levels);
    }
    hlsPlayer.startLoad();
    hlsPlayer.on(window.Hls.Events.LEVEL_LOADED, (event, data) => {
      const levelData = hlsPlayer.levels[data.level];
      if (levelData) {
        setPlayerSetting("hlsQuality", {
          height: levelData.height,
          bitrate: levelData.bitrate,
        });
      }
    });
  };
};

export default useHlsQualityTracker;
