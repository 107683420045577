import { USER_STOP_PREVIEWING_CONTENT } from "modules/content";
import { librarySelectElement } from "services/typewriter/segment";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";

export const INITIALIZE_REGISTER_STEPS = "classes/INITIALIZE_REGISTER_STEPS";
export const SET_NAVBAR_HEIGHT = "classes/SET_NAVBAR_HEIGHT";
export const SET_SIDENAVBAR_WIDTH = "classes/SET_SIDENAVBAR_WIDTH";
export const SET_REGISTER_STEP_INDEX = "classes/SET_REGISTER_STEP_INDEX";
export const TOGGLE_ADD_CLASS_MODAL = "classes/TOGGLE_ADD_CLASS_MODAL";
export const TOGGLE_SEARCH_BAR = "classes/TOGGLE_SEARCH_BAR";
export const TOGGLE_SETTINGS_VOLUME = "classes/TOGGLE_SETTINGS_VOLUME";
export const SET_WELCOME_GUIDE_VIEWED = "classes/SET_WELCOME_GUIDE_VIEWED";
export const SHRINK_OR_EXPAND_SIDE_NAVBAR =
  "classes/SHRINK_OR_EXPAND_SIDE_NAVBAR";

const initialState = {
  AddClassModal: {
    toggled: false,
    classData: null,
  },
  Settings: {
    volume: 0,
  },
  Navbar: {
    height: 60,
  },
  SideNavbar: {
    expanded: true,
    width: 300,
  },
  OnboardingCard: {
    showPreviewMode: false,
  },
  Register: {
    stepIndex: 0,
    steps: [],
    step: "level",
  },
  SearchBar: { toggled: false },
  WelcomeGuide: {
    viewed: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_REGISTER_STEPS:
      return {
        ...state,
        Register: {
          ...state.Register,
          steps: action.steps,
        },
      };

    case SET_NAVBAR_HEIGHT:
      return {
        ...state,
        Navbar: {
          ...state.Navbar,
          height: action.height,
        },
      };

    case SET_SIDENAVBAR_WIDTH:
      return {
        ...state,
        SideNavbar: {
          ...state.SideNavbar,
          width: action.width,
        },
      };

    case SET_REGISTER_STEP_INDEX:
      return {
        ...state,
        Register: {
          ...state.Register,
          stepIndex: action.stepIndex,
          step: state.Register.steps[action.stepIndex],
        },
      };

    case SET_WELCOME_GUIDE_VIEWED:
      return {
        ...state,
        WelcomeGuide: {
          ...state.WelcomeGuide,
          viewed: action.viewed,
        },
      };

    case SHRINK_OR_EXPAND_SIDE_NAVBAR:
      return {
        ...state,
        SideNavbar: {
          ...state.SideNavbar,
          expanded: action.expanded,
        },
      };

    case TOGGLE_ADD_CLASS_MODAL:
      return {
        ...state,
        AddClassModal: {
          ...state.AddClassModal,
          classData: {
            id: action.classId,
            refId: action.programClassRefId,
          },
        },
      };

    case TOGGLE_SEARCH_BAR:
      return {
        ...state,
        SearchBar: {
          ...state.SearchBar,
          toggled: action.toggle,
        },
      };

    case TOGGLE_SETTINGS_VOLUME:
      return {
        ...state,
        Settings: {
          ...state.Settings,
          volume: action.toggle,
        },
      };

    default:
      return state;
  }
};

export const initializeRegisterSteps = steps => dispatch => {
  dispatch({ type: INITIALIZE_REGISTER_STEPS, steps });
  // change back to 0
  dispatch({ type: SET_REGISTER_STEP_INDEX, stepIndex: 0 });
};

export const setNavbarHeight = height => dispatch => {
  dispatch({ type: SET_NAVBAR_HEIGHT, height });
};

export const setSideNavbarWidthAction = width => dispatch => {
  dispatch({ type: SET_SIDENAVBAR_WIDTH, width });
};

export const setRegisterStepIndex = index => dispatch => {
  dispatch({
    type: SET_REGISTER_STEP_INDEX,
    stepIndex: index,
  });
};

export const shrinkOrExpandSideNavbarAction = expanded => dispatch => {
  dispatch({
    type: SHRINK_OR_EXPAND_SIDE_NAVBAR,
    expanded,
  });
};

export const toggleSearchBarAction = ({ toggle, accountMode }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const {
    router: { location },
  } = state;

  if (toggle && location.pathname?.includes("/library")) {
    const underscoredPathname = location.pathname.slice(1).replace("/", "_"); // e.g. transforms /library/classes => library_classes
    librarySelectElement({
      element_name: "Library - Select Search",
      element_type: "icon",
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE[underscoredPathname],
      account_mode: accountMode,
    });
  }
  dispatch({ type: TOGGLE_SEARCH_BAR, toggle });
};

export const toggleSettingsVolumeAction = toggle => dispatch =>
  dispatch({ type: TOGGLE_SETTINGS_VOLUME, toggle });

export const toggleAddClassModalAction = (
  classId,
  programClassRefId
) => dispatch => {
  // Turn off previewing for video when modal shows
  dispatch({ type: USER_STOP_PREVIEWING_CONTENT });
  dispatch({ type: TOGGLE_ADD_CLASS_MODAL, classId, programClassRefId });
};
