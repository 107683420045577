import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Modal from "app/components/Modal";
import Flex from "app/components/Flex";
import { H3, P1 } from "app/components/Typography";
import Icon, { Close } from "app/components/Icon";
import { useReportActivity } from "hooks/Challenges";
import Button from "app/components/Button";
import { MEDIUM_BREAKPOINT_VAL } from "constants/index";
import ReportModalListItem from "./ReportModalListItem";
import { REPORT_REASON_OPTIONS } from "./constants";

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
`;

const ModalWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

const CloseButton = styled(Icon)`
  align-self: flex-end;
`;

const ModalList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 32px;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    flex-direction: column;
  }
`;

const HalfReasonList = styled(Flex)`
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    align-self: center;
  }
`;

const ReportModal = ({
  activityId,
  isOpen,
  setIsOpen,
  activityType,
  onClickReport,
}) => {
  const [selectedReportReason, setSelectedReportReason] = useState(null);
  const [isViewLtMediumSize, toggleIsViewLtMediumSize] = useState(false);

  const [reportActivity] = useReportActivity({
    onCompleted: () => {
      // TODO: Fire analytics event?
      setIsOpen(false);
      toast.success("Your report request has been submitted.");
    },
    onError: () => {
      toast.error("There was an error. Refresh and try again.");
    },
  });

  const handleReportActivity = async () => {
    await reportActivity({
      variables: {
        activityId,
        activityType,
        reportReason: selectedReportReason,
      },
    });
    onClickReport(selectedReportReason);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MEDIUM_BREAKPOINT_VAL) {
        toggleIsViewLtMediumSize(true);
      } else {
        toggleIsViewLtMediumSize(false);
      }
    };

    handleResize();

    window.addEventListener("resize", _.debounce(handleResize, 300));
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 300));
    };
  });

  return (
    <StyledModal isOpen={isOpen} onBackgroundClick={() => setIsOpen(false)}>
      <ModalWrapper>
        <Flex flexDirection="column" p={{ _: 3, md: 4 }}>
          <CloseButton
            as={Close}
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          />
          <H3 mb={3}>Why are you reporting this post?</H3>
          <P1>Your report request is anonymous.</P1>
        </Flex>
        <ModalList>
          <HalfReasonList mr={{ _: 0, md: 4 }}>
            {REPORT_REASON_OPTIONS.slice(0, 3).map((reason, i) => (
              <ReportModalListItem
                key={reason.value}
                selected={reason.value === selectedReportReason}
                reason={reason}
                setSelectedReportReason={setSelectedReportReason}
                isLastInRow={!isViewLtMediumSize && i === 2}
              />
            ))}
          </HalfReasonList>
          <HalfReasonList>
            {REPORT_REASON_OPTIONS.slice(3, 6).map((reason, i) => (
              <ReportModalListItem
                key={reason.value}
                selected={reason.value === selectedReportReason}
                reason={reason}
                setSelectedReportReason={setSelectedReportReason}
                isLastInRow={i === 2}
              />
            ))}
          </HalfReasonList>
        </ModalList>
      </ModalWrapper>
      <SubmitButton
        disabled={!selectedReportReason}
        width="217px"
        my={{ _: 3, md: 4 }}
        mr={{ _: 0, md: 4 }}
        onClick={handleReportActivity}
      >
        Submit
      </SubmitButton>
    </StyledModal>
  );
};

ReportModal.propTypes = {
  activityType: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClickReport: PropTypes.func.isRequired,
};

export default ReportModal;
