import React from "react";
import { Notification } from "services/graphql";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Avatar from "app/components/Avatar";
import { NotificationComment, NotificationLike } from "app/components/Icon";
import Img from "app/components/Img";
import { deconstructSourceParamsCsv } from "./helpers";
import { useRedirectToNotficationSource } from "./hooks";
import { Wrapper, Span, Header, Body, Icon } from "./styles";

const NOTIFICATION_ICON_MAP = {
  UGCComment: NotificationComment,
  UGCReactionLike: NotificationLike,
};

interface Props {
  notification: Partial<Notification>;
}

export function NotificationItem({ notification }: Props) {
  const { redirectToNotificationSource } = useRedirectToNotficationSource(
    notification
  );
  const sourceParamsObject = deconstructSourceParamsCsv(
    notification.sourceParamsCSV
  );
  const thumbnailUrl = sourceParamsObject?.thumbnailUrl;
  const NotificationIcon =
    NOTIFICATION_ICON_MAP[
      notification.sourceType as keyof typeof NOTIFICATION_ICON_MAP
    ];

  return (
    <Wrapper
      isSeen={notification.seen}
      onClick={() => redirectToNotificationSource()}
    >
      <Div position="relative">
        <Avatar
          src={notification.senderProfile.photoURL}
          alt="Profile Avatar"
          height="50px"
          width="50px"
        />
        {NotificationIcon && (
          <Icon as={NotificationIcon} isSeen={notification.seen} />
        )}
      </Div>
      <Flex flexDirection="column">
        <Header>
          {notification.header} <Span>{notification.timeSince}</Span>
        </Header>
        <Body>{notification.message}</Body>
      </Flex>
      {thumbnailUrl && (
        <Flex
          height="50px"
          width="26px"
          overflow="hidden"
          position="relative"
          borderRadius="2px"
        >
          <Img
            src={thumbnailUrl}
            alt="Post Thumbnail"
            position="absolute"
            top="0"
          />
        </Flex>
      )}
    </Wrapper>
  );
}
