import React, { useState } from "react";
import { STEEZYFamilyVertical } from "app/components/Icon";
import { ValidatePinModal } from "app/components/ValidatePinModal";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import {
  familyEnterFamilyMode,
  familyEnterStudioMode,
} from "services/typewriter/segment";
import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/index";
import { DropdownOption } from "app/components/Dropdown";
import { LineSeparator } from "./LineSeparator";

export const FamilyToggle = () => {
  const { isFamilyModeOn, toggleFamilyMode } = useFamilyModeSetting();
  const [isValidating, setIsValidating] = useState(false);

  if (isValidating) {
    return (
      <ValidatePinModal
        isOpen
        onValidated={() => {
          toggleFamilyMode(false);
          setIsValidating(false);
          familyEnterStudioMode({
            module: ANALYTICS_MODULE.mode_selection,
            location: ANALYTICS_LOCATION.avatar_dropdown,
          });
        }}
        onCancel={() => setIsValidating(false)}
      />
    );
  }

  const toggleFamily = () => {
    if (isFamilyModeOn) {
      setIsValidating(true);
    } else {
      toggleFamilyMode(true);
      familyEnterFamilyMode({
        module: ANALYTICS_MODULE.mode_selection,
        location: ANALYTICS_LOCATION.avatar_dropdown,
      });
    }
  };

  return (
    <>
      {isFamilyModeOn ? (
        <DropdownOption
          text="Exit STEEZY Family"
          onClick={() => {
            toggleFamily();
          }}
        />
      ) : (
        <DropdownOption
          Svg={STEEZYFamilyVertical}
          text="Switch to SF"
          onClick={() => {
            toggleFamily();
          }}
        />
      )}
      {!isFamilyModeOn && <LineSeparator />}
    </>
  );
};
