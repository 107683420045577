import React from "react";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import InputAddon from "./InputAddon";

const defaultProps = {
  errors: {},
  handleChange: null,
  required: false,
  touched: {},
  isSignUp: false,
};

const propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.shape({}),
  required: PropTypes.bool,
  touched: PropTypes.shape({}),
  isSignUp: PropTypes.bool,
};

const InputEmail = ({ isSignUp = false, ...props }) => (
  <InputAddon
    {...props}
    isSignUp={isSignUp}
    type="email"
    name="email"
    id="email"
    placeholder={isSignUp ? "Email address" : "Enter your email"}
    label={isSignUp || "Email Address"}
    validate={values => {
      const { required } = props;
      if (required && (!values || values === "")) {
        return "Email is required";
      }
      if (!isEmail(values)) {
        return "Invalid email address";
      }
      return null;
    }}
  />
);

InputEmail.defaultProps = defaultProps;
InputEmail.propTypes = propTypes;

export default InputEmail;
