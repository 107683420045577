import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { border, layout, space, system } from "styled-system";

const ImgWrapper = styled.img`
  ${layout}
  ${border}
  ${space}
  ${system({
    objectFit: true,
  })}
`;

type Props = {
  src: string;
  placeholderSrc?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  [x: string]: any;
};

const Img = ({ placeholderSrc, src, ...rest }: Props) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      {loading && <ImgWrapper src={placeholderSrc} {...rest} />}
      <ImgWrapper src={src} onLoad={() => setLoading(false)} {...rest} />
    </>
  );
};

Img.defaultProps = {
  placeholderSrc: null,
};

Img.propTypes = {
  placeholderSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default Img;
