import React from "react";
import styled from "styled-components";
import { matchPath, withRouter } from "react-router";
import ErrorReporter from "services/error-reporter";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { Navbar } from "../Navbar";
import isClient from "../../../helpers/isClient";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const H1 = styled.h1`
  font-weight: 600;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    props.history.listen(() => {
      const { hasError } = this.state;

      if (hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  componentDidCatch(error, info) {
    if (error.name === "ChunkLoadError") {
      this.handleChunkLoadError();
    }
    this.setState({ hasError: true });
    ErrorReporter.report(error, info);
  }

  handleChunkLoadError() {
    const { entrypoint, history } = this.props;
    const path = entrypoint.split("?")[0];
    const locationIsEntrypoint = matchPath(history.location.pathname, { path });
    if (isClient && !locationIsEntrypoint) {
      window.location.reload();
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <Navbar forceFocus />
          <Container>
            <H1>
              Something went wrong <span>:(</span>
            </H1>
            <p>We&apos;re working to fix this.</p>
            <p>Please refresh the page.</p>
          </Container>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  entrypoint: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
};

ErrorBoundary.defaultProps = {
  entrypoint: null,
};

const mapStateToProps = ({ server }) => ({
  entrypoint: server.entrypoint,
});

export default connect(mapStateToProps)(withRouter(ErrorBoundary));
