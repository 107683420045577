import styled from "styled-components";
import { darken } from "polished";
import { color, space, typography, TypographyProps } from "styled-system";

interface Props extends TypographyProps {
  underline?: boolean;
  right?: boolean;
  grey?: boolean;
}

export default styled.span<Props>`
  color: ${props => props.theme.colors.blue};
  cursor: pointer;

  ${({ grey, theme }) =>
    grey &&
    `
    color: ${theme.colors.grey};
    :hover {
      color: ${darken(0.1, theme.colors.grey)};
    }
  `}
  ${props => props.underline && "text-decoration: underline;"}
  ${props => props.right && "text-align: right;"}

  ${color}
  ${space}
  ${typography}
`;
