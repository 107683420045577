import styled from "styled-components";
import { Menu } from "@szhsin/react-menu";
import { menuSelector, menuItemSelector } from "@szhsin/react-menu/style-utils";

export const DropdownMenu = styled(Menu)`
  ${menuSelector.name} {
    justify-content: center;
    flex-direction: column;
    padding: 0;
    background: ${({ theme }) => theme.colors.white};
    max-width: 265px;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.colors.monochrome[1]};
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
    z-index: 999999; // Needs to be bigger than Modal
  }

  ${menuItemSelector.name} {
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 0;
    white-space: nowrap;
    @media (hover: hover) {
      :hover {
        background: ${({ theme }) => theme.colors.monochrome[1]};
      }
    }
  }
`;
