import { FAMILY_MODE_STORAGE_KEY } from "constants/index";

export function getCachedFamilyModeSetting(): boolean | null {
  const localStorageItem = localStorage.getItem(FAMILY_MODE_STORAGE_KEY);

  if (!localStorageItem) {
    return null;
  }

  const localStorageJSON = JSON.parse(localStorageItem);
  if (localStorageJSON.expiresAt < new Date()) {
    return null;
  }

  return localStorageJSON.isToggled || false;
}

