import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import PropTypes from "prop-types";

const PersistWrapper = ({ children, persistor }) => {
  // Temporarily disable feature until SSR is more stable
  return children;

  if (typeof window === "undefined") {
    return children;
  }
  return (
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  );
};

PersistWrapper.propTypes = {
  children: PropTypes.element,
  persistor: PropTypes.object,
};

export default PersistWrapper;
