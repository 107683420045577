import { useSelector } from "react-redux";
import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";

export const useGetUserProfilePhotoUrl = () =>
  useSelector(
    ({ user, auth }) =>
      user.public.profile_pic || auth.providerData?.[0]?.photoURL
  );

export const useGetUserCredentials = () =>
  useSelector(({ auth: { email, uid } } = {}) => ({
    email,
    uid,
  }));

export const useGetUserIsAnonymous = (mustBeLoaded = false) =>
  useSelector(({ auth: { isAnonymous, loaded } } = {}) =>
    mustBeLoaded ? isAnonymous && loaded : isAnonymous
  );

export const useIsUserLoaded = () =>
  useSelector(
    ({ user }) =>
      user.subscription.loaded &&
      user.public.loaded &&
      user.private.loaded &&
      user.progress.loaded
  );

export const useUserSubscription = () =>
  useSelector(({ user }) => ({
    isSubscriptionActive: user.subscription.activeSubscription,
    isSubscriptionPaused: user.subscription.isPaused,
    hasSubscription: user.subscription.hasSubscription,
  }));

export const useShouldShowExplicitMusic = () =>
  useSelector(({ user }) => user.private.show_explicit);

export const useShowOnlyLicensedClasses = () =>
  useSelector(({ user }) => user.private.show_only_licensed_classes ?? true);

export const useShowOnlyAvailableToMeOnlyClasses = () =>
  useSelector(({ user }) => user.private.show_available_to_me_only ?? true);

export const useGetClassRating = classId =>
  useSelector(({ user }) => user?.classRatings?.[classId]?.rating);

export const useGetLastSeenWeeklyStreak = () =>
  useSelector(
    ({ user }) =>
      user.progress?.content_progress?.for_you?.last_seen_weekly_streak
  );

export const useGetUserClassProgressPercent = ({
  classId,
  classDurationInSeconds,
}) => {
  let progressTimeInSeconds = 0;
  const userClassProgress = useSelector(
    ({ user }) => user.progress?.["class-progress"]?.[classId] || {}
  );

  if (!userClassProgress) {
    return {
      classProgressPercent: 0,
      isClassCompleted: false,
    };
  }

  if (userClassProgress.time) {
    progressTimeInSeconds = convertTimeObjectToSeconds(userClassProgress.time);
  }

  return {
    classProgressPercent:
      (progressTimeInSeconds / classDurationInSeconds) * 100,
    isClassCompleted: Boolean(userClassProgress.completed),
  };
};

export const useGetPartyData = () =>
  useSelector(({ user }) => user.private?.party || {});
