export default {
  navbar: {
    height: 60,
    borderBottom: 3,
  },
  card: {
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: "lightGrey",
    display: "inline-block",
    marginBottom: "20px",
    padding: "30px",
    background: "white",
    borderRadius: "5px",
  },
  programClassCard: {
    videoWidth: { lg: 360, xl: 360 },
  },
};
