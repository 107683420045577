import { isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "graphql/queries";

export default ({ options = {} } = {}) => {
  const { data = { me: {} }, loading, error, fetchMore } = useQuery(
    GET_USER_PROFILE,
    options
  );

  const userData = data?.me;

  if (isEmpty(userData)) {
    return [{}, loading, error];
  }

  const { uploadedVideos: { edges = [], totalCount } = {} } = userData;

  const hasMore = edges.length < totalCount;
  const fetchMoreData = () =>
    hasMore &&
    fetchMore({
      variables: {
        first: edges.length + 10,
      },
      updateQuery: (pv, { fetchMoreResult }) => fetchMoreResult,
    });

  return [userData, loading, error, fetchMoreData];
};
