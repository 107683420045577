/* Component Sizes */
export const navbarHeight = 60;
export const leftNavWidth = 250;
export const classCardWidth = 300;
export const classCardHeight = 289;
export const programCardWidth = 250;
export const programCardHeight = 450;
export const routePadding = 15;

/* Spacing Variables */
export const xs = "4px";
export const sm = "8px";
export const md = "16px";
export const lg = "32px";
export const xl = "64px";

export const xlarge = 1440;
export const large = 1200;
export const medium = 768;
export const small = 480;
export const xsmall = 374;
