import styled from "styled-components";
import { typography, system } from "styled-system";
import Div from "../Div";

export default styled(Div)`
  ${typography}
  ${system({
    textTransform: true,
    textDecoration: true,
    overflowWrap: true,
  })}
  scroll-margin: ${({ scrollMargin }) => scrollMargin ?? null};

  ${({ limitLines }) =>
    limitLines &&
    `
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${limitLines};
    -webkit-box-orient: vertical;
  `}
`;
