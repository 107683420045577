// These are the default options for user dance preferences
// Currently this is the only place storing the copies

const initialState = {
  levels: {
    "brand-new": {
      slug: "brand-new",
      name: "Brand New",
      order: 0,
    },
    beginner: {
      slug: "beginner",
      name: "Beginner",
      order: 1,
    },
    intermediate: {
      slug: "intermediate",
      name: "Intermediate",
      order: 2,
    },
    advanced: {
      slug: "advanced",
      name: "Advanced",
      order: 3,
    },
  },
  reasons: {
    foundations: {
      slug: "foundations",
      name: "Foundations",
      description: "I want to learn basic dance foundations.",
      message:
        "STEEZY has 40+ step-by-step classes to help you learn the basics of dance.",
      goal: "Learn basic dance foundations",
      help: "Help with your basics",
    },
    freestyle: {
      slug: "freestyle",
      name: "Freestyle",
      description: "I want to learn how to freestyle.",
      message:
        "STEEZY has 50+ unique classes that can help train your personal style.",
      goal: "Learn how to freestyle",
      help: "Help with your freestyling",
    },
    choreography: {
      slug: "choreography",
      name: "Choreography",
      description: "I want to learn how to choreograph.",
      message: "Learn how to break down choreography from dance legends.",
      goal: "Learn how to choreograph",
      help: "Help with your choreography",
    },
    confidence: {
      slug: "confidence",
      name: "Confidence",
      description: "I want to dance in public confidently.",
      message:
        "Look great at the club or wedding with our 50+ beginner classes.",
      goal: "Dance in public confidently",
      help: "Help you dance with confidence",
    },
    health: {
      slug: "health",
      name: "Health",
      description: "I want to stay healthy with fun dance classes.",
      message:
        "STEEZY has 50+ unique classes that can help train your personal style.",
      goal: "Stay healthy with fun dance classes",
      help: "Help you stay healthy and fit",
    },
  },
  durations: [
    {
      name: "15 minutes or less",
      description: "Short drills to learn or practice techniques.",
      slug: "short",
      start: 0,
      end: 900,
    },
    {
      name: "15-45 minutes",
      description: "Quick dance practices that let you get a sweat in.",
      slug: "medium",
      start: 901,
      end: 2700,
    },
    {
      name: "+45 minutes",
      description: "Studio class length. Perfect for learning full routines.",
      slug: "long",
      start: 2701,
      end: 99999999,
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
