import { useMutation } from "@apollo/client";
import { GET_USER_PROFILE } from "graphql/queries";
import {
  VIDEO_UPLOAD_CREATE,
  VIDEO_UPLOAD_COMPLETE,
  VIDEO_UPLOAD_SUBMIT,
} from "graphql/mutations";
import { Platform } from "services/graphql";

export default () => {
  const [videoUploadCreateMutation] = useMutation(VIDEO_UPLOAD_CREATE);
  const [videoUploadCompleteMutation] = useMutation(VIDEO_UPLOAD_COMPLETE);
  const [videoUploadSubmitMutation] = useMutation(VIDEO_UPLOAD_SUBMIT, {
    refetchQueries: [{ query: GET_USER_PROFILE }],
  });

  const videoUploadCreate = async (classNumber, numParts) => {
    const resp = await videoUploadCreateMutation({
      variables: {
        input: {
          classNumber,
          parts: numParts,
        },
      },
    });

    const videoUploadCreateResp = resp?.data?.videoUploadCreate;
    if (!videoUploadCreateResp) {
      return null;
    }

    return {
      s3PartsUrls: videoUploadCreateResp.s3PartsUrls,
      videoId: videoUploadCreateResp.id,
      uploadId: videoUploadCreateResp.uploadId,
    };
  };

  const videoUploadComplete = async (videoId, parts, uploadId) => {
    const resp = await videoUploadCompleteMutation({
      variables: {
        input: {
          videoId,
          parts,
          uploadId,
        },
      },
    });
    const success = !!resp?.data?.videoUploadComplete?.id;
    return success;
  };

  const videoUploadSubmit = async (videoId, description, classNumber) => {
    const resp = await videoUploadSubmitMutation({
      variables: {
        input: {
          videoId,
          description,
          classNumber,
          platform: Platform.Web,
        },
      },
    });
    return resp?.data?.videoUploadSubmit;
  };

  return {
    videoUploadCreate,
    videoUploadComplete,
    videoUploadSubmit,
  };
};
