import { css } from "styled-components";

// This file is meant to override package styles that require css instead of styled-components

export default css`
  .carousel {
    position: relative;
  }

  .carousel__inner-slide {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }

  .carousel__slider {
    outline: none;
  }

  .carousel {
    &:focus {
      outline: none;
    }
  }

  .player-controls .rc-tooltip-inner {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .rc-tooltip.player-controls .rc-tooltip-arrow {
    border-top-color: rgba(0, 0, 0, 0.75);
  }

  .player-controls.no-padding .rc-tooltip-inner {
    padding: 0;
  }

  .rc-tooltip.player-controls.tooltip-menu {
    z-index: 99 !important;
  }

  .tooltip-menu.rc-slider-tooltip {
    z-index: 100;
  }

  .rc-slider-tooltip {
    z-index: 1500;
  }
`;
