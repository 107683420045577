import { useContext } from "react";
import { FamilyContext } from "app/components/AppProviders/FamilyContextProvider";

export function useFamilyModeSetting() {
  const {
    isFamilyModeOn,
    loadingIsFamilyModeOn,
    refetchParentalControls,
    setAnalyticsContext,
    toggleFamilyMode,
  } = useContext(FamilyContext);

  return {
    isFamilyModeOn,
    loadingIsFamilyModeOn,
    refetchParentalControls,
    setAnalyticsContext,
    toggleFamilyMode,
  };
}
