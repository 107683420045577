import momentTz from "moment-timezone";
import axios from "services/api";
import { getLocation } from "modules/iplocation";
import { ME_QUERY } from "graphql/queries";
import {
  RATE_CLASS_MUTATION,
  SET_USERS_TIMEZONE_MUTATION,
} from "graphql/mutations";
import { PARTY_STATUSES } from "../../constants";
import { resetPartyAction } from "../party";
import isServer from "../../helpers/isServer";

import {
  GET_PRIVATE_DATA,
  GET_PROGRESS_DATA,
  GET_PUBLIC_DATA,
  GET_SUBSCRIPTION_DATA,
  GET_CLASS_RATING_DATA,
  SAVE_PRIVATE_DATA,
  SAVE_PUBLIC_DATA,
} from "./actions-types";

export const setShowExplicitAction = toggle => (
  dispatch,
  getState,
  { getFirebase }
) => {
  if (isServer) {
    return undefined;
  } // Prevent data mutation on the server

  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;
  const updates = {};

  updates[`users_private/${uid}/show_explicit`] = toggle;

  return firebase
    .database()
    .ref()
    .update(updates);
};

export const setShowOnlyLicensedClassesAction = toggle => (
  dispatch,
  getState,
  { getFirebase }
) => {
  if (isServer) {
    return undefined;
  } // Prevent data mutation on the server

  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;
  const updates = {};

  updates[`users_private/${uid}/show_only_licensed_classes`] = toggle;

  return firebase
    .database()
    .ref()
    .update(updates);
};

export const setShowAvailablToMeOnlyClassesAction = toggle => (
  dispatch,
  getState,
  { getFirebase }
) => {
  if (isServer) {
    return undefined;
  } // Prevent data mutation on the server

  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;
  const updates = {};

  updates[`users_private/${uid}/show_available_to_me_only`] = toggle;

  return firebase
    .database()
    .ref()
    .update(updates);
};

export const getUserPublic = user => (dispatch, getState, { getFirebase }) =>
  new Promise(resolve => {
    const firebase = getFirebase();
    const { uid } = user;

    return firebase
      .database()
      .ref(`users_public/${uid}`)
      .on("value", snapshot => {
        const userPublic = snapshot.val();
        dispatch({ type: GET_PUBLIC_DATA, results: userPublic });
        resolve(snapshot.val());
      });
  });

export const getUserPrivate = user => (dispatch, getState, { getFirebase }) =>
  new Promise(resolve => {
    const firebase = getFirebase();
    const { uid } = user;
    return firebase
      .database()
      .ref(`users_private/${uid}`)
      .on("value", snapshot => {
        const userPrivate = snapshot.val() || {};

        if (!userPrivate || userPrivate.show_explicit === undefined) {
          dispatch(setShowExplicitAction(true));
        }

        dispatch({ type: GET_PRIVATE_DATA, results: userPrivate });

        const { party = {} } = userPrivate;
        if (
          party.status === PARTY_STATUSES.LEFT ||
          party.status === PARTY_STATUSES.ENDED
        ) {
          dispatch(resetPartyAction({ pid: party.id }));
        }

        resolve(snapshot.val());
      });
  });

export const getUserProgress = user => (dispatch, getState, { getFirebase }) =>
  new Promise(resolve => {
    const firebase = getFirebase();
    const { uid } = user;

    return firebase
      .database()
      .ref(`users_progress/${uid}`)
      .on("value", snapshot => {
        dispatch({
          type: GET_PROGRESS_DATA,
          results: snapshot.val(),
        });
        resolve(snapshot.val());
      });
  });

export const listenToFirebaseSubscription = user => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  if (isServer) {
    return;
  }
  const firebase = getFirebase();
  const { uid } = user;
  firebase
    .database()
    .ref(`users_subscriptions/${uid}`)
    .on("value", getUserSubscription);
};

export const getUserSubscription = () => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  const { data } = await apolloClient.query({
    query: ME_QUERY,
    fetchPolicy: "network-only",
  });
  dispatch({
    type: GET_SUBSCRIPTION_DATA,
    results: data?.me || {},
  });
};

export const saveUserPublic = updates => (
  dispatch,
  getState,
  { getFirebase }
) =>
  new Promise(resolve => {
    const firebase = getFirebase();
    const { uid } = getState().auth;

    return firebase
      .database()
      .ref(`users_public/${uid}`)
      .update(updates)
      .then(() => {
        dispatch({ type: SAVE_PUBLIC_DATA });
        resolve(updates);
      });
  });

export const saveUserPrivateAction = updates => (
  dispatch,
  getState,
  { getFirebase }
) => {
  if (isServer) {
    return;
  } // Prevent data mutation on the server

  // eslint-disable-next-line consistent-return
  return new Promise(resolve => {
    const firebase = getFirebase();
    const { uid } = firebase.auth().currentUser;

    return firebase
      .database()
      .ref(`users_private/${uid}`)
      .update(updates)
      .then(() => {
        dispatch({ type: SAVE_PRIVATE_DATA });
        resolve(updates);
      });
  });
};

export const uploadProfilePhoto = file => (
  dispatch,
  getState,
  { getFirebase }
) => {
  let photoUrl;
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;
  const metadata = {
    contentType: file.type,
  };

  return firebase
    .storage()
    .ref()
    .child(`profile_pics/${uid}`)
    .put(file, metadata)
    .then(snapshot => snapshot.ref.getDownloadURL())
    .then(url => {
      photoUrl = url;
      return firebase.auth().currentUser.updateProfile({
        photoUrl,
      });
    })
    .then(() => {
      firebase
        .database()
        .ref(`users_public/${uid}/profile_pic`)
        .set(photoUrl);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error("Upload failed:", error);
    });
};

export const saveUserEmail = email => (dispatch, getState, { getFirebase }) =>
  new Promise((resolve, reject) => {
    const firebase = getFirebase();
    const state = getState();
    const { user } = state;
    const { subscription } = user;
    const { uid } = firebase.auth().currentUser;

    return firebase
      .updateEmail(email)
      .then(() =>
        firebase
          .database()
          .ref(`users_private/${uid}/email`)
          .set(email)
          .then(() => {
            dispatch({ type: SAVE_PRIVATE_DATA });
            window.analytics.track("Account - Link Email");
            window.analytics.identify(uid, { email });

            if (subscription.braintree_id) {
              return axios.put("/api/payments/braintree/customer", {
                braintreeId: subscription.braintree_id,
                email,
                uid,
              });
            }

            return null;
          })
          .then(() => {
            if (subscription.stripe_id) {
              return axios.put("/api/payments/stripe/customer", {
                stripeId: subscription.stripe_id,
                email,
                uid,
              });
            }

            return null;
          })
          .then(() => {
            resolve(email);
          })
      )
      .catch(err => reject(err));
  });

export const saveUserPassword = ({ currentPassword, newPassword }) => (
  dispatch,
  getState,
  { getFirebase }
) =>
  new Promise((resolve, reject) => {
    const firebase = getFirebase();
    const state = getState();
    const { email } = state.auth;

    return firebase
      .login({ email, password: currentPassword })
      .then(() => {
        if (newPassword === currentPassword) {
          throw Error("New password cannot be the same as current password.");
        }
        return firebase.auth().currentUser.updatePassword(newPassword);
      })
      .then(() => {
        resolve();
      })
      .catch(err => reject(err));
  });

export const saveUsername = (oldUsername, newUsername) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { currentUser } = firebase.auth();
  const updates = {};

  if (!newUsername) {
    throw new Error("No username provided.");
  }

  try {
    const usernameSnapshot = await firebase
      .database()
      .ref(`usernames/${newUsername}`)
      .once("value");
    const usernameUID = usernameSnapshot.val();
    if (usernameUID && usernameUID !== currentUser.uid) {
      throw new Error("This username is taken.");
    }

    updates[`usernames/${oldUsername}`] = null;
    updates[
      `users_public/${currentUser.uid}/username`
    ] = newUsername.toLowerCase();
    updates[`usernames/${newUsername.toLowerCase()}`] = currentUser.uid;

    await firebase
      .database()
      .ref()
      .update(updates);
    await currentUser.updateProfile({ displayName: newUsername });
    return "success";
  } catch (error) {
    throw error;
  }
};

export const saveClassRatingAction = ({
  rating = null,
  comment = null,
  classId,
}) => async (dispatch, getState, { apolloClient }) => {
  try {
    await apolloClient.mutate({
      variables: {
        classId,
        rating,
        comment,
      },
      mutation: RATE_CLASS_MUTATION,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log({ err });
  }
};

export const getUserClassRatings = user => (
  dispatch,
  getState,
  { getFirebase }
) =>
  new Promise(resolve => {
    const firebase = getFirebase();
    const { uid } = user;
    return firebase
      .database()
      .ref(`users_class_ratings/${uid}`)
      .on("value", snapshot => {
        dispatch({ type: GET_CLASS_RATING_DATA, results: snapshot.val() });
        resolve(snapshot.val());
      });
  });

export const setUsersTimezoneAction = () => async (
  dispatch,
  getState,
  { apolloClient }
) => {
  if (isServer) {
    return;
  }
  try {
    await apolloClient.mutate({
      variables: {
        zoneName: momentTz.tz.guess(),
      },
      mutation: SET_USERS_TIMEZONE_MUTATION,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log({ err });
  }
};

export const loadAllUserData = user => async dispatch => {
  const actions = [
    dispatch(setUsersTimezoneAction()),
    dispatch(getUserPublic(user)),
    dispatch(getLocation()),
    dispatch(getUserSubscription(user)),
    dispatch(getUserPrivate(user)),
    dispatch(getUserProgress(user)),
    dispatch(getUserClassRatings(user)),
    dispatch(listenToFirebaseSubscription(user)),
  ];
  // eslint-disable-next-line no-console
  const safeActions = actions.map(a => a.catch(console.error));
  return Promise.all(safeActions);
};
