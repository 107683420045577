import React from "react";
import colors from "theme/colors";
import styled from "styled-components";
import Div from "../Div";
import { H6 } from "../Typography";

const Container = styled(Div)`
  border-radius: 4px;
  background: ${({
    fromColor,
    toColor,
  }: {
    fromColor: string;
    toColor: string;
  }) => `linear-gradient(90deg, ${fromColor} 0%, ${toColor} 200%);`};
  padding: 2px 8px 2px 9px; // Correct for font tracking
`;
const Label = styled(H6)`
  color: white;
`;

export const GradientLabel = ({
  label,
  fromColor = "blue",
  toColor = "bulbaGreen",
}: {
  label: string;
  fromColor?: keyof typeof colors;
  toColor?: keyof typeof colors;
}) => (
  <Container fromColor={colors[fromColor]} toColor={colors[toColor]}>
    <Label>{label}</Label>
  </Container>
);
