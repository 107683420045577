import firebase from "config/firebase-config";

export const getAuthHeaders = async () => {
  const header = {};
  const user = firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken();
    header["x-auth-token"] = `Bearer ${token}`;
  }
  return header;
};
