import React from "react";
import PropTypes from "prop-types";
import { StyledModal, Wrapper, BackgroundClick } from "./styles";

export { default as CloseModalButton } from "./CloseModalButton";
export { ModalWithContext } from "./ModalWithContext";

class StyledTransitionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: 0,
    };

    this.afterOpen = this.afterOpen.bind(this);
    this.beforeClose = this.beforeClose.bind(this);
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
    document.body.className = "";
  }

  afterOpen = () => {
    const { afterOpen } = this.props;
    document.body.className = "modal-open";
    setTimeout(() => {
      this.setState({ opacity: 1 });
    });

    if (afterOpen) {
      afterOpen();
    }
  };

  beforeClose = () =>
    new Promise(resolve => {
      this.setState({ opacity: 0 });
      setTimeout(resolve, 200);
    });

  render() {
    const {
      children,
      isOpen,
      onBackgroundClick,
      backgroundProps,
      onEscapeKeydown,
      ...rest
    } = this.props;
    const { opacity } = this.state;

    const afterClose = () => {
      document.body.style.overflow = "";
      document.body.className = "";
    };

    return (
      <StyledModal
        isOpen={isOpen}
        afterOpen={this.afterOpen}
        beforeClose={this.beforeClose}
        afterClose={afterClose}
        opacity={opacity}
        backgroundProps={{ ...backgroundProps, opacity }}
        onEscapeKeydown={onEscapeKeydown}
        aria-modal="true"
        tabIndex="-1"
      >
        <BackgroundClick
          onClick={() => {
            if (onBackgroundClick) {
              onBackgroundClick();
            }
          }}
        />
        <Wrapper {...rest}>{children}</Wrapper>
      </StyledModal>
    );
  }
}

StyledTransitionModal.defaultProps = {
  afterOpen: null,
  backgroundProps: {},
  isOpen: false,
  onBackgroundClick: null,
  onEscapeKeydown: () => {},
  borderRadius: "5px",
  p: 3,
};

StyledTransitionModal.propTypes = {
  afterOpen: PropTypes.func,
  backgroundProps: PropTypes.shape({}),
  borderRadius: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onBackgroundClick: PropTypes.func,
  onEscapeKeydown: PropTypes.func,
  p: PropTypes.number,
};

export default StyledTransitionModal;
