import styled from "styled-components";
import Div from "app/components/Div";
import { flexbox, system } from "styled-system";

type Attributes = {
  display?: string;
};

type Props = {
  display?: string;
  scrollMargin?: string;
  [x: string]: any;
};

export default styled(Div).attrs(({ display }: Attributes) => ({
  display: display || "flex",
}))<Props>`
  scroll-margin: ${({ scrollMargin }) => scrollMargin ?? null};
  ${flexbox};
  ${system({
    gap: true,
  })};
`;
