import store from "./store";

import {
  SET_AUTH,
  RESET_AUTH,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  RESET_ERROR,
} from "./actions-types";

export default (state = store, action) => {
  switch (action.type) {
    case SET_AUTH:
      return Object.assign(action.authData, {
        loaded: true,
      });

    case RESET_AUTH:
      return store;

    case LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
