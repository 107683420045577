import React, { useEffect, useState } from "react";
import Text from "app/components/Text";
import Flex from "app/components/Flex";
import { H6 } from "app/components/Typography";
import SpanLink from "app/components/SpanLink";
import { useUserValidatePinLazyQuery } from "services/graphql";
import { FullscreenModal } from "app/components/FullscreenModal";
import IconButton from "app/components/Button/IconButton";
import { Close } from "app/components/Icon";
import { CreatePinModal } from "app/routes/Account/Family/CreatePinModal";
import PinInput from "../../routes/Account/Family/PinInput";
import {
  areInputValuesValid,
  isPartiallyValid,
} from "../../routes/Account/Family/helpers/areInputValuesValid";
import { ReauthenticateModal } from "./ReauthenticateModal";

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  onValidated(pin: string): void;
  onCancel(): void;
}

export const ValidatePinModal = ({
  isOpen,
  onValidated,
  onCancel,
  children,
}: Props) => {
  const [isCreatingPin, setIsCreatingPin] = useState(false);
  const [isReauthing, setIsReauthing] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [hasError, setHasError] = useState(false);
  const [
    validatePinMutation,
    { data: validatedPinData },
  ] = useUserValidatePinLazyQuery({
    fetchPolicy: "no-cache",
  });
  const pinValues = inputValues.join("");

  useEffect(() => {
    if (areInputValuesValid(inputValues)) {
      validatePinMutation({
        variables: {
          pin: pinValues,
        },
      });
    } else if (isPartiallyValid(inputValues)) {
      setHasError(false);
    }
  }, [inputValues]);

  useEffect(() => {
    if (validatedPinData && !validatedPinData.userValidatePin) {
      setInputValues(["", "", "", ""]);
      setHasError(true);
    } else if (validatedPinData?.userValidatePin) {
      onValidated(pinValues);
    }
  }, [validatedPinData?.userValidatePin]);

  return (
    <FullscreenModal isOpen={isOpen}>
      <Flex justifyContent="flex-end" width="100%">
        <IconButton
          Icon={Close}
          width="20px"
          height="auto"
          color="grey"
          onClick={onCancel}
        />
      </Flex>
      <Flex
        p={3}
        width="100%"
        height="100%"
        justifyContent={{ _: "start", md: "center" }}
        alignItems="center"
        flexDirection="column"
        bg="white"
      >
        {children ?? (
          <Text fontWeight="700" fontSize="63px" mb="64px">
            Enter your PIN
          </Text>
        )}
        <PinInput
          autoFocus
          values={inputValues}
          hasError={hasError}
          onChange={({ values }) => setInputValues(values)}
        />
        {hasError && (
          <H6 color="redMercedes.5" mt="16px">
            Looks like the pins didn&apos;t match. Try again.
          </H6>
        )}
        <SpanLink
          mt="48px"
          fontSize="14px"
          fontWeight="700"
          onClick={() => {
            setIsReauthing(true);
          }}
        >
          Forgot your PIN?
        </SpanLink>
      </Flex>
      <ReauthenticateModal
        isOpen={isReauthing}
        onCancel={() => setIsReauthing(false)}
        onSuccess={() => {
          setIsReauthing(false);
          setIsCreatingPin(true);
        }}
      />
      <CreatePinModal
        isOpen={isCreatingPin}
        onSuccess={() => {
          setIsCreatingPin(false);
          onCancel();
        }}
        onCancel={() => setIsCreatingPin(false)}
      />
    </FullscreenModal>
  );
};
