import React from "react";
import { menuSelector } from "@szhsin/react-menu/style-utils";
import { Menu } from "@szhsin/react-menu";
import styled from "styled-components";
import Icon, { Bell, BellWithBadge } from "app/components/Icon";
import { H3 } from "app/components/Typography";
import Flex from "app/components/Flex";
import { useGetNotifications } from "./hooks";
import { NotificationList } from "./NotificationList";

const StyledMenu = styled(Menu)`
  ${menuSelector.name} {
    width: 400px;
    height: 475px;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 0;

    @media (max-width: 400px) {
      max-width: 100vw;
    }
  }
`;

const StyledButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`;

export function Notifications() {
  const {
    hasNewNotifications,
    getNotifications,
    notifications,
    areNotificationsLoading,
    notificationsPageInfo,
    fetchMoreNotifications,
  } = useGetNotifications();

  return (
    <StyledMenu
      align="end"
      direction="bottom"
      onMenuChange={({ open }) => {
        if (open) {
          getNotifications();
        }
      }}
      menuButton={() => (
        <StyledButton type="button">
          <Icon
            as={hasNewNotifications ? BellWithBadge : Bell}
            width="25px"
            height="25px"
            color="monochrome.9"
          />
        </StyledButton>
      )}
    >
      <Flex height="100%" flexDirection="column">
        <H3 m="16px">Notifications</H3>
        <NotificationList
          notifications={notifications}
          isLoading={areNotificationsLoading}
          notificationsPageInfo={notificationsPageInfo}
          fetchMoreNotifications={fetchMoreNotifications}
        />
      </Flex>
    </StyledMenu>
  );
}
