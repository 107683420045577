import { useMutation } from "@apollo/client";
import { VIDEO_UPLOAD_DELETE } from "graphql/challenges";

export default options => {
  const [mutate, results] = useMutation(VIDEO_UPLOAD_DELETE, options);

  return [
    async ({ variables }) => {
      const { videoId } = variables;

      return mutate({
        variables: {
          videoId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: videoId,
            __typename: "VideoUpload",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    results,
  ];
};
