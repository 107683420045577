import moment from "moment";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const setSeenModalAction = ({ slug }) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  await firebase
    .database()
    .ref(`users_progress/${uid}/content_progress/modals/${slug}`)
    .set(moment().toISOString());
};

export const setLastSeenWeeklyStreakAction = weeklyStreak => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  await firebase
    .database()
    .ref(
      `users_progress/${uid}/content_progress/for_you/last_seen_weekly_streak`
    )
    .set(weeklyStreak);
};

export const setSeenPlaylistCompleteModalAction = ({ playlistId }) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  await firebase
    .database()
    .ref(
      `users_progress/${uid}/playlist_progress/${playlistId}/seen_completed_modal`
    )
    .set(moment().toISOString());
};
