import { useGetConfigQuery } from "services/graphql";

export const useFeatureFlags = () => {
  const { loading } = useGetConfigQuery();

  return {
    loadingFeatureFlags: loading,
  };
};

export function useAreNotificationsAvailable(): boolean {
  const isEmailNotificationFeatureEnabled = useEmailNotificationsFlag();
  const isPushNotificationFeatureEnabled = usePushNotificationsFlag();

  return isEmailNotificationFeatureEnabled || isPushNotificationFeatureEnabled;
}

export function useFamilyFriendlyFlag(): boolean {
  return useFeatureFlag("family_friendly");
}

export function useGooglePayFlag(): boolean {
  return useFeatureFlag("google_pay");
}

export function useApplePayFlag(): boolean {
  return useFeatureFlag("apple_pay");
}

export function usePaypalCheckoutFlag(): boolean {
  return useFeatureFlag("paypal_checkout");
}

export function useEmailNotificationsFlag(): boolean {
  return useFeatureFlag("email_notifications");
}

export function usePublicCommunityFlag(): boolean {
  return useFeatureFlag("public_community");
}

export function usePushNotificationsFlag(): boolean {
  return useFeatureFlag("push_notifications");
}

function useFeatureFlag(flagName: string): boolean {
  // This query is ran on app load. Can assume data will always be there.
  const { data } = useGetConfigQuery();

  const flag = data?.configs?.featureFlags?.find(val => val.name === flagName);

  return !!flag?.enabled;
}
