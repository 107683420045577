import { useUserSubscription } from "modules/selectors";
import { useEffect } from "react";
import { useGetCanUserTakeClassQuery } from "services/graphql";

export function useCanUserTakeClass({ classId }: { classId: number | string }) {
  const { isSubscriptionActive } = useUserSubscription();
  const { data, loading, refetch } = useGetCanUserTakeClassQuery({
    variables: { classId: String(classId) },
  });

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [isSubscriptionActive]);
  if (!data) {
    return { canUserTakeClass: false, loadingCanUserTakeClass: loading };
  }

  const { classData } = data;

  return {
    canUserTakeClass: classData.canUserTakeClass,
    loadingCanUserTakeClass: loading,
    refetchCanUserTakeClass: refetch,
  };
}
