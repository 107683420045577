import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { Highlight, connectAutoComplete } from "react-instantsearch-dom";
import Icon, { ChevronDown, Close } from "app/components/Icon";
import transition from "styled-transition-group";
import { toggleSearchBarAction } from "modules/components";
import {
  userSelectsClassAction,
  userSelectsProgramAction,
} from "modules/content";
import IconButton from "app/components/Button/IconButton";
import env from "helpers/env";
import { AccountMode } from "services/typewriter/segment";
import { FamilyContext } from "../AppProviders/FamilyContextProvider";

const AutoCompleteWrapper = transition.div`
  width: 300px;
  margin-right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 0 0 5px 5px;
  background: ${({ theme }) => theme.colors.lightGrey};

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__section-container {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  .react-autosuggest__section-title {
    text-transform: uppercase;
    font-weight: 600;
    padding: 15px 20px;
    font-size: 16px;
    letter-spacing: 3px;
  }

  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
  }

  .react-autosuggest__suggestion {
    padding: 15px 20px;
    cursor: pointer;

    @media (hover: hover) {
      :hover {
        background: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  mark,
  .mark {
    background: none;
    font-weight: bold;
    padding: 0;
  }

  &:enter {
    margin-right: -300px;
    opacity: 0;
  }

  &:enter-active {
    transition: margin-right 300ms ease, opacity 300ms ease;
    margin-right: 0;
    opacity: 1;
  }

  &:exit {
    margin-right: 0;
    opacity: 1;
  }

  &:exit-active {
    transition: margin-right 300ms ease, opacity 300ms ease;
    margin-right: -300px;
    opacity: 0;
  }
`;

const Title = styled.h1`
  font-size: 14px;
  line-height: 1.4em;
  margin: 0;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h2`
  font-size: 12px;
  margin-bottom: 0;
  text-transform: capitalize;
`;

const Avatar = styled.img`
  width: 20px;
  border-radius: 50%;
`;

const Instructor = styled.h3`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 0 10px;
`;

const InstructorSection = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 5px 0;
`;

const Input = styled.input`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  box-sizing: border-box;
  background: 0 0;
  padding: 0 20px;
  height: 40px;
  width: 100%;
  border: 0;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  input {
    letter-spacing: 0.5px;
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  width: 100%;
`;

const ResultsContainer = styled.div`
  overflow: scroll;
  max-height: 80vh;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

const ViewAllWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const renderSectionTitle = section => {
  let sectionTitle = section.index;
  if (section.index.includes("programs")) {
    sectionTitle = "programs";
  }
  return section.hits.length ? sectionTitle : null;
};

const getSectionSuggestions = section => section.hits;

const getSuggestionValue = hit => hit.title;

const renderSuggestion = hit => (
  <div>
    <Title>
      <Highlight attribute="title" hit={hit} tagName="mark" />
    </Title>
    <Subtitle>
      {hit.style}
      {hit.model_name === "class" && <span>&nbsp;&#124;&nbsp;</span>}
      {hit.model_name === "class" && (
        <Highlight attribute="type" hit={hit} tagName="mark" />
      )}
    </Subtitle>

    {!!hit.instructor_name && (
      <InstructorSection>
        <Avatar
          src={`${env("PUBLIC_ASSET_PATH")}/choreographers/${
            hit.instructor_slug
          }.png?w=120`}
        />
        <Instructor>
          <Highlight attribute="instructor_name" hit={hit} tagName="mark" />
        </Instructor>
      </InstructorSection>
    )}
  </div>
);

class AutoComplete extends React.Component {
  static contextType = FamilyContext;

  constructor(props) {
    super(props);

    this.state = {
      value: props.currentRefinement,
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.searchQuery = this.searchQuery.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { showSearchBar } = this.props;
    if (!showSearchBar && prevProps.showSearchBar) {
      this.setState({ value: "" });
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 13 && !this.suggestionSelected) {
      this.searchQuery();
      event.target.blur();
    }

    this.suggestionSelected = false;
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionSelected = (event, data) => {
    const { userSelectsClass, userSelectsProgram, location } = this.props;
    const { isFamilyModeOn } = this.context;
    const accountMode = isFamilyModeOn
      ? AccountMode.Family
      : AccountMode.Studio;

    this.suggestionSelected = true;
    if (data.suggestion.model_name === "class") {
      userSelectsClass({
        classData: data.suggestion,
        component: "SearchBar",
        element: "SearchBarSuggestion",
        entryRoute: location.pathname,
        accountMode,
      });
    } else {
      userSelectsProgram({
        programSlug: data.suggestion.slug,
        component: "SearchBar",
        element: "SearchBarSuggestion",
      });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { refine } = this.props;
    refine(value);
  };

  onSuggestionsClearRequested = () => {
    const { refine } = this.props;
    refine();
  };

  searchQuery() {
    const { value } = this.state;
    const { location, history, toggleSearchBar } = this.props;

    if (location.pathname?.includes("/instructors")) {
      history.push(`/results/instructors?query=${value}`);
    } else if (location.pathname?.includes("/programs")) {
      history.push(`/results/programs?query=${value}`);
    } else {
      history.push(`/results/classes?query=${value}`);
    }

    toggleSearchBar();
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { showSearchBar } = this.props;
    return (
      <SuggestionsContainer {...containerProps}>
        <ResultsContainer>
          {!!query && showSearchBar && children}
        </ResultsContainer>
        {!!query && showSearchBar && children && (
          <ViewAllWrapper onClick={this.searchQuery}>
            View All Results
            <Icon
              width="10px"
              color="white"
              transform="rotate(-90deg)"
              as={ChevronDown}
            />
          </ViewAllWrapper>
        )}
      </SuggestionsContainer>
    );
  };

  renderInputComponent(inputProps) {
    const { showSearchBar, toggleSearchBar } = this.props;
    const { isFamilyModeOn } = this.context;

    return (
      <InputWrapper>
        {showSearchBar && <Input name="search" type="text" {...inputProps} />}
        {showSearchBar && (
          <IconButton
            width="14px"
            Icon={Close}
            color="black"
            mr="5px"
            onClick={() =>
              toggleSearchBar({
                toggle: false,
                accountMode: isFamilyModeOn
                  ? AccountMode.Family
                  : AccountMode.Studio,
              })
            }
          />
        )}
      </InputWrapper>
    );
  }

  render() {
    const { hits, showSearchBar } = this.props;
    const { value } = this.state;

    const inputProps = {
      placeholder: "Search",
      onChange: this.onChange,
      value,
      onKeyDown: this.onKeyDown,
      autoFocus: true,
    };

    return (
      <AutoCompleteWrapper unmountOnExit in={showSearchBar} timeout={300}>
        <Autosuggest
          suggestions={hits}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          renderInputComponent={this.renderInputComponent}
          multiSection
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
        />
      </AutoCompleteWrapper>
    );
  }
}

const mapStateToProps = ({ components }) => ({
  showSearchBar: components.SearchBar.toggled,
});

const mapDispatchToProps = {
  toggleSearchBar: toggleSearchBarAction,
  userSelectsClass: userSelectsClassAction,
  userSelectsProgram: userSelectsProgramAction,
};

AutoComplete.defaultProps = {
  showSearchBar: false,
};

AutoComplete.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  showSearchBar: PropTypes.bool,
  toggleSearchBar: PropTypes.func.isRequired,
  userSelectsClass: PropTypes.func.isRequired,
  userSelectsProgram: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(connectAutoComplete(AutoComplete))
);
