import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "app/components/Button/IconButton";
import { H2 } from "app/components/Typography";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Dot from "app/components/Dot";
import { ChevronLeft, ChevronRight } from "app/components/Icon";
import styled from "styled-components";

const AMOUNT_OF_DAYS_IN_MONTH = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];
const AMOUNT_OF_DAYS_IN_MONTH_LEAP = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];
const DAYS_OF_THE_WEEK = ["S", "M", "T", "W", "T", "F", "S"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DayHighlighter = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
`;

const Day = styled(Flex).attrs({
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
})`
  :after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:hover {
    ${DayHighlighter} {
      background: ${({ theme }) => theme.colors.monochrome[1]};
    }
  }
`;

const DayHighlighterWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
`;

const getStartDayIndexOfMonth = date => {
  return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
};

const isLeapYear = year => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const Calendar = ({
  onSelectDate,
  currentDate,
  textColor,
  highlightedDays,
  indicatedDays,
}) => {
  const today = new Date();
  const [, setCurrentDate] = useState(currentDate);
  const [dayIndex, setDayIndex] = useState(currentDate.getDate());
  const [monthIndex, setMonthIndex] = useState(currentDate.getMonth());
  const [yearIndex, setYearIndex] = useState(currentDate.getFullYear());
  const [startDayIndex, setStartDayIndex] = useState(
    getStartDayIndexOfMonth(currentDate)
  );
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  const currentYear = today.getFullYear();
  // Prevent users from accessing dates 1 year into the future
  const isAfterMaxFutureMonth =
    currentDate > new Date(currentYear + 1, currentMonth, 0);

  const dayStyling = { width: "17%" };
  const dayHighlighterStyling = {
    width: { _: 35, xl: 45 },
    height: { _: 35, xl: 45 },
  };

  const setDate = date => {
    if (onSelectDate) {
      onSelectDate(date);
    } else {
      setCurrentDate(date);
    }
  };

  useEffect(() => {
    setDayIndex(currentDate.getDate());
    setMonthIndex(currentDate.getMonth());
    setYearIndex(currentDate.getFullYear());
    setStartDayIndex(getStartDayIndexOfMonth(currentDate));
  }, [currentDate]);

  const daysInMonthArray = isLeapYear(yearIndex)
    ? AMOUNT_OF_DAYS_IN_MONTH_LEAP
    : AMOUNT_OF_DAYS_IN_MONTH;

  const totalDaysOffsetByStartDay =
    daysInMonthArray[monthIndex] + startDayIndex;
  const extraDaysForFiller = 7 - (totalDaysOffsetByStartDay % 7);

  const daysWithinWeeksArray = Array(
    totalDaysOffsetByStartDay + extraDaysForFiller
  )
    .fill(null)
    .reduce((arr, val, index) => {
      const copiedArr = arr;
      const potentialDayIndexNumber = index - (startDayIndex - 1);
      const weekIndex = Math.floor(index / 7);
      const dayNumber =
        potentialDayIndexNumber > 0 &&
        potentialDayIndexNumber <= daysInMonthArray[monthIndex]
          ? potentialDayIndexNumber
          : "";
      if (copiedArr[weekIndex]) {
        copiedArr[weekIndex].push(dayNumber);
      } else {
        copiedArr[weekIndex] = [dayNumber];
      }

      return copiedArr;
    }, []);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex alignItems="center" justifyContent="space-between" px="5%">
        <Flex flex="85">
          <H2 color={textColor}>
            {MONTHS[monthIndex]} {dayIndex}, {yearIndex}
          </H2>
        </Flex>

        <Flex flex="15" justifyContent="space-between" alignItems="center">
          <IconButton
            Icon={ChevronLeft}
            color={textColor}
            onClick={() =>
              setDate(new Date(yearIndex, monthIndex - 1, dayIndex))
            }
          />
          <IconButton
            width="8px"
            Icon={() => (
              <Dot
                width="8px"
                height="8px"
                bg={textColor}
                onClick={() => onSelectDate(today)}
              />
            )}
          />
          <IconButton
            opacity={isAfterMaxFutureMonth ? 0 : 1}
            disabled={isAfterMaxFutureMonth}
            Icon={ChevronRight}
            color={textColor}
            onClick={() =>
              setDate(new Date(yearIndex, monthIndex + 1, dayIndex))
            }
          />
        </Flex>
      </Flex>
      <Flex>
        {DAYS_OF_THE_WEEK.map(dayOfWeek => (
          <Day {...dayStyling} color={textColor}>
            <strong>{dayOfWeek}</strong>
          </Day>
        ))}
      </Flex>
      <Flex flexDirection="column">
        {daysWithinWeeksArray.map(daysToRender => {
          return (
            <Flex mb={2}>
              {daysToRender.map(dayNumber => {
                let textColorCopy = textColor;
                const hasStreak = highlightedDays?.includes(dayNumber);
                const hasIndicator = indicatedDays?.includes(dayNumber);
                const isToday =
                  monthIndex === currentMonth &&
                  dayNumber === currentDay &&
                  yearIndex === currentYear;
                const isSelected = dayNumber === dayIndex;
                const borderRadius = "100%";
                let backgroundColor;

                if (hasStreak) {
                  backgroundColor = "bulbaGreen";
                } else if (isToday || isSelected) {
                  backgroundColor = "blue";
                }

                if (isToday) {
                  textColorCopy = "white";
                }

                const component = (
                  <Day
                    {...dayStyling}
                    cursor={Number(dayNumber) ? "pointer" : "auto"}
                    color={textColorCopy}
                    onClick={() =>
                      Number(dayNumber) &&
                      setDate(new Date(yearIndex, monthIndex, dayNumber))
                    }
                  >
                    <DayHighlighterWrapper {...dayHighlighterStyling}>
                      <DayHighlighter
                        opacity={isToday || hasStreak ? 1 : 0.3}
                        bg={backgroundColor}
                        borderRadius={borderRadius}
                        {...dayHighlighterStyling}
                      />
                      {hasIndicator && (
                        <Dot
                          width="7px"
                          height="7px"
                          bg="redMercedes.2"
                          position="absolute"
                          top={1}
                          right={1}
                        />
                      )}
                    </DayHighlighterWrapper>
                    <Div position="relative">{dayNumber}</Div>
                  </Day>
                );
                return component;
              })}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

Calendar.defaultProps = {
  currentDate: null,
  highlightedDays: [],
  indicatedDays: [],
  onSelectDate: null,
  textColor: "white",
};

Calendar.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  highlightedDays: PropTypes.instanceOf(Array),
  indicatedDays: PropTypes.instanceOf(Array),
  onSelectDate: PropTypes.func,
  textColor: PropTypes.string,
};

export default Calendar;
