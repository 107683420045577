import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cancelReason: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setCancelReason(state, action) {
      state.cancelReason = action.payload;
    },
  },
});

export const {
  setCancelReason: setCancelReasonAction,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
