import React from "react";
import loadable from "@loadable/component";
import LoaderCentered from "../components/Loader/LoaderCentered";

export const AsyncComponent = loadable(
  props =>
    import(
      /* webpackPrefetch: true */
      `./${props.path}`
    ),
  {
    fallback: <LoaderCentered />,
  }
);
