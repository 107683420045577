import axios from "axios";
import env from "helpers/env";

const refreshServerSessionForUser = async user => {
  if (env("WEBPACK_SERVE")) {
    return undefined;
  }
  const idToken = await user.getIdToken();
  return axios.post("/session", { idToken });
};

export default refreshServerSessionForUser;
