import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/";

export const getAnalyticsLocationFromPath = (path: string) => {
  if (!path) {
    return null;
  }

  if (path.includes("/playlists")) {
    return ANALYTICS_LOCATION.playlists;
  }
  if (path.includes("/dashboard")) {
    return ANALYTICS_LOCATION.home;
  }
  if (path.includes("/library")) {
    return ANALYTICS_LOCATION.library;
  }
  if (path.includes("/results")) {
    return ANALYTICS_LOCATION.library;
  }
  if (path.includes("/community")) {
    return ANALYTICS_LOCATION.community;
  }
  if (path.includes("/challenges")) {
    return ANALYTICS_LOCATION.community;
  }
  if (path.includes("/post")) {
    return ANALYTICS_LOCATION.community;
  }
  if (path.includes("/account")) {
    return ANALYTICS_LOCATION.account;
  }
  if (path.includes("/saved")) {
    return ANALYTICS_LOCATION.saved;
  }
  if (path.includes("/history")) {
    return ANALYTICS_LOCATION.history;
  }
  if (path.includes("/profile")) {
    return ANALYTICS_LOCATION.profile;
  }
  if (path.includes("/achievements")) {
    return ANALYTICS_LOCATION.achievements;
  }

  return null;
};

export function getAnalyticsModuleFromPath(pathname: string) {
  if (!pathname) {
    return null;
  }

  if (pathname.includes("/playlists/created")) {
    return ANALYTICS_MODULE.my_playlists;
  }
  if (pathname.includes("/playlists/following")) {
    return ANALYTICS_MODULE.followed_playlists;
  }
  if (pathname.includes("/playlists/steezy")) {
    return ANALYTICS_MODULE.steezy_playlists;
  }

  return null;
}
