import { createSlice } from "@reduxjs/toolkit";
import { Plan } from "services/graphql";

export interface CheckoutState {
  selectedPlan: Plan | null;
  coupon: string | null;
}

const initialState: CheckoutState = {
  selectedPlan: null,
  coupon: null,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setSelectedPlan(state, { payload }) {
      state.selectedPlan = payload;
    },
    setCoupon(state, { payload }) {
      state.coupon = payload;
    },
  },
});

export const { setSelectedPlan, setCoupon } = checkoutSlice.actions;

export default checkoutSlice.reducer;
