import React from "react";
import styled, { withTheme } from "styled-components";
import media from "styled-media-query";
import { Field } from "formik";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import { LARGE_BREAKPOINT } from "constants/index";

const defaultProps = {
  className: null,
  errors: {},
  label: "Input",
  enableLabel: false,
  placeholder: null,
  required: false,
  touched: false,
  validate: null,
  isSignUp: false,
  wrapperProps: {},
};

const propTypes = {
  theme: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  errors: PropTypes.shape({}),
  label: PropTypes.string,
  enableLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.shape({}),
  validate: PropTypes.func,
  isSignUp: PropTypes.bool,
  wrapperProps: PropTypes.shape({}),
};

const StyledField = styled(Field)`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ borderColor }) => borderColor};
  line-height: 21px;
  letter-spacing: 0.04em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${media.lessThan(LARGE_BREAKPOINT)`
    text-align: ${({ isSignUp }) => isSignUp && "center"};
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.monochrome[4]};

    ${media.greaterThan(LARGE_BREAKPOINT)`
      font-size: ${({ isSignUp }) => isSignUp && "18px"};
    `}
  }
  :focus {
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  ${({ isSignUp, borderColor }) => `
    padding: ${isSignUp ? "0px" : "8px"};
    border-left: ${isSignUp && "none"};
    border-right: ${isSignUp && "none"};
    border-top: ${isSignUp && "none"};
    border-bottom: ${isSignUp && `2px solid ${borderColor}`};
    border-radius: ${isSignUp ? "0" : "2px"};
  `}
`;

const Wrapper = styled(Flex)`
  flex-flow: column nowrap;

  ${media.lessThan(LARGE_BREAKPOINT)`
    text-align: ${({ isSignUp }) => isSignUp && "center"};
  `}
`;

const RedSmall = styled.small`
  color: ${({ theme }) => theme.colors.redMercedes[2]};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.04em;
  padding: 10px 0 0 0;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 32px;
  color: ${({ color }) => color};

  ${({ isSignUp }) => `
    font-weight: ${isSignUp || "bold"};
    margin-bottom: ${isSignUp || "12px"};
  `}
`;

const Input = ({
  errors,
  label,
  enableLabel,
  name,
  required,
  touched,
  validate,
  theme,
  isSignUp,
  wrapperProps,
  ...rest
}) => (
  <Wrapper isSignUp={isSignUp} {...wrapperProps}>
    {enableLabel && (
      <StyledLabel
        color={
          (errors[name] && touched[name] && theme.colors.redMercedes[2]) ||
          theme.colors.monochrome[8]
        }
        isSignUp={isSignUp}
      >
        {label}
      </StyledLabel>
    )}
    <StyledField
      {...rest}
      name={name}
      required={required}
      borderColor={
        (errors[name] && touched[name] && theme.colors.redMercedes[2]) ||
        (isSignUp ? theme.colors.blue : theme.colors.monochrome[1])
      }
      isSignUp={isSignUp}
      validate={values => {
        if (validate) {
          return validate(values);
        }
        if (required && (!values || values === "")) {
          return `${label} required`;
        }
        return null;
      }}
    />
    {errors[name] && touched[name] && <RedSmall>{errors[name]}</RedSmall>}
  </Wrapper>
);

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;

export default withTheme(Input);
