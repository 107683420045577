import {
  searchSelectContent,
  librarySelectContent,
  homeSelectContent,
  HomeSelectContentContainer,
  LibrarySelectContentContainer,
  HomeSelectContentContentType,
} from "services/typewriter/segment";
import { useAccountMode } from "hooks/useAccountMode";
import { ANALYTICS_LOCATION } from "constants/analytics";
import { LIBRARY_MODULE, SEARCH_MODULE } from "constants/index";
import { getFiltersApplied } from "helpers/getFiltersApplied";
import { useLocation } from "react-router";
import qs from "qs";
import { AlgoliaClassRefinements } from "app/components/Algolia/types";

type Container = HomeSelectContentContainer & LibrarySelectContentContainer;

export function useClassSelectContentEvent({ classData }: { classData: any }) {
  const location = useLocation();
  const { accountMode } = useAccountMode();

  const state = location.state as { prevPath: string };
  const prevPath = state?.prevPath;
  const selectContentData = {
    content_name: classData.title,
    content_type: HomeSelectContentContentType.Class,
    content_id: classData.id,
    account_mode: accountMode,
  };
  const { query, refinementList } = (qs.parse(
    location.search.slice(1)
  ) as unknown) as {
    query: string;
    refinementList: AlgoliaClassRefinements;
  };

  function selectContentEvent({ container }: { container?: Container }) {
    if (location.pathname?.includes("/results/classes") && query?.length > 1) {
      searchSelectContent({
        ...selectContentData,
        location: ANALYTICS_LOCATION.search,
        module: SEARCH_MODULE.search_classes,
        search_text: query,
        container: null, // should be titled carousel/list, which does not exist currently
        element_type: "class_card",
        featured: false,
      });
    } else if (
      location.pathname?.includes("/library/classes") ||
      prevPath === "/library/classes"
    ) {
      librarySelectContent({
        ...selectContentData,
        location: ANALYTICS_LOCATION.library,
        module: LIBRARY_MODULE.library_classes,
        container: LibrarySelectContentContainer.Classes,
        filters_applied: getFiltersApplied({ refinementList }),
        element_type: "class_card",
        featured: false,
      });
    } else if (location.pathname?.includes("/results/categories")) {
      librarySelectContent({
        ...selectContentData,
        location: ANALYTICS_LOCATION.library,
        module: LIBRARY_MODULE.library_categories,
        filters_applied: getFiltersApplied({ refinementList }),
        element_type: "class_card",
        featured: false,
      });
    } else if (
      prevPath === "/library/instructors" ||
      prevPath === "/results/instructors"
    ) {
      librarySelectContent({
        ...selectContentData,
        location: ANALYTICS_LOCATION.library,
        module: LIBRARY_MODULE.library_instructors,
        container,
        element_type: "class_card",
        featured: container === "featured_class",
      });
    } else if (location.pathname?.includes("/dashboard")) {
      const elementType =
        container === "featured_content" ? "featured_card" : "class_card";

      homeSelectContent({
        ...selectContentData,
        location: ANALYTICS_LOCATION.home,
        container,
        element_type: elementType,
        featured: container === "featured_content",
        module: null, // passing empty per https://stzy.atlassian.net/wiki/spaces/SP/pages/145195071/Home+Redesign+v1+Analytics
      });
    }
  }

  return {
    selectContentEvent,
  };
}
