import axios from "axios";
import env from "helpers/env";
import isServer from "../helpers/isServer";

class ApiWrapper {
  constructor() {
    const instance = axios.create({
      baseURL: isServer ? env("SERVER_API_DOMAIN") : env("PUBLIC_API_DOMAIN"),
    });
    instance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.instance = instance;
  }

  handleSuccess = response => {
    return response;
  };

  handleError = error => {
    return Promise.reject(error);
  };

  get(url, config) {
    return this.instance.get(url, config);
  }

  patch(url, data, config) {
    return this.instance.request({
      method: "PATCH",
      url,
      responseType: "json",
      data,
      ...config,
    });
  }

  post(url, data, config) {
    return this.instance.request({
      method: "POST",
      url,
      responseType: "json",
      data,
      ...config,
    });
  }

  put(url, data, config) {
    return this.instance.request({
      method: "PUT",
      url,
      responseType: "json",
      data,
      ...config,
    });
  }
}

export default new ApiWrapper();
