import React, { useState, createContext, useContext } from "react";
import { StyledModal, Wrapper, BackgroundClick } from "./styles";

export const ModalContext = createContext({
  isRenderedInModal: false,
});

export const useModalContext = () => useContext(ModalContext);

interface Props {
  afterOpen?(): void;
  children: any;
  isOpen: boolean;
  onBackgroundClick(): void;
  backgroundProps?: any;
  onEscapeKeydown(): void;
}

export function ModalWithContext({
  afterOpen: afterOpenCallback,
  children,
  isOpen,
  onBackgroundClick,
  backgroundProps,
  onEscapeKeydown,
  ...rest
}: Props) {
  const [opacity, setOpacity] = useState(0);

  const afterOpen = () => {
    document.body.className = "modal-open";
    setTimeout(() => {
      setOpacity(1);
    });

    if (afterOpenCallback) {
      afterOpenCallback();
    }
  };

  const beforeClose = () =>
    new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });

  const afterClose = () => {
    document.body.style.overflow = "";
    document.body.className = "";
  };

  return (
    <StyledModal
      isOpen={isOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      afterClose={afterClose}
      opacity={opacity}
      backgroundProps={{ ...backgroundProps, opacity }}
      onEscapeKeydown={onEscapeKeydown}
      aria-modal="true"
      tabIndex="-1"
    >
      <BackgroundClick
        onClick={() => {
          if (onBackgroundClick) {
            onBackgroundClick();
          }
        }}
      />
      <ModalContext.Provider value={{ isRenderedInModal: true }}>
        <Wrapper {...rest}>{children}</Wrapper>
      </ModalContext.Provider>
    </StyledModal>
  );
}
