import isServer from "./isServer";

const getTransactionId = () => {
  if (isServer) {
    return undefined;
  }
  return sessionStorage.getItem("txid");
};

export default getTransactionId;
