import React from "react";
import { StaticRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

interface Props {
  location: string;
  children: React.ReactNode;
  history: Record<string, unknown>;
  context: Record<string, unknown>;
}

const RouterProvider = ({ location, children, history, context }: Props) => {
  const isSsr = typeof window === "undefined";
  if (isSsr) {
    return (
      <StaticRouter location={location} context={context}>
        {children}
      </StaticRouter>
    );
  }
  return <ConnectedRouter history={history}>{children}</ConnectedRouter>;
};

export default RouterProvider;
