import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Div from "app/components/Div";

const StyledToastContainer = styled(ToastContainer)`
  .Toastify {
    z-index: 99999;
  }

  .Toastify__toast {
    background-color: #202020;
    color: ${({ theme }) => theme.colors.white};
    padding: 16px 10px;
    font-size: 14px;
  }
  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    align-self: center;
    margin-left: 32px;
  }

  .Toastify__close-button > svg {
    width: 20px;
    height: 20px;
  }

  .Toastify__toast--success {
    border-left: 6px solid ${({ theme }) => theme.colors.cleanMint[5]};
  }

  .Toastify__toast--error {
    border-left: 6px solid ${({ theme }) => theme.colors.redMercedes[3]};
  }
`;

const WrappedToastContainer = props => (
  <Div zIndex="999999 !important">
    <StyledToastContainer {...props} />
  </Div>
);

export default WrappedToastContainer;
