import { useDispatch, useSelector } from "react-redux";
import { setField } from "modules/player-settings";
import { setSpeedClassAction } from "modules/classPlayer";

const usePlayerSettings = () => {
  const dispatch = useDispatch();
  const { playerSettings, speed } = useSelector(state => ({
    playerSettings: state.playerSettings,
    speed: state.classPlayer.speed,
  }));

  const setSpeed = newSpeed => {
    dispatch(setSpeedClassAction(newSpeed));
  };

  const setPlayerSetting = (field, value) => {
    dispatch(setField({ field, value }));
  };

  const togglePlayerSetting = field => {
    const toggledValue = !playerSettings[field];
    dispatch(setField({ field, value: toggledValue }));
    return toggledValue;
  };

  return {
    ...playerSettings,
    speed,
    setSpeed,
    setPlayerSetting,
    togglePlayerSetting,
  };
};

export default usePlayerSettings;
