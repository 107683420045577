import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 50px;
  padding-top: 25px;
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Line = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.monochrome[1]};
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.monochrome[5]};
  display: inline-block;
  background-color: inherit;
  padding: 5px 10px;
  font-size: 14px;
  top: -16px;
  position: relative;
  text-transform: lowercase;
`;

const LineThroughText = ({ className, children }) => (
  <Wrapper className={className}>
    <Line />
    <Text>{children}</Text>
  </Wrapper>
);

LineThroughText.defaultProps = {
  className: null,
};

LineThroughText.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LineThroughText;
