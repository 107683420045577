import { createSlice } from "@reduxjs/toolkit";
import { push } from "connected-react-router";

export interface ContinuityState {
  isContinuityActive: boolean;
  entryRoute?: string;
}

const initialState: ContinuityState = { isContinuityActive: false };

const continuitySlice = createSlice({
  name: "continuity",
  initialState,
  reducers: {
    enterContinuity(state, action) {
      state.isContinuityActive = true;
      const { entryRoute } = action.payload;
      state.entryRoute = entryRoute;
    },
    exitContinuity(state) {
      if (state.isContinuityActive) {
        state.isContinuityActive = false;
        state.entryRoute = "";
        push(state.entryRoute);
      }
    },
  },
});

export const { enterContinuity, exitContinuity } = continuitySlice.actions;
export default continuitySlice.reducer;
