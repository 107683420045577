import Flex from "app/components/Flex";
import { H2, H4 } from "app/components/Typography";
import React from "react";
import { Option, OptionList, Wrapper } from "./styles";

export const NotFound = () => (
  <Wrapper>
    <Flex p={4}>
      <span
        role="img"
        aria-label="eyes-left"
        style={{ fontSize: "40px", paddingRight: "24px" }}
      >
        👀
      </span>
      <Flex flexDirection="column">
        <H2 pb={3}>Looks like something went wrong...</H2>
        <H4>If you&#8217;re in the right place, you could try:</H4>
        <OptionList>
          <Option>Refreshing the page</Option>
          <Option>Checking your internet connection</Option>
        </OptionList>
      </Flex>
    </Flex>
  </Wrapper>
);
