import Modal from "app/components/Modal";
import styled from "styled-components";

export const FullscreenModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight}px;
  max-width: 100vw;
  border-radius: 0;
  overflow: auto;
  z-index: 9999999;
  position: absolute;
`;
