import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";
import toSentence from "helpers/toSentence";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import CloseButton from "app/components/Button/CloseButton";
import moment from "moment";
import env from "helpers/env";
import NoSsr from "../NoSsr";

const StyledExperimentLabel = styled(Flex)`
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: ${({ theme }) => theme.colors.white};
  z-index: 9999999 !important;
  padding: 5px 10px;
  font-size: 12px;
  background: ${({ theme }) => theme.colors.red};
`;

const CloseExpLabelButton = styled(CloseButton)`
  right: 5px;
`;

const ExperimentLabel = () => {
  const [isShowing, setIsShowing] = useState(true);
  const { uid, email } = useSelector(({ auth }) => auth);
  const userExperiments = useSelector(({ user }) => user?.private?.experiment);

  if (env("PUBLIC_ENV") !== "testing" && env("NODE_ENV") !== "development") {
    return <></>;
  }

  return (
    <NoSsr>
      <StyledExperimentLabel display={!isShowing && "none"}>
        <Flex flexDirection="column">
          <Div>
            Experiment: &nbsp;
            {toSentence(_.map(userExperiments, (val, key) => `${key}:${val}`))}
          </Div>
          <Div>UID: {uid ?? "none"}</Div>
          <Div>Email: {email ?? "none"}</Div>
          {env("PUBLIC_VERSION") && <Div>Version: {env("PUBLIC_VERSION")}</Div>}
          {env("PUBLIC_SHA") && (
            <Div>SHA: {env("PUBLIC_SHA").substring(0, 8)}</Div>
          )}
          {env("PUBLIC_BUILD_TIME") && (
            <Div>
              Built {moment.unix(Number(env("PUBLIC_BUILD_TIME"))).fromNow()}
            </Div>
          )}
        </Flex>
        <CloseExpLabelButton
          onClick={() => setIsShowing(false)}
          dataTestId="close-experiment-label-button"
        />
      </StyledExperimentLabel>
    </NoSsr>
  );
};

export default ExperimentLabel;
