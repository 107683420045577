import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { border, layout, system } from "styled-system";

const ImgWrapper = styled.img.attrs(({ width }) => ({
  width: width || "100%",
}))`
  ${layout}
  ${border}
  ${system({
    transition: true,
    opacity: true,
  })}
`;

const Thumbnail = ({ placeholderSrc, src, ...rest }) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      {loading && <ImgWrapper src={placeholderSrc} {...rest} />}
      <ImgWrapper
        display={loading ? "none" : "block"}
        src={src}
        onLoad={() => setLoading(false)}
        {...rest}
      />
    </>
  );
};

Thumbnail.defaultProps = {
  placeholderSrc: null,
};

Thumbnail.propTypes = {
  placeholderSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default Thumbnail;
