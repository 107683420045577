import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetCommunityAccess } from "hooks/User";
import { DropdownOption } from "app/components/Dropdown";
import { Link } from "react-router-dom";
import { logout } from "modules/auth/actions";

interface RootState {
  user: {
    private: {
      role: string;
    };
  };
  auth: {
    isAnonymous: boolean;
  };
}

export const Links = () => {
  const dispatch = useDispatch();
  const isAnonymous = useSelector(({ auth }: RootState) => auth.isAnonymous);
  const [hasCommunityAccess] = useGetCommunityAccess();

  const viewProfile = !isAnonymous && hasCommunityAccess;

  const signOut = () => {
    dispatch(logout());
  };

  return (
    <>
      {viewProfile && (
        <Link to="/profile">
          <DropdownOption text="View Profile" />
        </Link>
      )}
      {!isAnonymous && (
        <Link to="/account/settings">
          <DropdownOption text="Settings" />
        </Link>
      )}
      {!isAnonymous && (
        <Link to="/achievements">
          <DropdownOption text="Achievements" />
        </Link>
      )}
      {!isAnonymous && <DropdownOption onClick={signOut} text="Log Out" />}
    </>
  );
};
