import {
  AlgoliaClassMultiRanges,
  AlgoliaClassRefinements,
} from "../app/components/Algolia/types";

export const getFiltersApplied = ({
  refinementList,
  multiRange,
}: {
  refinementList: AlgoliaClassRefinements;
  multiRange?: AlgoliaClassMultiRanges;
}) => {
  const filtersApplied = [] as string[];

  if (multiRange) {
    Object.keys(multiRange).forEach(key => {
      const rangeValue = multiRange[key as keyof AlgoliaClassMultiRanges];
      if (rangeValue) {
        filtersApplied.push(rangeValue);
      }
    });
  }

  if (refinementList) {
    Object.keys(refinementList).forEach(key => {
      const refinementValue =
        refinementList[key as keyof AlgoliaClassRefinements];
      if (refinementValue) {
        filtersApplied.push(...refinementValue);
      }
    });
  }

  return filtersApplied;
};
