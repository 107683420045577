import React from "react";
import styled, { withTheme } from "styled-components";
import Div from "app/components/Div";
import PropTypes from "prop-types";
import Input from "./index";

const defaultProps = {
  errors: null,
  touched: false,
  isSignUp: false,
};

const propTypes = {
  theme: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  isSignUp: PropTypes.bool,
};

const Wrapper = styled(Div)`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding: ${({ isSignUp }) => (isSignUp ? "8px 4px" : "10px 12px")};
  border-width: 2px;
  border-radius: ${({ isSignUp }) => (isSignUp ? "0" : "4px")};
`;

const InputAddon = props => {
  const { isSignUp, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <StyledInput isSignUp={isSignUp} {...props} />
    </Wrapper>
  );
};

InputAddon.defaultProps = defaultProps;
InputAddon.propTypes = propTypes;

export default withTheme(InputAddon);
