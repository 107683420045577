import React from "react";
import PropTypes from "prop-types";
import { Configure, Index, InstantSearch } from "react-instantsearch-dom";
import { searchClient } from "app/components/Algolia/constants";
import AutoComplete from "./AutoComplete";
import MobileAutoComplete from "./MobileAutoComplete";
import { AlgoliaIndexes } from "../Algolia/types";

const SearchBar = ({ useMobile }) => (
  <InstantSearch indexName={AlgoliaIndexes.classes} searchClient={searchClient}>
    <Configure hitsPerPage={5} />
    {useMobile ? <MobileAutoComplete /> : <AutoComplete />}
    <Index indexName={AlgoliaIndexes.programs} />
  </InstantSearch>
);

SearchBar.defaultProps = {
  useMobile: false,
};

SearchBar.propTypes = {
  useMobile: PropTypes.bool,
};

export default SearchBar;
