import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Grid from "app/components/Grid";
import Div from "app/components/Div";
import { H7, P1 } from "app/components/Typography";
import Avatar from "app/components/Avatar";
import styled from "styled-components";
import { color } from "styled-system";
import { useDispatch } from "react-redux";
import { declineOrApproveMemberAction } from "modules/party";
import { PLACEHOLDER_FIRST_NAME } from "constants/index";

const Button = styled.button.attrs({
  color: "white",
})`
  padding: 8px;
  border: 0;
  border-radius: 2px;
  ${color}
`;

const MemberJoinNotification = ({ closeToast, pid, uid, usersPublicData }) => {
  const dispatch = useDispatch();

  const declineOrApproveMember = isApproved => {
    dispatch(
      declineOrApproveMemberAction({
        pid,
        uid,
        isApproved,
      })
    );
  };

  return (
    <Flex alignItems="center" width="100%">
      <Div mr={3}>
        <Avatar
          border="1px solid"
          borderColor="monochrome.7"
          width="40px"
          height="40px"
          src={usersPublicData.profile_pic}
        />
      </Div>
      <Div width="100%">
        <P1 color="white">
          {usersPublicData?.first_name || PLACEHOLDER_FIRST_NAME} would like to
          join the party
        </P1>
        <Grid gridTemplateColumns="1fr 1fr" gridGap={2} mt={2}>
          <Button
            bg="monochrome.5"
            onClick={() => {
              declineOrApproveMember(false);
              closeToast();
            }}
          >
            <H7>Decline</H7>
          </Button>
          <Button
            bg="blue"
            onClick={() => {
              declineOrApproveMember(true);
              closeToast();
            }}
          >
            <H7>Accept</H7>
          </Button>
        </Grid>
      </Div>
    </Flex>
  );
};

MemberJoinNotification.propTypes = {
  closeToast: PropTypes.func.isRequired,
  pid: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  usersPublicData: PropTypes.shape({}).isRequired,
};

export default MemberJoinNotification;
