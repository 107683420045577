import { InMemoryCache } from "@apollo/client";
import { concat, uniqBy } from "lodash";

export const createCache = () =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getHistoryDataV2: {
            keyArgs: false,
            merge(existing = {}, incoming) {
              /**
               * Explicit merge() here b/c the return type is non-normalized.
               * Apollo cannot tell how to dedupe, so we have to do it.
               *
               * See https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
               */
              const { edges: existingEdges = [] } = existing;

              const unfilteredEdges = concat(existingEdges, incoming.edges);
              const edges = uniqBy(unfilteredEdges, e => e.node.__ref);

              return {
                ...incoming,
                edges,
              };
            },
          },
          playlistsSteezy: {
            keyArgs: false,
            merge: true,
          },
        },
      },
      Class: {
        keyFields: ({ id, refId }) =>
          refId ? `Class:${JSON.stringify({ id, refId })}` : `Class:${id}`,
      },
      Category: {
        keyFields: ["slug"],
      },
      User: {
        fields: {
          myPlaylists: {
            keyArgs: false,
            merge: true,
          },
          savedPlaylists: {
            keyArgs: false,
            merge: true,
          },
        },
      },
      PlaylistV2: {
        fields: {
          studioClasses: {
            keyArgs: false,
            merge: true,
          },
        },
      },
      ProgramV2: {
        keyFields: ["slug"],
      },
      ProgramBlock: {
        keyFields: ["refId"],
      },
      ProgramSection: {
        keyFields: ["refId"],
      },
      Challenge: {
        fields: {
          comments: {
            keyArgs: false,
          },
        },
      },
      ActivityComment: {
        fields: {
          replies: {
            keyArgs: false,
          },
        },
      },
    },
  });

const clientCache = createCache();

export default clientCache;
