import { useGetPrimarySubscriptionQuery } from "services/graphql";

export function usePrimarySubscription() {
  const { data, loading, error, refetch } = useGetPrimarySubscriptionQuery();

  if (loading) {
    return {};
  }

  if (!data) {
    return {};
  }

  // @TODO?: Throw some kind of error
  if (error) {
    return {};
  }
  const { me } = data;

  if (!me) {
    return {};
  }

  const { primarySubscription } = me;

  return {
    primarySubscription,
    refetchPrimarySubscription: refetch,
    loadingPrimarySubscription: loading,
  };
}
