import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useGetCategoriesQuery } from "services/graphql";
import Flex from "../Flex";
import FollowedSideNavLink from "./FollowedSideNavLink";
import { P2 } from "../Typography";
import Button from "../Button";
import { LimitLineH5, LimitLineP2, LineSeparator } from "./lines";

const FollowedCategories = ({ goToLink }) => {
  const isAnonymous = useSelector(({ auth }) => auth.isAnonymous);
  const { data, loading } = useGetCategoriesQuery({
    variables: {
      slugs: [],
    },
    fetchPolicy: "cache-and-network",
  });

  const CategoryList = useMemo(
    () => () => {
      const followedCategories =
        data?.categories?.filter(category => category.isFollowing) || [];
      if (loading && !data?.categories?.length) {
        return (
          <Flex px={3} flexDirection="column">
            <P2 data-test-id="followed-category-loader">Loading...</P2>
          </Flex>
        );
      }
      return followedCategories.length ? (
        followedCategories.map(category => {
          return (
            <FollowedSideNavLink
              key={category.slug}
              path={`/results/categories/${category.slug}`}
              goToLink={goToLink}
            >
              <LimitLineP2>{category.name}</LimitLineP2>
            </FollowedSideNavLink>
          );
        })
      ) : (
        <Flex px={3} flexDirection="column">
          <P2 color="monochrome.4">
            Your list is empty, start following your favorite categories now!
          </P2>
          <Button
            mt={3}
            fontSize="9px"
            height="32px"
            onClick={() => goToLink({ path: "/library/categories" })}
            py={0}
          >
            Take Me To Categories Page
          </Button>
        </Flex>
      );
    },
    [loading, data, goToLink]
  );

  if (isAnonymous) {
    return null;
  }
  return (
    <>
      <LineSeparator />
      <Flex
        px="18px"
        width="100%"
        height="32px"
        alignItems="center"
        mb={2}
        color="black"
      >
        <LimitLineH5>Followed Categories</LimitLineH5>
      </Flex>
      <Flex flexDirection="column" mb={3}>
        <CategoryList />
      </Flex>
    </>
  );
};

FollowedCategories.propTypes = {
  goToLink: PropTypes.func.isRequired,
};

export default FollowedCategories;
