import styled, { css } from "styled-components";
import {
  smallCtaStyle,
  mediumCtaStyle,
  largeCtaStyle,
} from "app/components/Typography";
import {
  color,
  size,
  space,
  buttonStyle,
  border,
  layout,
  position,
  SpaceProps,
  BorderProps,
  LayoutProps,
  PositionProps,
  SizeProps,
  FontSizeProps,
} from "styled-system";
import { ButtonHTMLAttributes } from "react";

const sizeStylings = {
  sm: smallCtaStyle,
  md: mediumCtaStyle,
  lg: largeCtaStyle,
};

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    SpaceProps,
    BorderProps,
    LayoutProps,
    PositionProps,
    SizeProps,
    FontSizeProps {
  variant?: string;
  sizeStyling?: string;
  cursor?: string;
  hbg?: string;
  hoverBg?: string;
  hc?: string;
  hoverColor?: string;
  disabled?: boolean;
}

export const buttonCss = css<ButtonProps>`
  user-select: none;
  background-color: ${props => props.theme.colors.primary[0]};
  color: ${props => props.theme.colors.white};
  border: 1px solid transparent;
  padding: 12px 50px;
  border-radius: 2px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  cursor: ${({ cursor }) => (cursor !== "pointer" ? cursor : "pointer")};
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  position: relative;

  :disabled {
    opacity: 0.8;	
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.monochrome[1]};	
    color: ${({ theme }) => theme.colors.monochrome[2]};	

    @media (hover: hover) {	
      :hover {	
        background: ${({ theme }) => theme.colors.monochrome[1]};	
      }	
    }
  }

  ${({ sizeStyling }) =>
    sizeStylings[sizeStyling as keyof typeof sizeStylings]};
  ${buttonStyle}

  @media (hover: hover) {
    :hover {
      background: ${({ hbg, hoverBg, theme }) =>
        // Hotfix to account for color dot notation like monochrome.2
        (hbg && hbg.split(".").reduce((o, i) => o[i], theme.colors)) ||
        (hoverBg && hoverBg.split(".").reduce((o, i) => o[i], theme.colors))};
      color: ${({ hc, hoverColor, theme }) =>
        (hc && theme.colors[hc]) || (hoverColor && theme.colors[hoverColor])};
    }
  }
  ${space}
  ${color}
  ${border}
  ${layout}
  ${position}
  ${size}
`;

const Button = styled.button<ButtonProps>`
  ${buttonCss}
`;

Button.defaultProps = {
  variant: "primary",
  sizeStyling: "sm",
};

export default Button;
