/*
  @TODO: Build these into enums on bifrost repo so that we can import from segment.ts
*/

export const ANALYTICS_ACTION = {
  setupPin: "Setup PIN",
  skipPinSetup: "Skip PIN Setup",
  updatePin: "Update PIN",
  disablePin: "Disable PIN",
  playlistsCreate: "Playlists - Create Playlist",
  playlistsEdit: "Playlists - Edit Playlist Details",
  playlistsDelete: "Playlists - Delete Playlist",
  playlistsAddClass: "Playlists - Add Class",
  playlistRemoveClass: "Playlists - Remove Class",
  playlistReorderClass: "Playlists - Re-order Class",
};

export const ANALYTICS_ELEMENT_NAME = {
  accountSteezyFamily: "Account - Steezy Family",
  playlistCreateButton: "Playlists - Create Playlist Button",
  playlistAddClassesButton: "Playlists - Add Classes Button",
};

export const ANALYTICS_ELEMENT_TYPE = {
  list_element: "list_element",
  button: "button",
};
export const ANALYTICS_LOCATION = {
  avatar_dropdown: "avatar_dropdown",
  community: "community",
  library: "library",
  home: "home",
  search: "search",
  account: "account",
  saved: "saved",
  history: "history",
  launch: "launch",
  onboarding: "onboarding",
  profile: "profile",
  playlists: "playlists",
};
export const ANALYTICS_MODULE = {
  community_feed: "community_feed",
  community_guidelines: "community_guidelines",
  challenge_detail: "challenge_detail",
  my_profile: "my_profile",
  challenge_post: "challenge_post",
  navigation_bar: "navigation_bar",
  onboarding: "onboarding",
  setup_pin: "setup_pin",
  mode_selection: "mode_selection",
  update_pin: "update_pin",
  disable_pin: "disable_pin",
  account: "account",
  playlist_details: "playlist_details",
  my_playlists: "my_playlists",
  steezy_playlists: "steezy_playlists",
  followed_playlists: "followed_playlists",
  add_classes_modal: "add_classes_modal",
  user_video_modal: "user_video_modal",
  user_video_post: "user_video_post",
  class_details: "class_details",
};

export const ANALYTICS_SCREEN_NAME = {
  myPlaylists: "Playlists - My Playlists",
  steezyPlaylists: "Playlists - STEEZY Playlists",
  followedPlaylists: "Playlists - Followed Playlists",
};
