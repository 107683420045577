import { gql } from "@apollo/client";

export const CORE_VIDEO_UPLOAD_FIELDS = gql`
  fragment CoreVideoUploadFields on VideoUpload {
    id
    isAuthoredByMe
    reportedByMe
    description
    thumbnailUrl
    playbackUrl
    uploadedAt
    uploadedBy {
      id
      uid
      username
    }
    encodingStatus
    reactions {
      reactionType
      totalCount
    }
    userReactions
  }
`;

export const CHALLENGE_FIELDS = gql`
  fragment ChallengeFields on Challenge {
    id
    title
    description
    forClass {
      id
      title
      isUnlocked
      isFree
      isSaved
      categories
      duration
      type
      preview_url
      thumbnail
      level
      style
      duration
      isUnlocked
      isFree
      instructor {
        name
        slug
      }
    }
    isActive
    challengeStatus
    startTime
    endTime
    isActive
    submissionsCount
    reactions {
      reactionType
      totalCount
    }
    userReactions
  }
`;
