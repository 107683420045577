const preserveUrlHash = (history, hashString) => {
  let prevLocation = history.location;
  history.listen(nextLocation => {
    const prevHash = prevLocation.hash;
    const nextHash = nextLocation.hash;
    prevLocation = nextLocation;

    if (prevHash === `#${hashString}` && nextHash !== `#${hashString}`) {
      history.replace({
        ...nextLocation,
        hash: prevHash,
      });
    }
  });
};

export default preserveUrlHash;
