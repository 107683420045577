import returnVersionTest from "helpers/returnVersionTest";
import { saveUserPrivateAction } from "modules/user/actions";

export const START_EXPERIMENT = "experiment/START_EXPERIMENT";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_EXPERIMENT:
      return state;

    default:
      return state;
  }
};

// versions is an array of objects that contains name and weight
// name should be v1, v2, etc.
// weight is the amount you want the version to show up for your users
// e.g v1 with weight 1 and v2 with weight 2 will create an array [v1, v2, v2]
// so v1 will have 1/3 chance and v2 will have 2/3 chance of being selected
export const startExperimentAction = (number, index, versions) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { currentUser } = firebase.auth();
  const version = returnVersionTest(currentUser.uid, index || 2, versions);

  dispatch(
    saveUserPrivateAction({
      [`/experiment/${number}`]: version,
    })
  );
};
