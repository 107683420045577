import React from "react";
import ErrorBoundary from "app/components/ErrorBoundary";
import App from "app/app";
import AppProviders from "app/components/AppProviders";
import GlobalStyles from "./GlobalStyles";

const Application = ({
  location,
  store,
  history,
  persistor,
  firebaseInstance,
  apolloClient,
  helmetContext,
  routerContext,
  widgetsCollector,
} = {}) => {
  return (
    <AppProviders
      apolloClient={apolloClient}
      firebaseInstance={firebaseInstance}
      history={history}
      persistor={persistor}
      store={store}
      location={location}
      helmetContext={helmetContext}
      widgetsCollector={widgetsCollector}
      routerContext={routerContext}
    >
      <React.Fragment>
        <GlobalStyles />
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </React.Fragment>
    </AppProviders>
  );
};

export default Application;
