import React from "react";
import styled from "styled-components";
import { ScreenGtMd } from "app/components/MediaQueries";
import { useSelector } from "react-redux";
import LinkButton from "../Button/LinkButton";

const StyledLinkButton = styled(LinkButton)`
  font-size: 12px;
  letter-spacing: 2px;
  padding: 16px 32px;
  height: 100%;
  border-radius: 0;
  font-weight: medium;
`;

const SubscribeButton = () => {
  const hasSubscription = useSelector(
    ({ user }) => user.subscription.hasSubscription
  );

  return (
    <ScreenGtMd>
      <StyledLinkButton to="/checkout">
        {hasSubscription ? "Reactivate" : "Subscribe"}
      </StyledLinkButton>
    </ScreenGtMd>
  );
};

export default SubscribeButton;
