import React, { useEffect, useState } from "react";
import Text from "app/components/Text";
import Flex from "app/components/Flex";
import { H6 } from "app/components/Typography";
import Button from "app/components/Button";
import { FullscreenModal } from "app/components/FullscreenModal";
import { PrivacyFooter } from "app/components/PrivacyFooter";
import { useUserSetParentalControlsMutation } from "services/graphql";
import Icon, { STEEZYLogo, Close } from "app/components/Icon";
import { toast } from "react-toastify";
import IconButton from "app/components/Button/IconButton";
import PinInput from "../PinInput";
import { areInputValuesValid } from "../helpers/areInputValuesValid";

interface Props {
  isOpen: boolean;
  validatedPin?: string;
  onSuccess(): void;
  onCancel(): void;
}

export const CreatePinModal = ({
  isOpen,
  validatedPin,
  onSuccess,
  onCancel,
}: Props) => {
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [hasError, setHasError] = useState(false);
  const [potentialNewPin, setPotentialNewPin] = useState<string>();
  const [
    setParentalControlsMutation,
    {
      data: setParentalControlsData,
      error: userSetParentalControlsMutationError,
    },
  ] = useUserSetParentalControlsMutation();

  const currentInputPin = inputValues.join("");

  const setUserPin = () => {
    if (!potentialNewPin) {
      initializePin();
    } else {
      confirmPin();
    }
  };

  const initializePin = () => {
    setPotentialNewPin(currentInputPin);
    setInputValues(["", "", "", ""]);
  };

  const confirmPin = () => {
    if (potentialNewPin === currentInputPin) {
      setParentalControlsMutation({
        variables: {
          input: {
            currentPin: validatedPin, // validatedPin will be null when enabling Parent Controls
            isEnabled: true,
            newPin: potentialNewPin,
          },
        },
      });
    } else {
      setHasError(true);
    }
  };

  const handleEnterKey = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      setUserPin();
    }
  };

  useEffect(() => {
    if (!userSetParentalControlsMutationError && setParentalControlsData) {
      toast.success("PIN Saved");
      onSuccess();
    }
    // @POTENTIAL TODO: handle what happens when error occurs
  }, [userSetParentalControlsMutationError, setParentalControlsData]);

  return (
    <FullscreenModal isOpen={isOpen} p={0}>
      <Flex width="100%" height="100%" flexDirection="column">
        <Flex p={4} width="100%" height="100%" flexDirection="column">
          <Flex justifyContent="space-between" width="100%">
            <Icon height="16px" as={STEEZYLogo} />
            <IconButton
              Icon={Close}
              width="20px"
              height="auto"
              color="grey"
              onClick={onCancel}
            />
          </Flex>
          <Flex
            width="100%"
            height="100%"
            justifyContent={{ _: "start", md: "center" }}
            flexDirection="column"
            onKeyDown={handleEnterKey}
          >
            <Text fontWeight="700" fontSize="63px">
              {potentialNewPin ? "Re-Enter your PIN" : "Create your PIN"}
            </Text>
            <Text color="blue" my="40px" fontWeight="700" fontSize="23px">
              {potentialNewPin
                ? "Confirm your four digit pin"
                : "Create your PIN to access STEEZY STUDIO (13+ content), while STEEZY FAMILY is open to the whole family."}
            </Text>
            <PinInput
              autoFocus
              values={inputValues}
              hasError={hasError}
              onChange={({ values }) => setInputValues(values)}
            />
            {hasError && (
              <H6 color="redMercedes.5" mt="16px">
                Looks like the pins didn&apos;t match. Try again.
              </H6>
            )}
            <Button
              maxWidth="300px"
              my="40px"
              letterSpacing="0.04em"
              width="100%"
              height="44px"
              disabled={!areInputValuesValid(inputValues)}
              type="submit"
              onClick={setUserPin}
            >
              Continue
            </Button>
          </Flex>
        </Flex>
        <PrivacyFooter />
      </Flex>
    </FullscreenModal>
  );
};
