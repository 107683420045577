import { space, SpaceProps, color } from "styled-system";
import styled from "styled-components";
import media, { DefaultBreakpoints } from "styled-media-query";
import Flex from "app/components/Flex";
import LinkButton from "../Button/LinkButton";
import {
  LARGE_CUSTOM_BREAKPOINT,
  SMALL_MOBILE_CUSTOM_BREAKPOINT,
} from "./constants";
import Div from "../Div";

const StyledLinkButton = styled(LinkButton)`
  font-size: 12px;
  letter-spacing: 2px;
  padding: 16px 32px;
  height: 100%;
  border-radius: 0;
  font-weight: 500;
`;

interface LoginButtonProps extends SpaceProps {
  children: React.ReactNode;
  to: string;
}

export const LoginButton = styled(StyledLinkButton)<LoginButtonProps>`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.black};

  :hover {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.lightGrey};
  }
  ${space}
`;

interface SignUpButtonProps extends SpaceProps {
  children: React.ReactNode;
  to: string;
}

export const SignUpButton = styled(StyledLinkButton)<SignUpButtonProps>`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  ${space}
`;

export const Wrapper = styled.div<{ focus?: boolean }>`
  z-index: 99999 !important;
  border-bottom: 3px solid ${({ theme }) => theme.colors.lightGrey};
  background: ${({ theme }) => theme.colors.white};
  max-height: ${({ theme }) => theme.components.navbar.height}px;
  height: ${({ theme }) => theme.components.navbar.height}px;
  display: flex;
  justify-content: ${props => (props.focus ? "center" : "space-between")};
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 ${({ theme }) => theme.space[2]}px;
`;

export const Logo = styled.div`
  fill: ${({ theme }) => theme.colors.black};
  display: block;
  height: 100%;
`;

export const BannerWrapper = styled.div`
  display: flex;

  ${media.lessThan(LARGE_CUSTOM_BREAKPOINT as keyof DefaultBreakpoints)`
    flex: 1 1 25%;
    width: 25%;
  `}
`;

export const NavbarLogoWrapper = styled(Div)`
  height: 100%;

  ${media.lessThan(SMALL_MOBILE_CUSTOM_BREAKPOINT as keyof DefaultBreakpoints)`
    display: none;
  `}
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .ais-InstantSearch__root {
    ${media.greaterThan(LARGE_CUSTOM_BREAKPOINT as keyof DefaultBreakpoints)`
      max-width: 220px;
    `}
  }
`;

export const IconsWrapper = styled(Flex)`
  gap: 16px;
  ${color};

  transition: background-color 0.15s ease-in-out;
`;
