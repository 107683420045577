import React from "react";
import { connect, useSelector } from "react-redux";
import queryString from "query-string";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const UnauthenticatedRoute = ({ children, ...rest }) => {
  const query = queryString.parse(rest.location.search);
  const isAuthLoading = useSelector(({ auth }) => !auth.loaded);

  return (
    <Route
      {...rest}
      render={() =>
        !rest.isAnonymous && !isAuthLoading ? (
          <Redirect to={query.redirect || "/dashboard"} />
        ) : (
          <>{children}</>
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }) => ({
  isAnonymous: auth.isAnonymous,
});

UnauthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAnonymous: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(UnauthenticatedRoute);
