import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  communityContentFlag,
  CommunityContentFlagContentType,
  communityVideoActivity,
  Type,
} from "services/typewriter/segment";
import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/index";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import {
  Play,
  VolumeOn,
  VolumeOff,
  Pause,
  Heart,
  HeartFilled,
  Comments,
} from "app/components/Icon";
import { useRedirectNoAccess } from "hooks/Challenges";
import { useContentSelectClassEvent } from "hooks/analytics";
import ProgressBar from "./ProgressBar";
import BaseButton from "./BaseButton";
import Settings from "./Settings";

const ChildControls = ({
  challengeData,
  videoUploadData,
  classId,
  likesCount,
  commentsCount,
  playerProgressInSeconds,
  seekTo,
  durationOfVideo,
  playing,
  setPlaying,
  muted,
  setMuted,
  hidePlayBtn,
  hideSoundBtn,
  hideMenuBtn,
  hideCommentsBtn,
  hideLikesBtn,
  hideLearnBtn,
  isAuthoredByMe,
  videoId,
  onLike = () => {},
  didLike,
  onClickComment = () => {},
  thumbnailUrl,
}) => {
  const history = useHistory();
  const location = useLocation();
  const redirectNoAccess = useRedirectNoAccess();
  const { fromModule } = history.location?.state || {};
  const learnButtonCopy = "Learn";
  const { forClass = {} } = challengeData || {};
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData: forClass,
  });

  const onClickLike = async e => {
    e.stopPropagation();

    if (redirectNoAccess()) {
      return;
    }

    const likeStatus = await onLike();

    communityVideoActivity({
      type: likeStatus ? Type.Like : Type.Unlike,
      video_id: videoId,
      to_user_id: videoUploadData?.uploadedBy?.uid,
    });
  };

  const onClickLearn = e => {
    e.stopPropagation();
    if (classId) {
      contentSelectClassEvent({
        location: ANALYTICS_LOCATION.community,
        selected_from: fromModule || ANALYTICS_MODULE.challenge_post,
      });

      history.push({
        pathname: `/class/${classId}`,
        state: { previousLocation: location },
      });
    }
  };

  const onClickComments = e => {
    e.stopPropagation();
    onClickComment();
  };

  const onClickReport = reportReason => {
    communityContentFlag({
      element_name: "Community Feed - Report Post",
      location: ANALYTICS_LOCATION.community,
      module: fromModule || ANALYTICS_MODULE.challenge_post,
      content_id: videoId,
      content_user_id: videoUploadData?.uploadedBy?.uid,
      content_type: CommunityContentFlagContentType.UserVideo,
      challenge_id: challengeData?.id,
      challenge_active: !!challengeData?.isActive,
      reason: reportReason,
    });
  };

  return (
    <Flex position="absolute" height="100%" width="100%" bottom={0}>
      <Div
        position="absolute"
        height="100%"
        width="100%"
        bottom={0}
        backgroundColor="black"
        backgroundImage={thumbnailUrl ? `url('${thumbnailUrl}')` : ""}
        opacity={playing || playerProgressInSeconds > 0 ? 0 : 1}
        backgroundSize="cover"
        backgroundPosition="center"
      />
      <Flex
        position="absolute"
        height="70%"
        width="100%"
        bottom={0}
        backgroundImage="linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222 100%)"
        transition="bottom 0.25s ease-in-out"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Flex width="100%" justifyContent="flex-end">
          <Flex flexDirection="column" mr={2}>
            <BaseButton
              show={!hideLearnBtn}
              Icon={Play}
              text={learnButtonCopy}
              onClick={onClickLearn}
            />
            <BaseButton
              show={!hideLikesBtn}
              Icon={didLike ? HeartFilled : Heart}
              text={likesCount}
              onClick={onClickLike}
            />
            <BaseButton
              show={!hideCommentsBtn}
              Icon={Comments}
              text={commentsCount}
              onClick={onClickComments}
            />
            {!hideMenuBtn && (
              <Settings
                videoId={videoId}
                isAuthoredByMe={isAuthoredByMe}
                onClickReport={onClickReport}
              />
            )}
          </Flex>
        </Flex>
        <Flex width="100%" mb={3} justifyContent="center" alignItems="center">
          <BaseButton
            show={!hidePlayBtn}
            Icon={playing ? Pause : Play}
            onClick={e => {
              e.stopPropagation();
              setPlaying(!playing);
            }}
            iconWidth={14}
            ml={2}
            mb={1}
          />
          <Flex
            width="100%"
            ml={hidePlayBtn && 3}
            mr={hideSoundBtn && 3}
            mb={2}
          >
            <ProgressBar
              disableLooping
              hideDuration
              currentTimeInSeconds={playerProgressInSeconds}
              onAfterChange={seekTo}
              max={durationOfVideo}
              totalDuration={durationOfVideo}
            />
          </Flex>
          <BaseButton
            show={!hideSoundBtn}
            Icon={muted ? VolumeOff : VolumeOn}
            onClick={e => {
              e.stopPropagation();
              setMuted(!muted);
            }}
            mb={1}
            mr={2}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

ChildControls.propTypes = {
  isAuthoredByMe: PropTypes.bool.isRequired,
  videoId: PropTypes.string.isRequired,
  challengeData: PropTypes.shape({}).isRequired,
  videoUploadData: PropTypes.shape({}).isRequired,
  classId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  likesCount: PropTypes.number,
  commentsCount: PropTypes.number,
  playing: PropTypes.bool,
  setPlaying: PropTypes.func,
  muted: PropTypes.bool,
  setMuted: PropTypes.func,
  hidePlayBtn: PropTypes.bool,
  hideSoundBtn: PropTypes.bool,
  hideMenuBtn: PropTypes.bool,
  hideCommentsBtn: PropTypes.bool,
  hideLikesBtn: PropTypes.bool,
  hideLearnBtn: PropTypes.bool,
  playerProgressInSeconds: PropTypes.number,
  seekTo: PropTypes.func,
  durationOfVideo: PropTypes.number,
  onLike: PropTypes.func,
  didLike: PropTypes.bool,
  onClickComment: PropTypes.func,
};

export default ChildControls;
