import { isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import { GET_HAS_COMMUNITY_ACCESS } from "graphql/queries";
import { useFeatureFlags, usePublicCommunityFlag } from "hooks/useFeatureFlags";

export default ({ options = {} } = {}) => {
  const { loadingFeatureFlags } = useFeatureFlags();
  const isCommunityFeatureEnabled = usePublicCommunityFlag();
  const {
    data = { me: {} },
    loading: loadingCommunityAccess,
    error,
  } = useQuery(GET_HAS_COMMUNITY_ACCESS, options);
  const isLoading = loadingCommunityAccess || loadingFeatureFlags;

  const userData = data?.me;

  if (isEmpty(userData)) {
    return [false, isLoading, error];
  }

  if (isCommunityFeatureEnabled) {
    return [true, isLoading, error];
  }

  const { hasCommunityAccess } = userData;

  return [hasCommunityAccess, isLoading, error];
};
