import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const useRedirectNoAccess = () => {
  const history = useHistory();

  const isAnonymous = useSelector(({ auth }) => auth.isAnonymous);

  return () => {
    if (isAnonymous) {
      history.push(`/login?redirect=${history.location?.pathname}`);
      return true;
    }

    return false;
  };
};

export default useRedirectNoAccess;
