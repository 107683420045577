import env from "helpers/env";
import algoliasearch from "algoliasearch";
import { AccessType } from "services/graphql";

export const searchClient = algoliasearch(
  env("PUBLIC_ALGOLIA_SECRET_KEY"),
  env("PUBLIC_ALGOLIA_API_KEY")
);

// Allowed to show all classes for Standard and Premium
export const USER_ACCESS_TYPE_TO_FILTER_MAP: {
  [key in AccessType]: AccessType[];
} = {
  [AccessType.Free]: [AccessType.Free],
  [AccessType.Basic]: [AccessType.Basic, AccessType.Free],
  [AccessType.Standard]: [],
  [AccessType.Premium]: [],
};

export const UserAccessTypeToAlgoliaFilterMap = new Map<
  AccessType,
  AccessType[]
>([
  [AccessType.Free, [AccessType.Free]],
  [AccessType.Basic, [AccessType.Free, AccessType.Basic]],
  [AccessType.Standard, []],
  [AccessType.Premium, []],
]);
