/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Thumbnail from "app/components/Thumbnail";
import { LimitLineText, P1, H3 } from "app/components/Typography";
import Text from "app/components/Text";
import Modal from "app/components/Modal";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import { toggleAddClassModalAction } from "modules/components";
import styled from "styled-components";
import IconButton from "app/components/Button/IconButton";
import Icon, { ScheduleAdd, Close } from "app/components/Icon";
import Calendar from "app/components/Calendar";
import { GET_CALENDAR_DATA_QUERY, GET_PLAYLIST_QUERY } from "graphql/queries";
import {
  ADD_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION,
  CREATE_SCHEDULE_PLAYLIST_MUTATION,
} from "graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import Button from "app/components/Button";
import { ScreenLtLg } from "app/components/MediaQueries";
import { DATE_FORMAT_FOR_SCHEDULES } from "constants/index";
import env from "helpers/env";
import ClassRow from "../ClassRow";

const StyledModal = styled(Modal)`
  max-width: 100vw;
  max-height: 90vh;
  padding: 0;
  overflow: auto;
`;

const AddClassModal = ({
  classDataToAdd,
  toggleAddClassModal,
  isOpen,
  classId,
  programClassRefId,
  ...props
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const formattedDate = moment(currentDate).format("YYYY-MM-DD");
  const isPastDate = moment(currentDate)
    .startOf("day")
    .isBefore(moment().startOf("day"));

  // QUERIES
  const {
    loading: getPlaylistQueryLoading,
    data,
    refetch: refetchGetPlaylistQuery,
  } = useQuery(GET_PLAYLIST_QUERY, {
    variables: {
      date: formattedDate,
    },
    errorPolicy: "ignore",
  });

  const {
    data: calendarDataQueryData,
    refetch: refetchCalendarData,
  } = useQuery(GET_CALENDAR_DATA_QUERY, {
    variables: {
      startDate: moment(currentDate)
        .startOf("month")
        .format(DATE_FORMAT_FOR_SCHEDULES),
      endDate: moment(currentDate)
        .endOf("month")
        .format(DATE_FORMAT_FOR_SCHEDULES),
    },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
  });
  const { calendarData } = calendarDataQueryData || {};

  const refetchQueries = () => {
    refetchGetPlaylistQuery();
    refetchCalendarData();
  };

  // MUTATIONS
  const [
    createScheduleMutation,
    { loading: createScheduleMutationLoading },
  ] = useMutation(CREATE_SCHEDULE_PLAYLIST_MUTATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [
    addClassesMutation,
    { loading: addClassesMutationLoading },
  ] = useMutation(ADD_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const { playlist } = data || {};
  const isClassOnPlaylist = playlist?.classes?.find(
    c => parseInt(c?.id) === parseInt(classDataToAdd?.id)
  );
  const loading =
    getPlaylistQueryLoading ||
    createScheduleMutationLoading ||
    addClassesMutationLoading;

  const createOrAddClassesToSchedule = classIds => {
    if (playlist) {
      addClassesMutation({
        variables: {
          id: playlist.id,
          classIds,
          fromModule: "Class",
        },
      });
    } else {
      createScheduleMutation({
        variables: {
          date: formattedDate,
          classIds,
          fromModule: "Class",
        },
      });
    }
  };

  useEffect(() => {
    // Since useQuery is only called on mount, we want to refetch it when the modal opens
    if (isOpen) {
      refetchQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <StyledModal isOpen={isOpen} width={{ _: "400px", lg: "800px" }} {...props}>
      <Flex
        width="100%"
        height="85px"
        bg="black"
        overflow="hidden"
        borderRadius="2px"
      >
        <Thumbnail
          height="100%"
          width="auto"
          src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
            classDataToAdd?.slug
          }-basic.jpg?w=200`}
          placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
            classDataToAdd?.slug
          }-basic.jpg?blur=200&px=16&auto=format`}
        />
        <Flex p={3} justifyContent="space-between" width="100%">
          <Flex flexDirection="column" justifyContent="center">
            <LimitLineText
              fontSize="12px"
              fontWeight="bold"
              color="white"
              textTransform="uppercase"
              letterSpacing="1.2px"
            >
              {classDataToAdd?.title}
            </LimitLineText>
            {(classDataToAdd?.instructor_name ||
              classDataToAdd?.instructor) && (
              <Text fontSize="12px" color="white">
                {/* Algolia returns instructor_name which we're using to emulate quicker response */}
                {classDataToAdd?.instructor_name ||
                  classDataToAdd?.instructor.name}
              </Text>
            )}
          </Flex>
          <IconButton
            Icon={Close}
            width="20px"
            height="auto"
            color="white"
            onClick={() => toggleAddClassModal(null)}
          />
        </Flex>
      </Flex>
      <Flex p={{ _: 3, xl: 4 }} flexDirection="column">
        <Flex flexDirection={{ _: "column", lg: "row" }} mb={3}>
          <Flex
            width={{ _: "100%", lg: "50%" }}
            flexDirection="column"
            alignItems="center"
          >
            <Flex flexDirection="column" width="100%">
              <Calendar
                textColor="black"
                currentDate={currentDate}
                onSelectDate={setCurrentDate}
                indicatedDays={calendarData?.userCreatedPlaylists?.reduce(
                  (acc, createdPlaylist) => {
                    if (
                      !moment().isBefore(createdPlaylist.date) ||
                      createdPlaylist.classes?.length < 1
                    ) {
                      return acc;
                    }

                    return [
                      ...acc,
                      parseInt(moment(createdPlaylist.date).format("D")),
                    ];
                  },
                  []
                )}
              />
            </Flex>
            <P1
              textAlign="center"
              mb={3}
              mx={3}
              display={{ _: "none", lg: "block" }}
            >
              Please select a day that you would like to add this class into.
            </P1>
          </Flex>
          <Div
            width={{ _: "100%", lg: "2px" }}
            minWidth={{ lg: "2px" }}
            height={{ _: "2px", lg: "inherit" }}
            mx={{ lg: 4 }}
            my={{ _: 4, lg: 0 }}
            bg="monochrome.2"
          />
          <Flex
            width={{ _: "100%", lg: "50%" }}
            height="100%"
            minHeight="300px"
            flexDirection="column"
            overflow="hidden"
          >
            <ScreenLtLg>
              {isScreenLtLg => {
                if (isScreenLtLg && !playlist) {
                  return (
                    <Flex
                      height="100%"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <P1 textAlign="center" mb={3} mx={3}>
                        Please select a day that you would like to add this
                        class into.
                      </P1>
                    </Flex>
                  );
                }
                return (
                  <>
                    <H3 mb={3}>
                      Classes on: {moment(currentDate).format("M/DD/YYYY")}
                    </H3>
                    <Div
                      flex={1}
                      position="relative"
                      width="100%"
                      height="100%"
                    >
                      <Div height="100%" width="100%" overflow="auto">
                        {playlist &&
                          playlist.classes.map(classData => (
                            <Flex
                              width="100%"
                              height="60px"
                              bg="black"
                              borderRadius="5px"
                              mb={2}
                              key={classData.id}
                            >
                              <ClassRow classData={classData} />
                            </Flex>
                          ))}
                      </Div>
                    </Div>
                  </>
                );
              }}
            </ScreenLtLg>
          </Flex>
        </Flex>
        {!isPastDate && (
          <Button
            width="100%"
            disabled={isClassOnPlaylist || loading}
            mt={3}
            onClick={() =>
              createOrAddClassesToSchedule([
                { id: String(classId), refId: programClassRefId },
              ])
            }
          >
            {!isClassOnPlaylist && (
              <Icon height="20px" width="auto" as={ScheduleAdd} mr={3} />
            )}
            {isClassOnPlaylist ? "Already in Schedule" : "Add to Schedule"}
          </Button>
        )}
      </Flex>
    </StyledModal>
  );
};

const mapStateToProps = ({ firebase }, { classId }) => ({
  classDataToAdd: getVal(firebase, `data/classes_details/${classId}`),
});

const mapDispatchToProps = {
  toggleAddClassModal: toggleAddClassModalAction,
};

AddClassModal.defaultProps = {
  classData: null,
  classDataToAdd: null,
  isOpen: false,
};

AddClassModal.propTypes = {
  classData: PropTypes.shape({}),
  classDataToAdd: PropTypes.shape({}),
  toggleAddClassModal: PropTypes.func.isRequired,
  classId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  programClassRefId: PropTypes.string,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(({ classId }) => [`classes_details/${classId}`])
)(AddClassModal);
