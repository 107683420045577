import React, { useRef } from "react";
import { ScreenGtMd } from "app/components/MediaQueries";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import {
  shrinkOrExpandSideNavbarAction,
  toggleSearchBarAction,
} from "modules/components";
import Flex from "app/components/Flex";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { useLocation, useHistory } from "react-router-dom";
import { useAccountMode } from "hooks/useAccountMode";
import { LARGE_CUSTOM_BREAKPOINT } from "./constants";
import { DropdownAvatar } from "./DropdownAvatar";
import SubscribeButton from "./SubscribeButton";
import SearchBar from "../SearchBar";
import {
  Close,
  Menu,
  Search,
  STEEZYLogo,
  STEEZYFamilyHorizontal,
} from "../Icon";
import Focus from "./Focus";
import Div from "../Div";
import {
  SignUpButton,
  LoginButton,
  Wrapper,
  Logo,
  BannerWrapper,
  NavbarLogoWrapper,
  SearchBarWrapper,
  IconsWrapper,
} from "./styles";
import IconButton from "../Button/IconButton";
import { Notifications } from "./Notifications";

const CENTER_LOGO_ROUTES = [
  "/login",
  "/checkout",
  "/register",
  "/password-reset",
];

interface Props {
  forceFocus?: boolean;
  isAnonymous?: boolean;
  loaded?: boolean;
}

export const Navbar = ({
  forceFocus = false,
  isAnonymous = true,
  loaded = false,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const subscription = useSelector(({ user }: any) => user.subscription);
  const showSearchBar = useSelector(
    ({ components }: any) => components.SearchBar.toggled
  );
  const isSideNavbarExpanded = useSelector(
    ({ components }: any) => components.SideNavbar.expanded
  );
  const { isFamilyModeOn } = useFamilyModeSetting();
  const { accountMode } = useAccountMode();

  const navbarRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const goToLink = ({ path, component }: any) => {
    if (!path) {
      return;
    }

    window.analytics.track("Click - Link", {
      destination: path,
      in_component: component || "Navbar",
      path: location?.pathname,
    });

    history.push(path);
  };

  const focus = CENTER_LOGO_ROUTES.includes(location?.pathname) || forceFocus;

  if (focus) {
    return (
      <Wrapper ref={navbarRef} {...props} focus>
        <Focus />
      </Wrapper>
    );
  }

  return (
    <Wrapper ref={navbarRef} {...props}>
      <BannerWrapper>
        {loaded && !isFamilyModeOn && (
          <IconButton
            mx={3}
            width="20px"
            Icon={Menu}
            onClick={() =>
              dispatch(shrinkOrExpandSideNavbarAction(!isSideNavbarExpanded))
            }
          />
        )}
        <Div
          // @TODO: Updated styling when Family logo gets finalized (https://stzy.atlassian.net/browse/CORE-2265)
          mx={{ _: "8px", lg: "16px" }}
          my={isFamilyModeOn ? "14px" : "20px"}
        >
          <NavbarLogoWrapper
            cursor={loaded ? "pointer" : "default"}
            onClick={() =>
              goToLink({
                component: "NavbarLogo",
                path: "/dashboard",
              })
            }
          >
            <Logo as={isFamilyModeOn ? STEEZYFamilyHorizontal : STEEZYLogo} />
          </NavbarLogoWrapper>
        </Div>
      </BannerWrapper>
      {loaded && (
        <Flex>
          <MediaQuery minWidth={LARGE_CUSTOM_BREAKPOINT}>
            {windowLargerThanBreakpoint => (
              <IconsWrapper pr="24px">
                {!isFamilyModeOn && (
                  <>
                    <SearchBarWrapper>
                      <SearchBar useMobile={!windowLargerThanBreakpoint} />
                    </SearchBarWrapper>
                    {showSearchBar && !windowLargerThanBreakpoint && (
                      <IconButton
                        width="40px"
                        Icon={Close}
                        color="black"
                        onClick={() =>
                          dispatch(
                            toggleSearchBarAction({
                              toggle: false,
                              accountMode,
                            })
                          )
                        }
                      />
                    )}
                    {!showSearchBar && (
                      <IconButton
                        width="40px"
                        Icon={Search}
                        color="black"
                        onClick={() =>
                          dispatch(
                            toggleSearchBarAction({ toggle: true, accountMode })
                          )
                        }
                      />
                    )}
                  </>
                )}

                <Flex alignItems="center">
                  <Notifications />
                </Flex>

                <Flex alignItems="center">
                  <DropdownAvatar />
                </Flex>
              </IconsWrapper>
            )}
          </MediaQuery>
          {!isAnonymous &&
            loaded &&
            !subscription.activeSubscription &&
            !subscription.isPaused && <SubscribeButton />}
          {isAnonymous && (
            // Is shown on side nav if screen less than MD
            <ScreenGtMd>
              <Flex justifyContent="flex-end">
                <LoginButton py={[2, 3]} px={[3, 4]} to="/login">
                  Log In
                </LoginButton>
                <SignUpButton py={[2, 3]} px={[3, 4]} to="/register">
                  Sign Up
                </SignUpButton>
              </Flex>
            </ScreenGtMd>
          )}
        </Flex>
      )}
    </Wrapper>
  );
};
