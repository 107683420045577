import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { layout, flexbox, variant, space } from "styled-system";
import { md } from "styles/variables";
import { Alert as AlertIcon, Check, Info } from "app/components/Icon";

const alertStyle = variant({
  key: "alerts",
});

const CloseButton = styled.span`
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  right: 8px;
  color: ${props =>
    props.theme.alerts[props.variant] &&
    props.theme.alerts[props.variant].color};
`;

const Wrapper = styled.div`
  margin: ${md} 0;
  border-radius: 2px;
  padding: 8px 48px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary[0]};
  position: relative;
  line-height: 24px;
  ${alertStyle}
  ${layout}
  ${flexbox}
  ${space}
`;

const Icon = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  left: 8px;
`;

const icons = {
  danger: AlertIcon,
  warning: AlertIcon,
  success: Check,
  primary: Info,
};

const Alert = ({ children, closeAlert = () => {}, ...rest }) => (
  <Wrapper {...rest}>
    <Icon as={icons[rest.variant]} />
    {closeAlert && (
      <CloseButton variant={rest.variant} onClick={closeAlert}>
        &times;
      </CloseButton>
    )}
    {children}
  </Wrapper>
);

Alert.defaultProps = {
  closeAlert: null,
  variant: "primary",
};

Alert.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeAlert: PropTypes.func,
};

export default Alert;
