export default (timeSeconds, { discrete = false, showSeconds = true } = {}) => {
  const hours = Math.floor(timeSeconds / 3600);
  let minutes = Math.floor((timeSeconds % 3600) / 60);
  let seconds = Math.floor(timeSeconds % 60);
  let timeString;

  if (discrete) {
    const hourString = hours > 1 ? `${hours} Hrs` : `${hours} Hr`;
    const minuteString = minutes > 1 ? `${minutes} Mins` : `${minutes} Min`;
    const secondString = seconds > 1 ? `${seconds} Secs` : `${seconds} Sec`;

    if (hours === 0) {
      if (minutes === 0) {
        timeString = secondString;
      } else {
        timeString = minuteString;
      }
    } else if (minutes) {
      timeString = `${hourString} ${minuteString}`;
    } else {
      timeString = hourString;
    }

    if (showSeconds && minutes !== 0) {
      timeString = `${timeString} ${secondString}`;
    }
  } else {
    if (minutes < 10 && hours > 0) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    timeString = hours === 0 ? `${minutes}` : `${hours}:${minutes}`;

    if (showSeconds) {
      timeString = `${timeString}:${seconds}`;
    }
  }

  return timeString;
};
