import React from "react";
import PropTypes from "prop-types";
import Grid from "app/components/Grid";
import SubmissionsItem from "./SubmissionsItem";

const ChallengeSubmissionsList = ({ challenge, onOpenModal = () => {} }) => {
  const { submissionsCount = 0, videos: { edges = [] } = {} } = challenge;

  const openModal = ({ id }) => {
    onOpenModal(id);
  };

  if (!challenge) {
    return <></>;
  }

  const submissions = submissionsCount > 5 ? edges.slice(0, 5) : edges;

  return (
    <Grid
      width="100%"
      justifyContent="left"
      gridGap="11px"
      gridTemplateColumns="repeat(5, minMax(63px, 1fr))"
    >
      {submissions.map(({ node }) => (
        <SubmissionsItem submission={node} openModal={openModal} />
      ))}
    </Grid>
  );
};

ChallengeSubmissionsList.propTypes = {
  onOpenModal: PropTypes.func,
};

export default ChallengeSubmissionsList;
