export const DATA_TEST_ID = {
  onboarding: {
    category: "onboarding-category",
    categorySelectedIcon: "onboarding-category-selected-icon",
    createAccountWrapper: "onboarding-create-account-wrapper",
    continue: "onboarding-continue",
    optionSlug: slug => `onboarding-option-slug-${slug}`,
    optionTitle: "onboarding-option-title",
    optionWrapper: "onboarding-option-wrapper",
    pricingCard: "onboarding-pricing-card",
    wrapper: "onboarding-wrapper",
  },
};

export default DATA_TEST_ID;
