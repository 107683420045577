import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import { persistStore } from "redux-persist";
import client from "graphql/client";
import isServer from "helpers/isServer";
import env from "helpers/env";
import createRootReducer from "./modules";
import preserveUrlHash from "./helpers/preserveUrlHash";

export default ({
  url = "/",
  initialState = {},
  firebase,
  apolloClient = client,
}) => {
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory();

  if (env("PUBLIC_ENV") === "testing") {
    preserveUrlHash(history, "debug");
  }

  const enhancers = [];

  // Dev tools are helpful
  if (env("NODE_ENV") === "development" && !isServer) {
    const { devToolsExtension } = window;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const getFirebase = () => firebase;

  const middleware = [
    thunk.withExtraArgument({ getFirebase, apolloClient }),
    routerMiddleware(history),
  ];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Create the store
  const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  );

  const persistor = persistStore(store);

  return {
    history,
    persistor,
    store,
  };
};
