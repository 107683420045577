import React, { useState } from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { LimitLineText } from "app/components/Typography";
import env from "helpers/env";
import { PlayCircle } from "app/components/Icon";
import styled from "styled-components";

const THUMBNAIL_WIDTH = 106;
const THUMBNAIL_HEIGHT = 60;
const THUMBNAIL_RATIO = THUMBNAIL_HEIGHT / THUMBNAIL_WIDTH;

const ThumbnailSizeContainer = styled.div`
  width: ${THUMBNAIL_WIDTH}px;
`;

const ThumbnailContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: ${THUMBNAIL_RATIO * 100}%;
  position: relative;
`;

const ThumbnailWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const ClassRow = ({
  classData,
  onClick,
  ChildrenComponent,
  textColor,
  ...rest
}) => {
  const [isHovering, setIsHovering] = useState(false);
  /* classData can be from Algolia (snake_case) or GraphQL (camelCase) */
  const instructorName =
    classData.instructor_name ||
    classData.instructorName ||
    classData?.instructor?.name;
  return (
    <Flex
      width="100%"
      height={`${THUMBNAIL_HEIGHT}px`}
      overflow="hidden"
      borderRadius="2px"
      onClick={onClick}
      onMouseEnter={() => onClick && setIsHovering(true)}
      onMouseLeave={() => onClick && setIsHovering(false)}
      {...rest}
    >
      <ThumbnailSizeContainer>
        <ThumbnailContainer>
          <ThumbnailWrapper>
            <Thumbnail
              src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                classData.thumbnail
              }-basic.jpg?w=200`}
              placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                classData.thumbnail
              }-basic.jpg?blur=200&px=16&auto=format`}
            />
          </ThumbnailWrapper>
          {isHovering && (
            <Flex
              position="absolute"
              top={0}
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
              bg="rgb(black, 0.5)"
            >
              <Flex height="30px" width="30px">
                <PlayCircle color="white" />
              </Flex>
            </Flex>
          )}
        </ThumbnailContainer>
      </ThumbnailSizeContainer>
      <Flex flex={1} p={2} flexDirection="column" justifyContent="center">
        <LimitLineText
          fontSize="12px"
          fontWeight="bold"
          color={textColor}
          textTransform="uppercase"
          letterSpacing="1.2px"
        >
          {classData.title}
        </LimitLineText>
        {instructorName && (
          <LimitLineText fontSize="12px" color={textColor}>
            {instructorName} | {classData.duration}
          </LimitLineText>
        )}
      </Flex>
      {ChildrenComponent && <ChildrenComponent />}
    </Flex>
  );
};

ClassRow.defaultProps = {
  bg: "black",
  onRemove: null,
  textColor: "white",
};

ClassRow.propTypes = {
  bg: PropTypes.string,
  classData: PropTypes.shape({}).isRequired,
  onRemove: PropTypes.func,
  textColor: PropTypes.string,
};

export default ClassRow;
