import React, { createContext } from "react";
import PropTypes from "prop-types";

export const AlgoliaWidgetsCollectorContext = createContext(null);

export function AlgoliaWidgetsCollectorProvider({
  children,
  widgetsCollector,
}) {
  return (
    <AlgoliaWidgetsCollectorContext.Provider value={widgetsCollector}>
      {children}
    </AlgoliaWidgetsCollectorContext.Provider>
  );
}

AlgoliaWidgetsCollectorProvider.propTypes = {
  children: PropTypes.element,
  widgetsCollector: PropTypes.func,
};

export default AlgoliaWidgetsCollectorProvider;
