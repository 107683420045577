import React from "react";
import PropTypes from "prop-types";
import { useLocation, matchPath } from "react-router";
import SideNavLink from "./SideNavLink";

const FollowedSideNavLink = ({
  active,
  children,
  goToLink,
  path,
  renderDropdownIcon,
  ...rest
}) => {
  const location = useLocation();
  const isActive = active || matchPath(location.pathname, { path });

  return (
    <SideNavLink
      onClick={() => path && goToLink && goToLink({ path })}
      bg={isActive ? "monochrome.7" : "none"}
      color={isActive ? "white" : "black"}
      hoverBg={!isActive && "monochrome.2"}
      {...rest}
    >
      {children}
      {renderDropdownIcon && renderDropdownIcon({ isActive })}
    </SideNavLink>
  );
};

FollowedSideNavLink.defaultProps = {
  active: false,
  goToLink: null,
  path: null,
  renderDropdownIcon: null,
};

FollowedSideNavLink.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  goToLink: PropTypes.func,
  path: PropTypes.string,
  renderDropdownIcon: PropTypes.func,
};

export default FollowedSideNavLink;
