import { gql } from "@apollo/client";
import { ActivityCommentFieldsFragmentDoc } from "services/graphql";
import { CHALLENGE_FIELDS, CORE_VIDEO_UPLOAD_FIELDS } from "./fragments";

/**
 * https://stzy.atlassian.net/browse/CORE-1010
 *
 * Cannot pass args to fragments & need to pass `(first: 10)` to comments to appease the cache.
 * Revisit after Challenges launch please.
 */
const GET_CHALLENGE_BY_ID_QUERY = gql`
  ${CORE_VIDEO_UPLOAD_FIELDS}
  ${ActivityCommentFieldsFragmentDoc}
  ${CHALLENGE_FIELDS}
  query GetChallengeById($id: String!, $first: Float) {
    challengeById(id: $id) {
      ...ChallengeFields
      comments(first: 10) {
        edges {
          node {
            ...ActivityCommentFields
          }
          cursor
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      videos(first: $first) {
        edges {
          cursor
          node {
            ...CoreVideoUploadFields
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

const VIDEO_UPLOAD_BY_ID_PUBLIC = gql`
  ${CORE_VIDEO_UPLOAD_FIELDS}
  query VideoUploadByIdPublic($videoId: String!) {
    videoUploadByIdPublic(input: { id: $videoId }) {
      ...CoreVideoUploadFields
    }
  }
`;

export { GET_CHALLENGE_BY_ID_QUERY, VIDEO_UPLOAD_BY_ID_PUBLIC };
