import breakpoints from "./breakpoints";

// styled-system uses min-width to check for inline props
// https://github.com/styled-system/styled-system/blob/master/docs/responsive-styles.md
// subtracting 1 from lt allows use to differentiate between gt and lt queries

export default {
  ltsm: `@media (max-width: ${parseInt(breakpoints.sm) - 1}px)`,
  sm: `@media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md -
    1})`,
  gtsm: `@media (min-width: ${breakpoints.sm})`,
  ltmd: `@media (max-width: ${parseInt(breakpoints.md) - 1}px)`,
  md: `@media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg -
    1})`,
  gtmd: `@media (min-width: ${breakpoints.md})`,
  ltlg: `@media (max-width: ${parseInt(breakpoints.lg) - 1}px)`,
  lg: `@media (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl -
    1})`,
  gtlg: `@media (min-width: ${breakpoints.lg})`,
  ltxl: `@media (max-width: ${parseInt(breakpoints.xl) - 1}px)`,
  xl: `@media (min-width: ${breakpoints.xl})`,
};
