import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import LoaderCentered from "../../Loader/LoaderCentered";

const AuthenticatedRoute = ({ children, ...rest }) => {
  const [isLoading, isAnonymous] = useSelector(({ auth }) => [
    auth.loaded === false,
    auth.isAnonymous,
  ]);
  if (isLoading) {
    return <LoaderCentered />;
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAnonymous ? (
          <Redirect to={`/login?redirect=${props.location.pathname}`} />
        ) : (
          <>{children}</>
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  location: ReactRouterPropTypes.location,
};

export default AuthenticatedRoute;
