import { transparentize } from "polished";
import colors from "./colors";

export default {
  primary: {
    color: colors.primary[0],
    background: transparentize(0.9, colors.primary[0]),
  },
  success: {
    color: colors.success,
    background: transparentize(0.9, colors.success),
  },
  danger: {
    color: colors.danger,
    background: transparentize(0.9, colors.danger),
    borderColor: colors.danger,
  },
  warning: {
    color: colors.warning,
    background: transparentize(0.9, colors.warning),
    borderColor: colors.warning,
  },
};
