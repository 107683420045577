import styled from "styled-components";
import Div from "app/components/Div";

export const Avatar = styled(Div).attrs({
  borderRadius: "50%",
  overflow: "hidden",
  width: "120px",
  height: "120px",
})`
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%), 0px 4px 26px rgb(0 0 0 / 15%);
`;
