import { createGlobalStyle } from "styled-components";
import { IS_MOBILE } from "./constants";
import styleOverrides from "./style-overrides";

export default createGlobalStyle`
  @font-face {
    font-family: "BjolaFixed";
    src: local("BjolaFixed"), local("BjolaFixed"),
    url("Bjola.woff2") format("woff2"),
    url("Bjola.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey};
  }

  * {
    font-family: inherit;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    max-height: 100vh;
    height: 100%;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    ${
      IS_MOBILE &&
        "cursor: pointer;" /* https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event#Safari_Mobile */
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  #root {
    height: 100%;
  }

  a {
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary[0]};

    &:hover {
      color: ${({ theme }) => theme.colors.primary[0]};
      text-decoration-color: ${({ theme }) => theme.colors.primary[0]};
    }

    &:focus {
      outline: none;
    }
  }

  ul {
    &.list {
      li {
        list-style-type: disc;
      }
    }
  }

  li {
    list-style-type: none;
  }

  button {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  textarea {
    outline: none;
    border: 1px solid #E5E5E5;
    padding: 10px;
    border-radius: 2px;
  }

  ${styleOverrides}
`;
