import React from "react";
import MediaQuery from "react-responsive";
import breakpoints from "theme/breakpoints";

// styled-system uses min-width to check for inline props
// https://github.com/styled-system/styled-system/blob/master/docs/responsive-styles.md
// subtracting 1 from Lt allows use to differentiate between Gt and Lt queries

export const ScreenXl = props => (
  <MediaQuery {...props} minWidth={breakpoints.xl} />
);
export const ScreenLtXl = props => (
  <MediaQuery {...props} maxWidth={parseInt(breakpoints.xl) - 1} />
);
export const ScreenGtLg = props => (
  <MediaQuery {...props} minWidth={breakpoints.lg} />
);
export const ScreenLg = props => (
  <MediaQuery
    {...props}
    minWidth={breakpoints.lg}
    maxWidth={parseInt(breakpoints.xl) - 1}
  />
);
export const ScreenLtLg = props => (
  <MediaQuery {...props} maxWidth={parseInt(breakpoints.lg) - 1} />
);
export const ScreenGtMd = props => (
  <MediaQuery {...props} minWidth={breakpoints.md} />
);
export const ScreenMd = props => (
  <MediaQuery
    {...props}
    minWidth={breakpoints.md}
    maxWidth={parseInt(breakpoints.lg) - 1}
  />
);
export const ScreenLtMd = props => (
  <MediaQuery {...props} maxWidth={parseInt(breakpoints.md) - 1} />
);
export const ScreenGtSm = props => (
  <MediaQuery {...props} minWidth={breakpoints.sm} />
);
export const ScreenSm = props => (
  <MediaQuery
    {...props}
    minWidth={breakpoints.sm}
    maxWidth={parseInt(breakpoints.md) - 1}
  />
);
export const ScreenLtSm = props => (
  <MediaQuery {...props} maxWidth={parseInt(breakpoints.sm) - 1} />
);
