export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const setErrorAction = error => ({
  type: SET_ERROR,
  error,
});

export const hideErrorAction = () => ({
  type: HIDE_ERROR,
});

const initialState = null;

export default (state = initialState, action) => {
  const { error } = action;

  // Not switching on a specific action.type allows the error reducer to capture errors across different actions as long as an error is provided
  if (error) {
    return error;
  }
  if (action.type === HIDE_ERROR) {
    return null;
  }

  return state;
};
