import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Icon, { VisibleOff } from "app/components/Icon";

const ReportOverlay = ({ children, iconWidth, iconHeight, ...props }) => (
  <Flex
    position="absolute"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    bg="rgba(35, 35, 35, 0.7)"
    backdropFilter="blur(30px)"
    {...props}
  >
    <Icon as={VisibleOff} width={iconWidth} height={iconHeight} />
    {children}
  </Flex>
);

ReportOverlay.defaultProps = {
  children: null,
  iconWidth: null,
  iconHeight: null,
};

ReportOverlay.propTypes = {
  children: PropTypes.node,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
};

export default ReportOverlay;
