/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CORE_SUBSCRIPTION_FIELDS = gql`
  fragment CoreSubscriptionFields on Subscription {
    id
    isActive
    isCancelable
    isReactivatableV2
    isPauseScheduled
    isPastDue
    nextBillingAt
    status
    trialEndTime
    currentTermEnd
    cancelationTime
    pauseTime
    resumeTime
    provider {
      id
    }
  }
`;

export const CORE_PROFILE_FIELDS = gql`
  fragment CoreProfileFields on User {
    id
    email
    username
    photoURL
  }
`;

export const CORE_PLAN_FIELDS = gql`
  fragment CorePlanFields on Plan {
    id
    name
    accessType
  }
`;

export const CLASS_FIELDS = gql`
  fragment ClassFields on Class {
    id
    studioClassId
    refId
    accessType
    progress {
      completed
      started
      time {
        hour
        minute
        second
      }
      programClassProgress {
        programSlug
        isCompleted
      }
    }
    instructor {
      name
      slug
    }
    slug
    title
    isFree
    isUnlocked
    isFamilyFriendly
    isPreviewVideoMuted
    externalPreviewUrl
    duration
    duration_in_seconds
    level
    preview_url
    publish_date
    style
    type
    thumbnail
    isSaved

    mutedCuepoints {
      endsAtTime
      startsAtTime
      externalUrl
      endsAtTimeSec
      startsAtTimeSec
    }
  }
`;

export const CORE_PROGRAM_FIELDS = gql`
  fragment CoreProgramFields on ProgramV2 {
    slug
    title
    level
    style
    totalSectionCount
    totalClassesCount
    averageSessionTime
    publishDate
    categories
    isSaved
    isFree
    content {
      assets {
        thumbnailURL
        previewVideoURL
      }
    }
  }
`;

export const PROGRAM_BLOCK_FIELDS = gql`
  ${CLASS_FIELDS}
  fragment ProgramBlockFields on ProgramBlock {
    refId
    progress {
      isCompleted
      sectionsCompletedCount
      classesCompletedPercent
    }
    sectionsCount
    sortIndex
    superscript
    title
    sections {
      refId
      progress {
        isCompleted
      }
      sortIndex
      title
      durationInMins
      classesCount
      classes {
        ...ClassFields
      }
    }
  }
`;

export const TRACKS_CORE_FIELDS = gql`
  fragment TracksCoreFields on Track {
    id
    createdAt
    updatedAt
    trackId
    songId
    isrc
    title
    artists
    label
    albumName
    copyright
    releaseDate
    image
    genre
    isExplicit
    youtube
    spotify
    appleMusic
    soundcloud
    tikTokSound
    source
  }
`;
