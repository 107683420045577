import {
  isMobile,
  isMobileOnly,
  isIOS,
  isIPad13,
  isIPhone13,
  isIPod13,
  isIOS13,
} from "react-device-detect";

export {
  ANALYTICS_ACTION,
  ANALYTICS_ELEMENT_NAME,
  ANALYTICS_ELEMENT_TYPE,
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
} from "./analytics";

export * from "./dataTestId";

export const DATE_FORMAT_FOR_SCHEDULES = "YYYY-MM-DD";
export const DEFAULT_ASPECT_RATIO = 9 / 16;
// Custom variable to detect mobile due to iOS 13 changing its device type
// https://github.com/duskload/react-device-detect/issues/55
export const IS_MOBILE = isMobile || isIPad13 || isIPhone13 || isIPod13;
export const IS_MOBILE_ONLY = isMobileOnly;
export const IS_IOS = isIOS || isIOS13;
export const VIDEO_RATIO = 16 / 9;
export const PARTY_STATUSES = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ENDED: "ENDED",
  LEFT: "LEFT",
};
export const PLACEHOLDER_FIRST_NAME = "Anonymous";
export const SUBSCRIPTION_STATUSES = {
  in_trial: "in_trial",
  active: "active",
  non_renewing: "non_renewing",
  paused: "paused",
  canceled: "canceled",
};
export const SUBSCRIPTION_PROVIDERS = {
  chargebee: "chargebee",
  revenuecat: "revenuecat",
  steezy: "steezy",
};
export const PAYMENT_GATEWAYS = {
  stripe: "stripe",
  braintree: "braintree",
};
export const PLAN_PERIOD_UNITS = {
  year: "year",
  month: "month",
};
export const PAYMENT_METHOD_TYPES = {
  card: "card",
  paypal_express_checkout: "paypal_express_checkout",
};

export const CHALLENGE_CONTENT_TYPE = {
  challenge: "challenge",
  user_video: "user_video",
};
export const LIBRARY_MODULE = {
  library_classes: "library_classes",
  library_free_classes: "library_free_classes",
  library_basic_classes: "library_basic_classes",
  library_programs: "library_programs",
  library_categories: "library_categories",
  library_instructors: "library_instructors",
};
export const SEARCH_MODULE = {
  search_classes: "search_classes",
  search_programs: "search_programs",
};
export const INSTRUCTOR_CONTAINER = {
  RecentClasses: "recent_classes",
  FeaturedClass: "featured_class",
};
export const HOME_CONTAINER = {
  FeaturedTitle: "featured_content",
  ContinueDancing: "continue_dancing",
  DanceWorkouts: "dance_workouts",
  SavedClasses: "saved_classes",
  Playlist: "schedule",
};
export const FAMILY_MODE_STORAGE_KEY = "familyMode";

export const XSMALL_BREAKPOINT = "374px";
export const SMALL_BREAKPOINT = "480px";
export const MEDIUM_BREAKPOINT = "767px";
export const LARGE_BREAKPOINT = "1169px";

export const XSMALL_BREAKPOINT_VAL = 374;
export const SMALL_BREAKPOINT_VAL = 480;
export const MEDIUM_BREAKPOINT_VAL = 767;
export const LARGE_BREAKPOINT_VAL = 1169;
