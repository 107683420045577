import challengesDaysLeft from "helpers/challengesDaysLeft";
import { useQuery } from "@apollo/client";
import { GET_CHALLENGE_BY_ID_QUERY } from "graphql/challenges";

export default ({ variables = {}, options = {} } = {}) => {
  const { data = {}, loading, error, fetchMore } = useQuery(
    GET_CHALLENGE_BY_ID_QUERY,
    {
      variables,
      ...options,
    }
  );

  const challenge = data?.challengeById;

  if (!challenge) {
    return [{}, loading, error];
  }

  const { videos: { edges = [], totalCount } = {} } = challenge;

  const hasMore = edges.length < totalCount;
  const fetchMoreData = () =>
    hasMore &&
    fetchMore({
      variables: {
        first: edges.length + 10,
      },
      updateQuery: (pv, { fetchMoreResult }) => fetchMoreResult,
    });

  const daysLeft = challengesDaysLeft(challenge);

  return [{ ...challenge, daysLeft }, loading, error, fetchMoreData];
};
