export function deconstructSourceParamsCsv(sourceParamsCSV: string) {
  const sourceParamsArray = sourceParamsCSV.split(",");
  const sourceObject = sourceParamsArray.reduce(
    (acc: { [key: string]: string }, curr, index) => {
      const dupeAcc = acc;
      if (index % 2 === 0) {
        dupeAcc[curr] = sourceParamsArray[index + 1];
      }

      return dupeAcc;
    },
    {}
  );

  return sourceObject;
}
